import { orderByDistance, getPathLength, getPreciseDistance } from "geolib";
import { memo } from "react";

export const MapStyles = [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4e4e4',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
      ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4e4e4',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
];

export const Labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const allPermutations = (items) => {
    let results = [];
    const permute = (arr, memo1) => {
        var cur, memo1 = memo1 || [];
        console.log(memo1);
        for (let i = 0; i < arr.length; i++) {
            cur = arr.splice(i, 1); // remove element at index
            if (arr.length === 0) {
                results.push(memo1.concat(cur));
            }
            permute(arr.slice(), memo1.concat(cur));
            arr.splice(i, 0, cur[0]); // add element at index
        }
        return results;
    }
    permute(items);
    return results;
}

const allValidPermutations = ((pointsHash) => {

    let results = [];
    let keys = Object.keys(pointsHash);
    let keysLength = keys.length;

    const permute = (arr, memo1) => {
        var cur, memo1 = memo1 || [];
        for (let i = 0; i < arr.length; i++) {
            cur = arr.splice(i, 1); // remove element at index

            //add control here, before adding to memo, if rules are not observed set arr to zero and cut off recursion tree
            let concatMemo = memo1.concat(cur);
            if ( (pointsHash[concatMemo[0]].type == "destination" && i == 0) || (pointsHash[concatMemo[concatMemo.length-1]].type == "location" && concatMemo.length == keysLength)) break;

            if(concatMemo.length > 1)
            {
                let nextPoint = pointsHash[concatMemo[concatMemo.length - 1]];

                if ( nextPoint.type == "location")
                {
                    // get alternate points that are before in the path order
                    let hasIncorrectOrder =  memo1.some((label)=>{
                        let prevPoint = pointsHash[label];
                        if( nextPoint.booking._id == prevPoint.booking._id && prevPoint.type == "destination" && nextPoint.order < prevPoint.order) return true
                        return false;
                    })
                    
                    if(hasIncorrectOrder) break;
                }
            }
            if (arr.length === 0) {
                results.push(memo1.concat(cur));
            } 
            permute(arr.slice(), memo1.concat(cur));
            arr.splice(i, 0, cur[0]); // add element at index
        }
        return results;
    }
    permute(keys.slice());

    return results;
})

const validatePath = (path, perm) =>{
    let valid = path.every((point, index)=>{
        const {booking, type, order } = point; 
        let alternatePointType;
        let start;
        let end;
        
        if(type == "location")
        {
            alternatePointType = "destination";
            start = 0;
            end = index;
        }
        else
        {
            alternatePointType = "location";
            start = index + 1;
            end = path.length;
        }

        // get alternate points that are before in the path order
        let hasIncorrectOrder =  path.slice(start, end).some((altPoint)=>{

            if( booking._id == altPoint.booking._id && altPoint.type == alternatePointType)
            {
                if ((type == "location" && altPoint.order > order) 
                || (type == "destination" && altPoint.order < order))
                {
                    return true
                }             
            }
            return false;
        })
        return (!hasIncorrectOrder);
    });

    return valid;
}

export const optimizeSelectedBookingsPath2 = (selectedBookingsPath) =>{

    // get all combinations starting with location points and then validate combinations

    // we need to get permutations
    if (selectedBookingsPath.length <= 2) return selectedBookingsPath;

    let pointsHash = {};
    let validPathsArray = [];

    selectedBookingsPath.forEach((point, index)=> {
        pointsHash[Labels[index]] = point;
    });

    // get permutations of remainingLabels
    let _validPermutations = allValidPermutations(pointsHash);

    //for each permutaion create trip order and validate
    _validPermutations.forEach((permutation,index)=>{
        validPathsArray.push(permutation.map((permLabel)=> pointsHash[permLabel]));
    })


    let _leastDistance = Number.MAX_VALUE;
    let optimizedSelectedBookingPath = [];

    validPathsArray.forEach((path)=>{

        let pointsLatLng = path.map((point)=>{
            const { latitude, longitude } = point;
            return { latitude, longitude};
        })
        let _distance = getPathLength(pointsLatLng, getPreciseDistance)

        if (_distance < _leastDistance)
        {
            optimizedSelectedBookingPath = path;
            _leastDistance = _distance
        }
    })

    return optimizedSelectedBookingPath;
}

export const optimizeSelectedBookingsPath = (selectedBookingsPath) =>{

    if (selectedBookingsPath.length <= 2) return selectedBookingsPath;
    // lets get path as hash

    let locationPointsArray = []
    let destinationPointsArray = []
    let optimizedSelectedBookingPath = []
    let pathsHash = {}

    selectedBookingsPath.forEach((point)=>{

        const {type} = point
        if (type == "location")
        {
            locationPointsArray.push(point)
        }
        else{
            destinationPointsArray.push(point)
        }
    })

    // combinations always start with locations
    locationPointsArray.forEach((point)=>{
        
        const {type, booking, latitude, longitude, order} = point;

        let _currentPoint = point;
        let _currentPointLatLng = { latitude, longitude};

        let _pathArray = [_currentPoint] 

        // get a hash with all other points
        let _remainingPointsArray = selectedBookingsPath.filter((point)=>{
            if ((point.type == _currentPoint.type) && (point.booking._id == _currentPoint.booking._id) && (point.order == _currentPoint.order))
            {
                return false
            }
            return true
        })

        while (_remainingPointsArray.length > 0)
        {
            //get remaining points latlng
            let _remainingPointsLatLngArray = _remainingPointsArray.map((point)=>{
                const { latitude, longitude }  = point;
                return { latitude, longitude }
            })

            // get ordered remaining points latlng relative to current location point
            _remainingPointsLatLngArray =  orderByDistance(_currentPointLatLng,[..._remainingPointsLatLngArray]);

            // find nearest valid point(s)
            let _nearestValidPoints = [];

            _remainingPointsLatLngArray.every((latLng)=>{

                //match coordinates to path
                let _nearestRemainingPoints = _remainingPointsArray.filter((point)=>{
                    const { latitude, longitude} = point;
                    return (latLng.latitude == latitude && latLng.longitude == longitude)

                });

                if (_nearestRemainingPoints.length > 1)
                {
                    // find the first valid point

                    _nearestRemainingPoints.forEach((_nearestPoint)=>{                        
                        if(_nearestPoint.type == "location")
                        {
                            // add to nearest valid points array
                            _nearestValidPoints.push(_nearestPoint)
                            return false; // using false as break
                        }
                        else
                        {
                            // if it is a destination point, check if the locations that are supposed to come before it have already been added in _path array
                            let locationsBeforeDestination = selectedBookingsPath.filter((point)=>{
                                const { type, order, booking } = point;
                                if (type == "location" && order < _nearestPoint.order && (booking._id == _nearestPoint.booking._id)) return true;
                                return false;                                
                            });

                            let _allAdded = locationsBeforeDestination.every((locationPoint) => {
                                return _pathArray.some((point)=>{
                                    const {booking, type, order} = point;
                                    return( booking._id == locationPoint.booking._id && type == locationPoint.type && order == locationPoint.order)
                                })
                            });

                            if(_allAdded)
                            {
                                // add to nearest valid points array
                                _nearestValidPoints.push(_nearestPoint)
                                return false; // using false as break
                            }
                            else
                            {
                                // skip this destination point
                                return true
                            }
                        }
                    })
                }
                else
                {
                    let _nearestPoint = _nearestRemainingPoints[0];

                    if(_nearestPoint.type == "location")
                    {
                        // add to nearest valid points array
                        _nearestValidPoints.push(_nearestPoint)
                        return false; // using false as break
                    }
                    else
                    {
                        // if it is a destination point, check if its location has already been added in _path array

                        let locationsBeforeDestination = selectedBookingsPath.filter((point)=>{
                            const { type, order, booking } = point;
                            if (type == "location" && order < _nearestPoint.order && (booking._id == _nearestPoint.booking._id)) return true;
                            return false;                                
                        });

                        let _allAdded = locationsBeforeDestination.every((locationPoint) => {
                            return _pathArray.some((point)=>{
                                const {booking, type, order} = point;
                                return( booking._id == locationPoint.booking._id && type == locationPoint.type && order == locationPoint.order)
                            })
                        });

                        if(_allAdded)
                        {
                            // add to nearest valid points array
                            _nearestValidPoints.push(_nearestPoint)
                            return false; // using false as break
                        }
                        else
                        {
                            // skip this destination point
                            return true
                        }
                    }
                }

            });

            // add nearest valid points to _path array, in order
            _pathArray = [..._pathArray, ..._nearestValidPoints]

            // reassign current point to the last point in the created path
            _currentPoint = _pathArray[_pathArray.length-1];
            _currentPointLatLng = { latitude: _currentPoint.latitude, longitude: _currentPoint.longitude };

            //remove nearest valid points from remaining points array
            _nearestValidPoints.forEach((point)=>{
                const {booking, type, order} = point;
                _remainingPointsArray = _remainingPointsArray.filter((remainingPoint)=>( (remainingPoint.type != type) || (remainingPoint.booking._id != booking._id) || (remainingPoint.order != order) ))
            })

        }

        //console.log(_pathArray);
        // store generated path
        pathsHash[`${booking._id}-${type}-${order}`] = _pathArray;
    })

    let _leastDistance = Number.MAX_VALUE;

    for (const [key, value] of Object.entries(pathsHash)){

        let points = value.map((point)=>{
            const { latitude, longitude } = point;
            return { latitude, longitude};
        })
        let _distance = getPathLength(points, getPreciseDistance)

        if (_distance < _leastDistance)
        {
            optimizedSelectedBookingPath = value;
            _leastDistance = _distance
        }
    }

    return optimizedSelectedBookingPath;
} 

export const vehicleIcon = {
    path: 'M17.75 7c.138 0 .25.112.25.25v.255c0 .397-.463.495-.808.495l-.543-1h1.101zm-.721 1.753c.32.593.473 1.126.473 1.833 0 .685-.198 1.267-.503 1.991v.923c0 .276-.224.5-.5.5h-.75c-.276 0-.5-.224-.5-.5v-.5h-6.5v.5c0 .276-.224.5-.5.5h-.749c-.276 0-.5-.224-.5-.5v-.924c-.304-.724-.503-1.306-.503-1.991 0-.707.154-1.24.473-1.833.414-.768.926-1.726 1.465-2.626.415-.69.631-.853 1.139-.944.767-.137 1.459-.182 2.426-.182s1.659.045 2.426.182c.508.091.725.253 1.139.945.539.9 1.05 1.857 1.464 2.626zm-8.029 1.497c0-.414-.336-.75-.75-.75s-.75.336-.75.75.336.75.75.75.75-.336.75-.75zm5 .5c0-.138-.112-.25-.25-.25h-3.5c-.138 0-.25.112-.25.25s.112.25.25.25h3.5c.138 0 .25-.112.25-.25zm1.471-2.764s-.37-.913-.815-1.571c-.101-.149-.258-.251-.435-.283-.756-.136-1.418-.179-2.221-.179s-1.465.043-2.22.179c-.177.032-.334.134-.435.283-.445.658-.816 1.571-.816 1.571.821.157 2.155.249 3.471.249s2.65-.092 3.471-.249zm1.029 2.264c0-.414-.336-.75-.75-.75s-.75.336-.75.75.336.75.75.75.75-.336.75-.75zm-9.149-3.25h-1.101c-.138 0-.25.112-.25.25v.255c0 .397.463.495.808.495l.543-1zm4.649-7c-5.523 0-10 4.394-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.421-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8c4.419 0 8 3.582 8 8s-3.581 8-8 8z',
    fillColor: 'rgb(0,0,255)', // Blue color or any other color
    fillOpacity: 1,
    strokeColor: 'rgba(0,0,0,0.5)', // Semi-transparent border
    strokeWeight: 2, // Border thickness
    scale: 1.5, // Adjust the size of the icon
  };


  