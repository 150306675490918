import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import axios from "axios";
import context from "../../context";

const ForgotPassword = () => {
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    message: "",
  });
  const [email, setEmail] = useState(""); //'tawo@mail.com'

  const onEmailChanged = (email) => {
    setEmail(email.trim().toLowerCase());
  };
  const handleSubmit = async () => {
    axios
      .post(`${context.SERVER_URL}/api/usersinvitepasswordreset`, {
        data: {
          email,
        },
      })
      .then((res) => {
        console.log("invitation sent");
      })
      .catch((err) => {
        //alert("wrong user name and password");
        setShowErrorMessage({
          show: true,
          message: err.response?.data?.message || "An error occured.",
        });

        setTimeout(() => {
          setShowErrorMessage({
            show: false,
            message: "",
          });
        }, 4000);
        console.log(err);
      });
  };
  return (
    // <div className=" flex flex-col w-full h-full bg-green-50 items-center overflow-y-auto">
    //     <span className = "flex flex-row text-3xl font-bold underline py-2 text-primary-1"> Wheels
    //         <span className="text-orange-custom text-4xl">it</span>
    //         Now
    //     </span>
    //     <div  className="flex flex-col w-full md:w-2/3 px-10">

    //         <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/2">
    //             <label className = "font-bold text-primary-1">Email</label>
    //             <input
    //                 className = "border-2 border-primary-1 rounded-lg p-4 text-gray-500 text-lg bg-white"
    //                 type="email"
    //                 onChange = {e => onEmailChanged(e.target.value) }
    //                 placeholder = {"Enter email"}
    //                 value = {email}
    //             />
    //         </div>
    //         <button
    //             className="bg-primary-1 rounded-lg p-5 text-white font-semibold w-full mt-10 md:w-1/2 md:mt-2"
    //             onClick= {()=>handleSubmit()}
    //         >
    //             Send link
    //         </button>

    //     </div>

    //     {
    //         (showErrorMessage.show) &&
    //         <div className="mt-3 underline font-bold text-red-500">{showErrorMessage.message}</div>
    //     }
    // </div>

    <section className="py-12 bg-white sm:py-16 lg:py-20 h-screen">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center">
            LOGO
            <h1 className="mt-12 text-3xl font-bold text-primary-2">
              Wheelsitnow
            </h1>
            <p className="mt-4 text-sm font-medium text-gray-500">
              We recognize that you may have misplaced your password. Kindly
              provide us with your email address so that we can send you a reset
              link.
            </p>
          </div>

          <div className="mt-4">
            <div className="space-y-4">
              <div>
                <label for="" className="text-sm font-bold text-primary-2">
                  {" "}
                  Email{" "}
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    onChange={(e) => onEmailChanged(e.target.value)}
                    placeholder={"Enter email"}
                    value={email}
                    className="block w-full px-4 py-3 border placeholder-gray-500 border-gray-300 rounded-lg focus:ring-primary-1 focus:border-primary-1 sm:text-sm caret-primary-1"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={() => handleSubmit()}
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-primary-1 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-1 hover:bg-green-500"
                >
                  Send link
                </button>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <Link to="/" className="text-primary-2"> Go Back </Link>
          </div>

          <div className="mt-6 text-center">
            {showErrorMessage.show && (
              <div className="mt-3  text-red-500">
                {showErrorMessage.message}
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForgotPassword;
