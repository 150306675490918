import React from 'react';

const BookingModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
                <h2 className="text-3xl font-bold mb-4 text-gray-800">Exclusive Access</h2>
                <p className="text-gray-600 mb-6 leading-relaxed">
                    Thank you for your interest in Wheelsit. Our services are not yet public, but registration is limited to invited participants.
                </p>
                <p className="text-gray-600 mb-6 leading-relaxed">
                    We value your enthusiasm and would love to hear from you. Please use the <b>contact form below</b> to submit your inquiries or express interest in future opportunities.
                </p>
                <button
                    onClick={onClose}
                    className="w-full py-3 bg-black text-white rounded-full hover:bg-gray-800 transition duration-300 ease-in-out font-semibold text-lg"
                >
                    Continue to Contact Form
                </button>
            </div>
        </div>
    );
};

export default BookingModal;
