import { trailerLocationUpdateAction } from "../../routes/bookings/modules/bookings";
import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (trailerId, callback = null) => {
    try {
        const socket = await getSocket();
        socket.on(`TrailerLocationUpdate_${trailerId}`, (data) => {
            ReduxStorage.store.dispatch(trailerLocationUpdateAction(data));
            if (callback) callback(data);
        })
    } catch (error) {
        console.log("Error register trailer location update receiver: ", error);
    }
}

export const unregister = async (trailerId) => { 
    try {
        const socket = await getSocket();
        socket.off(`TrailerLocationUpdate_${trailerId}`);
    } catch (error) {
        console.log("Error unregister trailer location update receiver: ", error);
    }

}