import {connect} from 'react-redux'
import ErrorModal from '../components/ErrorModal';
import { 
    showErrorAction
} from '../modules/modal'

const mapStateToProps = (state) =>({
    showError: state.modal.showError,
    errorMessage: state.modal.errorMessage
});

const mapActionCreators = {
    showErrorAction
};

export default connect (mapStateToProps,mapActionCreators)(ErrorModal); 