import React from 'react'
import Modal from 'react-modal'
import CancelIcon from '@mui/icons-material/Cancel';
const ErrorModal = ({
    showErrorAction,
    showError,
    errorMessage 
}) => {

    return(
        <div>
            <Modal
                isOpen={showError}
                ariaHideApp={false}
                //onAfterOpen={afterOpenModal}
                //onRequestsClose={closeModal}
                style={style}
                //contentLabel="Example Modal"
            >
                <div className='flex flex-col w-full justify-center items-center'>
                    <button 
                        className='absolute right-1 top-1'
                        onClick={()=>showErrorAction({
                            showError: false,
                            errorMessage: ""
                        })}
                    >
                        <CancelIcon/>
                    </button>
                    <div className='font-bold text-red-500'>Error</div>
                    <div>{errorMessage}</div>
                </div>
                
            </Modal>
        </div>
    )

}

const style = {
    content: {
        top: 10 + "%",
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        justifyContent: "center",
        alignItems: "center",
        width: 80 + "%",
        borderColor: "red",
        borderRadius: 10,
        padding: 10
    },

}

export default ErrorModal;