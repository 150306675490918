import {connect} from 'react-redux'
import Landing from '../components/Landing';
import { 
} from '../modules/landing'

const mapStateToProps = (state) =>({

});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(Landing); 