import React,{useEffect, useRef, useState} from "react";

import { Link } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import context from "../../../context/index"

const NewUser = () => {

    const [companies, setCompanies] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [companyRoles, setCompanyRoles] = useState([]);
    const [fleetRoles, setFleetRoles] = useState([]);


    const [accountTypes, setAccountTypes] = useState([]);
    const [selectedAccountType, setSelectedAccountType] = useState('');


    const [invitation, setInvitation] = useState({
        emails: "",
        role: "",
        company: "",
        fleet: ""
    });

    const fetchRoles = () =>{
        axios.get(`${context.SERVER_URL}/api/userroles`)
        .then((res)=>{
            const { company, fleet } = res.data;
            const companyRoles = company.map((role)=>{
                return { label: role , value: role }
            });
            const fleetRoles = fleet.map((role)=>{
                return { label: role , value: role }
            });

            setCompanyRoles(companyRoles);
            setFleetRoles(fleetRoles);
        }).catch((err)=>{
            console.log(err)
        });
    }

    const fetchAccountTypes = () =>{
        axios.get(`${context.SERVER_URL}/api/accounttypes`)
        .then((res)=>{
            const _accountTypes = res.data.map((type)=>{
                return { label: type , value: type }
            });
            setAccountTypes(_accountTypes);
        }).catch((err)=>{
            console.log(err)
        });
    }

    const fetchCompanies = () =>{

        axios.get(`${context.SERVER_URL}/api/companies`)
        .then((response)=>{
            const companies = response.data.companies.map((company) => {
                return { label: company.name, value: company._id}
            });

            setCompanies(companies);
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const fetchFleets = () =>{

        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{
            const _fleets = response.data.map((fleet) => {
                return { label: fleet.name, value: fleet._id}
            });

            setFleets(_fleets);
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onInvitationValueChanged = (key, value) =>{
        setInvitation((prevState)=>{
            return{
                ...prevState,
                [key]: value
            }
        })
    }

    const submitInvitations = () =>{

        axios.post(`${context.SERVER_URL}/api/usersinvite`,
        {
            data:{
                emails: invitation.emails,
                role: invitation.role?.value,
                ...( selectedAccountType?.value == "company" && { company: invitation.company?.value } ),
                ...( selectedAccountType?.value == "fleet" && { fleet: invitation.fleet?.value } )
            }
        })
        .then((response)=>{
            console.log(response)
        })
        .catch((error)=>{
            console.log(error)
        })

    }

    useEffect(()=>{
        fetchFleets();
        fetchCompanies();
        fetchRoles();
        fetchAccountTypes();
    },[])

    return(
        <div className="flex flex-col w-full h-full p-5">
            <h1 className="text-primary-1 font-bold">New User</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link
                    to = {"/users"}
                    className = "underline pr-2"
                >users</Link>
            </div>

            <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/2 lg:w-1/4">
                <label className = "font-bold text-primary-1 text-xs">Account Type</label>
                <Select
                    className = "text-gray-500 text-xs"
                    placeholder="Select account type"
                    options={accountTypes}
                    onChange={(value)=>{
                        setSelectedAccountType(value);
                        onInvitationValueChanged("fleet","");
                        onInvitationValueChanged("company","");
                        onInvitationValueChanged("role","");
                    }}
                    value={selectedAccountType}
                    styles={{
                        control: (styles) => ({
                            ...styles,
                            borderColor: "#e2e8f0",
                            borderWidth: 1,
                            borderRadius: 9,
                            boxShadow:"1px 1px 0px rgba(0,0,0,0.1)"
                            /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                            ':hover' : {
                                borderColor: getColor(!hasError),
                                boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                            }*/
                        })
                    }}
                />
            </div>
            {
                selectedAccountType?.value == "company" &&
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/2 lg:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Company</label>
                    <Select
                        className = "text-gray-500 text-xs"
                        placeholder="Select company"
                        options={companies}
                        onChange={(value)=>onInvitationValueChanged("company",value)}
                        value={invitation.company}
                        styles={{
                            control: (styles) => ({
                                ...styles,
                                borderColor: "#e2e8f0",
                                borderWidth: 1,
                                borderRadius: 9,
                                boxShadow:"1px 1px 0px rgba(0,0,0,0.1)"
                                /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                                ':hover' : {
                                    borderColor: getColor(!hasError),
                                    boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                                }*/
                            })
                        }}
                    />
                </div>
            }
            {
                selectedAccountType?.value == "fleet" &&
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Fleets</label>
                    <Select
                        className = "text-gray-500 text-xs"
                        placeholder="Select fleet"
                        options={fleets}
                        onChange={(value)=>onInvitationValueChanged("fleet",value)}
                        value={invitation.fleet}
                        styles={{
                            control: (styles) => ({
                                ...styles,
                                borderColor: "#e2e8f0",
                                borderWidth: 1,
                                borderRadius: 9,
                                boxShadow:"1px 1px 0px rgba(0,0,0,0.1)"
                                /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                                ':hover' : {
                                    borderColor: getColor(!hasError),
                                    boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                                }*/
                            })
                        }}
                    />
                </div>
            }

            {
                (invitation.fleet?.value != null || invitation.company?.value != null) &&
                <>
                    <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/2 lg:w-1/4">
                        <label className = "font-bold text-primary-1 text-xs">Role</label>
                        <Select
                            className = "text-gray-500 text-xs"
                            placeholder="Select role"
                            options={ (selectedAccountType?.value == "company") ? companyRoles : fleetRoles}
                            onChange={(value)=>onInvitationValueChanged("role",value)}
                            value={invitation.role}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    borderColor: "#e2e8f0",
                                    borderWidth: 1,
                                    borderRadius: 9,
                                    boxShadow:"1px 1px 0px rgba(0,0,0,0.1)",
                                    //color: "rgba(226, 232, 240, 0.5)"
                                    /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                                    ':hover' : {
                                        borderColor: getColor(!hasError),
                                        boxShadow: `0 0 0 1px ${getColor(!hasError)}`,
                                    }*/
                                })
                            }}
                        />
                    </div>

                    <textarea
                        className="w-full h-1/2 text-gray-500 text-xs rounded-lg md:w-3/4 lg:w-3/4 p-3 mt-2 mb-2"
                        placeholder="Enter emails separated by commas (,) with no spaces. eg example@mail.com,example1@mail.com"
                        value={invitation.emails}
                        onChange={(e) => onInvitationValueChanged("emails", e.target.value)}
                    />
                    <button
                        className="bg-primary-1 rounded-lg text-white font-semibold w-full py-3 mt-10 mb-10 md:w-1/4 md:mt-2 md:mb-0"
                        onClick= {()=>submitInvitations()}
                    >
                        Invite
                    </button>
                </>
            }
        </div>
    )

}

export default NewUser;
