import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showErrorAction } from '../../common/modules/modal';
export const initialLandingState = {
};


const {
    PAGE_CHANGE
} = actionConstants;


export const LANDING_ACTION_HANDLERS = {
    
}



