export const POINT_TYPE = [
    { label: "pick", value: "location" },
    { label: "drop", value: "destination" }
]

export const PACKAGING_TYPE = [
    { label: "unit", value: "unit" },
    { label: "pallet", value: "pallet"},
    { label: "box", value: "box"},
    { label: "crate", value: "crate"},
    { label: "sack", value: "sack"},
    { label: "drum", value: "drum"},
    { label: "container", value: "container"}
]

export const AVAILABILITY_OPTIONS =[
    { label: "online", value: "online" },
    { label: "busy", value: "busy" },
    { label: "offline", value: "offline" },
]

export const SUPPORT_CATEGORIES =[
    { label: "Late driver", value: "lateDriver" },
    { label: "Broken goods", value: "brokenGoods" },
    { label: "Payment Issue", value: "paymentIssue" },
    { label: "Other", value: "other" }
]

export const SUPPORT_STATUSES = [
    { label: "Open", value: "open" },
    { label: "In progress", value: "inprogress" },
    { label: "Closed", value: "closed" }
]

export const COLOR_CODES = {
    company:{
        status:{
            active: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800"
        }
    },
    fleet:{
        status:{
            active: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800"
        }
    },
    trip:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            scheduled: "bg-blue-100 text-blue-800",
            accepted: "bg-green-100 text-green-800",
            onroute: "bg-purple-100 text-purple-800",
            complete: "bg-gray-100 text-gray-800",
            cancelled: "bg-red-100 text-red-800",
        }
    },
    trailer:{
        availability:{
            online: "bg-green-100 text-green-800",
            offline: "bg-gray-100 text-gray-800",
            busy: "bg-yellow-100 text-yellow-800"
        }
    },
    user:{
        status:{
            incomplete: "bg-yellow-100 text-yellow-800",
            complete: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800",
            active: "bg-blue-100 text-blue-800",
            banned: "bg-red-100 text-red-800"
        }
    },
    booking:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            scheduled: "bg-blue-100 text-blue-800",
            accepted: "bg-green-100 text-green-800",
            onroute: "bg-purple-100 text-purple-800",
            complete: "bg-gray-100 text-gray-800",
            cancelled: "bg-gray-100 text-gray-800",
        }
    },
    point:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            arrived: "bg-blue-100 text-blue-800",
            loading: "bg-green-100 text-green-800",
            offloading: "bg-green-100 text-green-800",
            onroute: "bg-purple-100 text-purple-800",
            complete: "bg-gray-100 text-gray-800"
        }
    },
    vehicle:{
        status:{
            active: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800"
        }
    }
}

export const generateLongUniqueId = () => {
    return (
      Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2)
    );
}