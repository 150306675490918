import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialSupportsState = {
    support: null,
    supports:[],
};


const {
    GET_SUPPORTS_DATA,
    GET_SUPPORT_DATA,
    UPDATE_SUPPORT,
    CREATE_SUPPORT
} = actionConstants;

export const getSupportsDataAction = (filters) => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        const {
            searchFilter,
            dateFilter,
            statusFilter,
            categoryFilter
        } = filters;

        let _date = "";
        if (dateFilter?.value) {
            const date = new Date(dateFilter.value);
            _date = JSON.stringify({
                month: date.getMonth(),
                date: date.getDate(),
                year: date.getFullYear(),
            });
        }

        const params = new URLSearchParams({
            searchFilter: searchFilter || "",
            dateFilter: _date,
            statusFilter: statusFilter?.value || "",
            categoryFilter: categoryFilter?.value || ""
        });

        axios.get(`${context.SERVER_URL}/api/supports?${params.toString()}`)
        .then((res) => {
            const supports  = res.data;
            dispatch({
                type: GET_SUPPORTS_DATA,
                payload:{
                    supports
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching supports:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const getSupportDataAction = (id) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/supports/${id}`)
            .then((res) => {
                const support = res.data;
                dispatch({
                    type: GET_SUPPORT_DATA,
                    payload:{
                        support
                    }
                })
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
    
    }    
}

export const updateSupportAction = (data, navigate) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        let support = store().supports.support;
        axios.put(`${context.SERVER_URL}/api/supports/${support?._id}`,{
            data
        })
            .then((res) => {
                const support = res.data;
                dispatch({
                    type: UPDATE_SUPPORT,
                    payload:{
                        support
                    }
                })
                navigate('/supports');
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
    
    }    
}

export const createSupportAction = (data, navigate) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.post(`${context.SERVER_URL}/api/supports`,{
            data
        })
        .then((res) => {
            const support = res.data;
            dispatch({
                type: CREATE_SUPPORT,
                payload:{
                    support
                }
            })
            navigate('/supports');
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    
    }    
}

function handleGetSupportsData (state, action){
    return update(state, {
        supports:{
            supports:{ $set: action.payload.supports },
        }
    })
}

function handleGetSupportData (state, action){
    return update(state, {
        supports:{
            support:{ $set: action.payload.support },
        }
    })
}

function handleUpdateSupport (state, action){
    return update(state, {
        supports:{
            support:{
                path: { $set: action.payload.support },
            },
        }
    })
}

function handleCreateSupport (state, action){
    return state;
}

export const SUPPORTS_ACTION_HANDLERS = {
    GET_SUPPORTS_DATA: handleGetSupportsData,
    GET_SUPPORT_DATA: handleGetSupportData,
    UPDATE_SUPPORT: handleUpdateSupport,
    CREATE_SUPPORT: handleCreateSupport
}