/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { images } from "../../../assets";

const Login = ({ setToken, loginAction }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); //'tawo@mail.com'
  const [password, setPassword] = useState(""); //1234

  const onEmailChanged = (email) => {
    setEmail(email.trim().toLowerCase());
  };

  const onPasswordChanged = (password) => {
    setPassword(password.trim());
  };

  return (
    <div>
      <section className="py-12 bg-white sm:py-16 lg:py-20">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 gap-y-12 lg:gap-x-20 lg:grid-cols-2 2xl:gap-x-8">
            <div>
              <div className="2xl:max-w-sm">
                <h1 className="text-3xl font-bold text-primary-2">
                  Welcome Back 👋
                </h1>
                <p className="mt-4 text-sm font-medium leading-6 text-gray-500">
                At Wheelsitnow, we are committed to providing you with a seamless and reliable transportation experience. Our platform offers real-time updates on your booking's status, detailed information on your driver's location, and much more. Whether you're scheduling a ride for immediate pickup or planning ahead, Wheelsitnow ensures that you stay informed and in control every step of the way.

                </p>

                <div className="mt-12">
                  <div className="space-y-6">
                    <div>
                      <label for="" className="text-sm font-bold text-primary-2">
                        {" "}
                        Email{" "}
                      </label>
                      <div className="relative mt-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                            />
                          </svg>
                        </div>

                        <input
                          type="email"
                          onChange={(e) => onEmailChanged(e.target.value)}
                          placeholder={"Enter email"}
                          value={email}
                          name=""
                          id=""
                          className="border block w-full py-3 pl-12 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-link-1 focus:border-link-1 sm:text-sm caret-link-1"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-between">
                        <label for="" className="text-sm font-bold text-primary-2">
                          {" "}
                          Password{" "}
                        </label>

                        <Link
                          to="/forgotpassword"
                          className="text-sm font-medium text-neutral-6 hover:text-neutral-8 hover:no-underline"
                        >
                          {" "}
                          Forgot Password?{" "}
                        </Link>
                      </div>
                      <div className="relative mt-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                        </div>

                        <input
                          type="password"
                          onChange={(e) => onPasswordChanged(e.target.value)}
                          placeholder={"Enter password"}
                          value={password}
                          className="border block w-full py-3 pl-12 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-link-1 focus:border-link-1 sm:text-sm caret-link-1"
                        />
                      </div>
                    </div>

                    <div className="relative flex items-center">
                      <div className="flex items-center h-5">
                        <input
                          type="checkbox"
                          name="remember-password"
                          id="remember-password"
                          className="w-4 h-4 text-link-1 border-gray-300 rounded focus:ring-link-1"
                        />
                      </div>

                      <div className="ml-3">
                        <label
                          for="remember-password"
                          className="text-sm font-medium text-primary-2"
                        >
                          {" "}
                          Remember Me{" "}
                        </label>
                      </div>
                    </div>

                    <div>
                      <button
                        onClick={() =>
                          loginAction({ email, password, navigate })
                        }
                        type="submit"
                        className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-primary-1 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-3 hover:bg-primary-2"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-20">
                  <p className="text-sm font-medium text-primary-2">
                    Don't have any?{" "}
                    <Link
                      to="/register"
                      title=""
                      className="font-bold text-link-1 hover:underline"
                    >
                      Create a free account
                    </Link>
                  </p>
                </div> */}
              </div>
            </div>

            <div className="overflow-hidden text-center bg-gray-100 rounded-2xl">
              <div className="flex items-center justify-center px-8 py-12 sm:px-12 sm:py-8 md:py-12 md:px-20">
                <div>
                  <img
                    className="w-auto mx-auto rounded-xl"
                    src={images.landingPicture01}
                    alt=""
                  />
                  <p className="mt-4 text-2xl font-bold text-primary-2">
                    Wheels it Now
                  </p>
                  <p className="mt-4 text-sm font-medium text-gray-500">
                    The ultimate destination for comprehensive logistics and
                    transportation solutions.
                  </p>
                  <div className="pt-8">
                    <Link
                      to={"/"}
                      className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-primary-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                        />
                      </svg>
                      Go Back
                    </Link>
                  </div>

                  <div className="flex items-center justify-center mt-16 space-x-1.5">
                    <div className="w-2.5 h-2.5 bg-gray-600 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-300 rounded-full"></div>
                    <div className="w-2.5 h-2.5 bg-gray-300 rounded-full"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
