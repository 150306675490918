import "react-confirm-alert/src/react-confirm-alert.css"; //

import * as CompanyPermissions from "../modules/CompanyPermissions";

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { COLOR_CODES } from "../../../util/general.util";
import ReactPaginate from "react-paginate";

const Companies = ({ 
  role,
  companies,
  totalPages,
  currentPage,
  getCompaniesDataAction,
  deleteCompanyAction
}) => {
  const [filters, setFilters] = useState({
    searchFilter: "",
    pageFilter: 1,
    limitFilter: 10
  }) 

  useEffect(() => {
    getCompaniesDataAction(filters);
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="w-full bg-white p-5 overflow-auto">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-primary-1 font-bold">
            Companies
          </h1>
          <p className="mt-2 text-sm text-primary-1">
            Here we can put some descriptions of the starts you can find here.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {CompanyPermissions.isAuthorized("new", role) && (
            <a href="/companies/new">
              <div className="bg-primary-1 rounded-md text-white px-3 py-2">
                New Company
              </div>
            </a>
          )}
        </div>
      </div>

      <div className="border border-gray-400 mt-1 mb-10" />
      <div className="flex flex-row w-full md:flex-row gap-4 mb-5">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="col-span-1">
            <input
              className="w-full rounded-lg border p-2"
              placeholder="Search"
              onChange={({ target: { value } }) => {
                setFilters((prevState)=>({
                  ...prevState,
                  searchFilter: value
                }))
              }}
              value={filters.searchFilter}
            />
          </div>
          <div className="flex col-span-1 gap-2 items-center">
            <label className="text-sm text-primary-1 font-semibold">
              Limit filter
            </label>
            <input
              className="w-fit border border-gray-200 rounded-md p-2"
              placeholder=""
              type = "number"
              onChange={({target: { value }}) =>{
                setFilters((prevState) => ({
                  ...prevState,
                  limitFilter: value,
                }))
              }}
              value={filters.limitFilter}
            />
          </div>
        </div>
        
        <div className="flex flex-row gap-4">
          <button
            className="bg-primary-1  text-white rounded-lg px-4 py-2"
            onClick={() => getCompaniesDataAction(filters)}
          >
            {" "}
            Search
          </button>
          <button
            className="bg-gray-500 text-white rounded-lg px-4 py-2"
            onClick={() => {

              let resetState = {
                searchFilter: "",
                pageFilter: 1,
                limitFilter: 10
              }
              setFilters(resetState);
              getCompaniesDataAction(resetState);
            }}
          >
            {" "}
            Reset
          </button>
        </div>
      </div>

      <div className="">
        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                >
                  Created At
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                >
                  Address
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => {
                return (
                  <tr key={company._id}>
                    <td
                      className={classNames(
                        index === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                      )}
                    >
                      <div className="font-medium text-primary-2">
                        {company.name}
                      </div>
                      <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                        <span>{company.status}</span>
                        <span className="hidden sm:inline">·</span>
                        <span>{company.storage}</span>
                      </div>
                      {index !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.company?.status[company.status.toLowerCase()]}`}>
                        {company.status}
                      </span>
                    </td>

                    <td
                      className={classNames(
                        index === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {company.created_at}
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? "" : "border-t border-gray-200",
                        "px-3 py-3.5 text-sm text-gray-500"
                      )}
                    >
                      <div className="">{company.locationAddress}</div>
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? "" : "border-t border-transparent",
                        "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex"
                      )}
                    >
                      {CompanyPermissions.isAuthorized("view", role) && (
                        <Link to={`/companies/view/${company._id}`}>
                          <div className="border p-2 m-2 rounded-md">View</div>
                        </Link>
                      )}
                      {CompanyPermissions.isAuthorized("edit", role) && (
                        <Link to={`/companies/edit/${company._id}`}>
                          <div className="border border-green-500 text-primary-1 p-2 m-2 rounded-md">
                            {" "}
                            Edit{" "}
                          </div>
                        </Link>
                      )}
                      {CompanyPermissions.isAuthorized("delete", role) && (
                        <button
                          className="border border-red-400 text-red-400 p-2 m-2 rounded-md"
                          onClick={() => deleteCompanyAction(company._id)}
                        >
                          {" "}
                          Delete{" "}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-row w-full items-center justify-end mt-5">
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({selected}) => {
            let _filters = {
              ...filters,
              pageFilter: selected + 1
            } 
            getCompaniesDataAction(_filters);
            setFilters(_filters);
          }}
          containerClassName={'pagination'}
          pageClassName={'page'}
          pageLinkClassName={'page-link'}
          previousClassName={'previous'}
          nextClassName={'next'}
          activeClassName={'active'}
          disabledClassName={'disabled'}      
        />
      </div>
    </div>
  );
};

export default Companies;
