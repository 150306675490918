import {connect} from 'react-redux'
import Users from '../components/Users';
import { showLoaderAction } from '../../common/modules/modal';
const mapStateToProps = (state) =>({
    role:  state.profile.role
});

const mapActionCreators = {
    showLoaderAction
};

export default connect (mapStateToProps,mapActionCreators)(Users); 