import {connect} from 'react-redux'
import Dashboard from '../components/Dashboard';
import { 
    getDashboardDataAction
} from '../modules/dashboard'

const mapStateToProps = (state) =>({
    companies: state.dashboard.companies,
    trips: state.dashboard.trips,
    role: state.profile.role
});

const mapActionCreators = {
    getDashboardDataAction
};

export default connect (mapStateToProps,mapActionCreators)(Dashboard); 