import React, {useState} from "react";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";

const CompanyBookings = (company, index) =>{

    const [expanded, setExpanded] = useState(false);

    const renderBooking = (booking, index) =>{
        const date = new Date(booking.date).toLocaleDateString()
        return(
            <div key={`booking-${index}`} className="w-full bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm font-medium">Date</label>
                <div className="text-gray-700 font-medium">{date}</div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm font-medium">Timeslot</label>
                <div className="text-gray-700 font-medium">{booking.timeslot}</div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm font-medium">Cargo Type</label>
                <div className="text-gray-700 font-medium">{booking.cargoType}</div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm font-medium">Distance</label>
                <div className="text-gray-700 font-medium">{`${booking.distance} km`}</div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm font-medium">Fare</label>
                <div className="text-gray-700 font-medium">{booking.fare}</div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm font-medium">Status</label>
                <div className="text-gray-700 font-medium">{booking.status}</div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <Link
                className="py-2 px-4 text-gray-500 border-2 border-gray-500 bg-gray-200 rounded-lg hover:bg-gray-300"
                to={`/bookings/view/${booking._id}`}
              >
                View
              </Link>
            </div>
          </div>
        )

    };


    const renderTabView = () => {

        return(
            <Tabs
                className="mt-2"
                key={"companies"}
            >
                <TabList>
                    <Tab>Bookings</Tab>
                    <Tab>Pending</Tab>
                    <Tab>Scheduled</Tab>
                    <Tab>Accepted</Tab>
                    <Tab>On Route</Tab>
                    <Tab>Complete</Tab>
                </TabList>

                <TabPanel
                    className="h-1/4 overflox-x-auto"
                    key={"bookings"}
                >
                    {
                        company.bookings.map((booking, index)=>renderBooking(booking, index))
                    }
                </TabPanel>
                <TabPanel
                    key={"pending"}
                >
                    {
                        company.pending.map((booking, index)=>renderBooking(booking, index))
                    }
                </TabPanel>
                <TabPanel
                    key={"scheduled"}
                >
                    {
                        company.scheduled.map((booking, index)=>renderBooking(booking, index))
                    }
                </TabPanel>
                <TabPanel
                    key={"accepted"}
                >
                    {
                        company.accepted.map((booking, index)=>renderBooking(booking, index))
                    }
                </TabPanel>
                <TabPanel
                    key={"onroute"}
                >
                    {
                        company.onroute.map((booking, index)=>renderBooking(booking, index))
                    }
                </TabPanel>
                <TabPanel
                    key={"complete"}
                >
                    {
                        company.complete.map((booking, index)=>renderBooking(booking, index))
                    }
                </TabPanel>
            </Tabs>
        )
    }


    return(
        <div className="w-full rounded-lg bg-white p-3 mb-2" key = {index}>
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <div className="font-bold mr-2">company:</div>
                    <div>{company.company[0]?.name}</div>
                </div>
                <button
                    onClick={()=>setExpanded((prevState)=> !prevState) }
                >
                    { expanded == false ? <ExpandCircleDownIcon/> : <CancelIcon/> }
                </button>
            </div>
            <div className="flex flex-wrap w-full">
                <div className="w-[95px] px-2">
                    <div className="font-bold">bookings</div>
                    <div className="">{company.bookings.length}</div>
                </div>
                <div className="w-[85px] px-2">
                    <div className="font-bold">pending</div>
                    <div className="">{company.pending.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">scheduled</div>
                    <div className="">{company.scheduled.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">accepted</div>
                    <div className="">{company.accepted.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">on route</div>
                    <div className="">{company.onroute.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">complete</div>
                    <div className="">{company.complete.length}</div>
                </div>
            </div>
            {
                (expanded == true) &&
                renderTabView()
            }

        </div>
    )
}

export default CompanyBookings;
