import { Link, useNavigate } from "react-router-dom"
import React,{createRef, useEffect, useState} from "react";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewWeightBilling from "./NewWeightBilling";
import Select from 'react-select'

const NewBilling = ({
    submitBillingsAction,
    countries,
    getCountriesAction
}) => {

    const navigate = useNavigate();
    const [weightFrom, setWeightFrom]= useState('');
    const [weightTo, setWeightTo]= useState('');
    const [country, setCountry]= useState(null);
    const [weightBillings, setWeightBillings] = useState([
        {
            driverRateRef: createRef(),
            bookingRateRef: createRef(),
            distanceFromRef: createRef(),
            distanceTo: createRef(),
            driverRate: '',
            bookingRate: '',
            distanceFrom: '',
            distanceTo: ''
        }
    ]);

    const onAddBilling = () => {
        setWeightBillings((billings)=>{
            let _billings = [...billings];
            _billings.push(
                {
                    driverRateRef: createRef(),
                    bookingRateRef: createRef(),
                    distanceFromRef: createRef(),
                    distanceTo: createRef(),
                    driverRate: '',
                    bookingRate: '',
                    distanceFrom: '',
                    distanceTo: ''
                }
            )
            return _billings
        })
    }

    const onBillingChanged = (value, key, index) => {
        setWeightBillings((billings)=>{
            let newBillings = [
                ...billings
            ]
            if (value && value != ''){ 
                newBillings[index][key] = Number(value)
            }
            else
            {
                newBillings[index][key] = ''
            }
            return newBillings
        })
    }
    const onCancelBilling = (index) =>{
        setWeightBillings((billings)=>{
            let _billings = [...billings];
            _billings.splice( index, 1)
            return _billings
        })
    }

    useEffect(()=>{
        getCountriesAction()
    },[])

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Billing</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/billings"}
                    className = "underline pr-2"
                >billings</Link>
            </div>
            <div className="flex flex-row w-full mt-5 items-center justify-between gap-5">
                <div className="w-full">
                    <label className="text-primary-1 font-bold">{'Weight >='}</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="number" 
                        value={weightFrom} 
                        onChange={(e)=> setWeightFrom(e.target.value)}
                        placeholder={"Enter Weight"}
                    />
                </div>
                <div className="w-full">
                    <label className="text-primary-1 font-bold">{"Weight <="}</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="number" 
                        value={weightTo} 
                        onChange={(e)=> setWeightTo(e.target.value)}
                        placeholder={"Enter weight"}
                    />
                </div>
                <div className="w-full">
                    <label className="text-primary-1 font-bold">Country</label>
                    <Select
                        className="w-full mt-2 rounded-lg"
                        placeholder="Select Country"
                        options={countries}
                        onChange={(value)=>setCountry(value)}
                        value={country}
                    />
                </div>
            </div>
            <div className="w-full">
                <label className="text-primary-1 font-bold">Weight Billings</label>
                <div className="flex flex-row w-full gap-5 items-center">
                    <label className="w-full text-primary-1 font-bold">{"Distance >="}</label>
                    <label className="w-full text-primary-1 font-bold">{"Distance <="}</label>
                    <label className="w-full text-primary-1 font-bold">Booking Rate / km</label>
                    <label className="w-full text-primary-1 font-bold">Driver Rate / km</label>
                    <label className="w-full text-primary-1 font-bold">Profite Rate /km</label>
                    <label className="text-primary-1 font-bold p-5"></label>
                </div>                
                {
                    weightBillings.map(({driverRateRef, driverRate, bookingRateRef, bookingRate, distanceFromRef, distanceFrom, distanceToRef,distanceTo}, index) => (
                        <NewWeightBilling
                            key={index}
                            index={index}
                            bookingRateRef={bookingRateRef}
                            driverRateRef={driverRateRef}
                            distanceFromRef={distanceFromRef}
                            distanceToRef={distanceToRef}
                            onCancelBilling={onCancelBilling}
                            onBillingChanged={onBillingChanged}
                            driverRate={driverRate}
                            bookingRate={bookingRate}
                            distanceFrom={distanceFrom}
                            distanceTo={distanceTo}
                        />
                    ))
                }
            </div>
            <div className="flex flex-row items-center justify-between">
                <button 
                    className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" 
                    onClick={()=> submitBillingsAction({
                        weightBillings,
                        weightFrom,
                        weightTo,
                        country: country.value,
                        navigate
                    })}
                >Submit</button>
                <button 
                    className="flex item-center justify-center p-1 bg-primary-1 text-white rounded-full h-fit"
                    onClick={()=>onAddBilling()}
                >
                    <AddCircleIcon fontSize="large"/>
                </button>
            </div>
                        
        </div>
    )

}

export default NewBilling;