import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialDashboardState = {
    companies: [],
    trips:{}
};


const {
    GET_DASHBOARD_DATA
} = actionConstants;

export const getDashboardDataAction = () =>{

    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.get(`${context.SERVER_URL}/api/dashboard`)
        .then(res=>{

            const { companies, trips } = res.data;
            
            dispatch({
                type: GET_DASHBOARD_DATA,
                payload:{
                    companies,
                    trips
                }
            })
        })
        .catch(err=>{
            console.log(err);
            //console.log(err.response.data)
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }    
}


function handleGetDashboardData (state, action){
    return update(state, {
        dashboard:{
            companies:{ $set: action.payload.companies },
            trips:{ $set: action.payload.trips },
        }
    })
}

export const DASHBOARD_ACTION_HANDLERS = {
    GET_DASHBOARD_DATA: handleGetDashboardData
}



