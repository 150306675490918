import 'react-confirm-alert/src/react-confirm-alert.css';
import * as ChatPermissions from '../modules/ChatPermissions';
import React, { useEffect, useRef, useState, useMemo } from "react"
import { useParams } from 'react-router-dom';
import * as ChatNewReceiver from '../../../sockets/receivers/ChatNewReceiver';
import * as ChatUpdateReceiver from '../../../sockets/receivers/ChatUpdateReceiver';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import SendIcon from '@mui/icons-material/Send';

const Chats = ({
    role,
    currentUserId,
    chats,
    getChatsDataAction,
    markAsReadAction,
    createChatAction
}) => {
    const { id } = useParams();
    const containerRef = useRef();
    const inputRef = useRef();
    const [message, setMessage] = useState('');
    const memoizedChats = useMemo(() => chats, [JSON.stringify(chats)]);

    useEffect(() => {
        getChatsDataAction(id);
    }, [])

    useEffect(() => {
        if(chats.some((chat) => chat.read === false && chat.sender !== currentUserId)) {
            markAsReadAction(id)
        }
        scrollToBottom();
    }, [memoizedChats])

    useEffect(() => {
        setTimeout(() => ChatNewReceiver.register(id), 100);
        return () => ChatNewReceiver.unregister(id);
    }, []);

    useEffect(() => {
        setTimeout(() => ChatUpdateReceiver.register(id), 100);
        return () => ChatUpdateReceiver.unregister(id);
    }, []);

    const scrollToBottom = () => {
        containerRef.current?.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth'
        });
    }

    const renderChat = (chat) => {
        const isSender = chat.sender === currentUserId;
        return (
            <div className={`flex ${isSender ? 'justify-end' : 'justify-start'} mb-4`}>
                <div className={`max-w-[80%] rounded-2xl py-2 px-4 ${isSender ? 'bg-gray-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
                    <p className="text-sm md:text-base">{chat.message}</p>
                    {isSender && (
                        <span className="flex justify-end text-sm">
                            {chat.read ? (
                                <DoneAllOutlinedIcon fontSize="small" className="text-gray-200" />
                            ) : (
                                <CheckOutlinedIcon fontSize="small" className="text-gray-200" />
                            )}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const handleSendMessage = () => {
        const trimmedMessage = message.trim();
        if (trimmedMessage) {
            createChatAction(trimmedMessage, id);
            setMessage('');
            inputRef.current.focus();
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    }

    return (
        <div className="flex flex-col h-screen w-full bg-gray-100">
            <div className="bg-white shadow-md p-4 w-full">
                <h1 className="text-2xl font-bold text-gray-600">Chats</h1>
            </div>
            <div className="flex-grow overflow-hidden w-full">
                <div ref={containerRef} className="h-full w-full overflow-y-auto px-4 py-6">
                    {chats.map((chat, index) => (
                        <React.Fragment key={index}>
                            {renderChat(chat)}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="bg-white p-4 shadow-md w-full">
                <div className="flex items-center bg-gray-100 rounded-full p-2 w-full">
                    <input
                        ref={inputRef}
                        type="text"
                        className="flex-grow bg-transparent outline-none px-4 w-full"
                        placeholder="Type your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <button
                        className={`ml-2 rounded-full p-2 ${message.trim() ? 'bg-gray-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                        onClick={handleSendMessage}
                        disabled={!message.trim()}
                    >
                        <SendIcon fontSize="small" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Chats;
