import React from 'react';
import PointComponent from './PointComponent';
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from "@react-google-maps/api";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const BookingDirections = ({
    directions,
    points,
    setPoints,
    onPointChanged,
    removePoint,
    onAddStop,
    calculateRoute,
    mapRef,
    setBookingStage,
    products,
    onSetPointCapacity,
    productCategories
}) => {

    return (
        <div className='w-full h-full'>
            <h4 className='mb-2'>My Directions</h4>
            <div className="w-full flex flex-col">
                <label className="text-green-custom font-bold">Stops</label>

                <ol class="relative border-s border-gray-200 dark:border-gray-700">

                    {
                        points.map((point, index) => (
                            <li class="mb-10 ms-16 mx-2">
                                <span class="absolute flex items-center justify-center px-2 py-1 bg-gray-200 text-xs rounded-full -start-3 ring-8 ring-white">
                                    {point.type.label}
                                </span>
                                <PointComponent
                                    point={point}
                                    index={index}
                                    onPointChanged={onPointChanged}
                                    removePoint={removePoint}
                                    points={points}
                                    setPoints={setPoints}
                                    onAddStop={onAddStop}
                                    calculateRoute={calculateRoute}
                                    products={products}
                                    onSetPointCapacity={onSetPointCapacity}
                                    productCategories={productCategories}
                                />
                            </li>

                        ))
                    }
                </ol>
            </div>

            {
                directions && (
                    <>
                    <div className="w-full h-1/2 my-10">
                <label className="text-green-custom font-bold">Map View</label>

                {
                    <GoogleMap
                        mapContainerStyle={{
                            width: 100 + "%",
                            height: 100 + "%",
                            borderRadius: 10,
                            borderColor: "GrayText",
                            borderWidth: 2,
                            marginTop: 4
                        }}
                        zoom={15}
                        onLoad={(map) => {
                            mapRef.current = map;
                        }}
                    >
                        {
                            directions &&
                            <div className="absolute left-3 top-16 bg-white p-4 text-xl text-red-500 font-bold border-gray-300 border-2">
                                {`${(directions.routes[0].legs.reduce((value, leg) => value + leg.distance.value, 0) / 1000).toFixed(2)} km`}
                            </div>
                        }
                        {
                            directions &&
                            <DirectionsRenderer
                                options={{
                                    markerOptions: {
                                        icon: {
                                            path: 'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                                            fillColor: 'rgb(0,0,255)',
                                            fillOpacity: 1,
                                            scale: 1.2,
                                            strokeColor: 'rgba(255, 255, 255, 0.6)',
                                            strokeWeight: 15,
                                        },
                                        anchorPoint: {
                                            x: 0.5,
                                            y: 0.5
                                        }
                                    }
                                }}
                                directions={directions}
                            />
                        }
                    </GoogleMap>
                }
            </div>

            <div className='flex flex-row w-full items-center justify-between pb-12'>
                <button
                    className="flex items-center justify-center px-4 py-2 bg-green-custom text-white rounded-lg mt-5"
                    onClick={() => setBookingStage("details")}
                >back</button>
                <button
                    className="flex items-center justify-center px-4 py-2 bg-green-custom text-white rounded-lg mt-5"
                    onClick={() => setBookingStage("summary")}
                >Next</button>
            </div>
                    </>
                )
            }



        </div>
    )
}

export default BookingDirections;
