import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { images } from "../../../assets";


import Select from "react-select";
import context from "../../../context";

const libraries = ["places"];

const REGISTRATION_TYPES = [
  { label: "User", value: "user" },
  { label: "Driver", value: "driver" },
  { label: "Company", value: "company" },
  { label: "Fleet", value: "fleet" },
];

const Register = ({ registerAction, showErrorAction }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: context.GOOGLE_API_KEY,
    libraries,
  });

  const navigate = useNavigate();

  const [email, setEmail] = useState(""); //'tawo@mail.com'
  const [password, setPassword] = useState(""); //1234
  const [confirmPassword, setConfirmPassword] = useState("");
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [registrationType, setRegistrationType] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [fleetName, setFleetName] = useState("");

  const [locationAutocomplete, setLocationAutocomplete] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const onEmailChanged = (email) => {
    setEmail(email.trim().toLowerCase());
  };

  const onPasswordChanged = (password) => {
    setPassword(password.trim());
  };

  const onConfirmPasswordChanged = (password) => {
    setConfirmPassword(password.trim());
  };

  const onContactChanged = (contact) => {
    setContact(contact.trim());
  };

  const onNameChanged = (name) => {
    setName(name.trim());
  };

  const onCompanyNameChanged = (companyName) => {
    setCompanyName(companyName);
  };

  const onFleetNameChanged = (fleetName) => {
    setFleetName(fleetName);
  };

  const onSurnameChanged = (surname) => {
    setSurname(surname.trim());
  };

  const handleSubmit = async () => {
    if (
      name == "" ||
      surname == "" ||
      email == "" ||
      password == "" ||
      contact == "" ||
      (registrationType?.value == "company" &&
        (companyName == "" || selectedLocation == null)) ||
      (registrationType?.value == "fleet" &&
        (fleetName == "" || selectedLocation == null))
    ) {
      showErrorAction({
        showError: true,
        errorMessage: "Please complete all fields.",
      });
    } else if (password !== confirmPassword) {
      showErrorAction({
        showError: true,
        errorMessage: "Passwords do not match!",
      });
    } else {
      const data = {
        type: registrationType?.value,
        ...(registrationType?.value == "company" && { companyName }),
        ...(registrationType?.value == "fleet" && { fleetName }),
        ...(registrationType?.value == "company" && {
          companyLocation: selectedLocation,
        }),
        ...(registrationType?.value == "fleet" && {
          fleetLocation: selectedLocation,
        }),
        email,
        password,
        firstName: name,
        lastName: surname,
        contactNumber: contact,
      };

      registerAction({ data, navigate });
    }
  };

  return (
    <div className=" flex flex-col justify-center  w-full h-full bg-white items-center overflow-y-auto">
      <section className="bg-white">
        <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
          <section className="relative flex h-32 w-full items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6 border-red-500 -left-16">            <img
            alt="Night"
            src={images.truckRegisterPicture}
            className="absolute inset-0 h-full w-full object-cover object-left opacity-80"
          />

            <div className="hidden lg:relative lg:block md:p-12 md:pl-28  ">
              {/* <a className="block text-white" href="/">
                <span className="sr-only">Home</span>
                LOGO
              </a> */}

              <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                Welcome to Wheelsitnow
              </h2>

              <p className="mt-4 leading-relaxed text-white/90">
                At Wheelsitnow, we are committed to providing you with a seamless and reliable transportation experience. Our platform offers real-time updates on your booking's status, detailed information on your driver's location, and much more. Whether you're scheduling a ride for immediate pickup or planning ahead, Wheelsitnow ensures that you stay informed and in control every step of the way.
              </p>

            </div>
          </section>

          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6 md:w-[900px]">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="relative -mt-16 block lg:hidden">
                {/* <a
                  className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-white text-blue-600 sm:h-20 sm:w-20"
                  href="/"
                >
                  <span className="sr-only">Home</span>
                  LOGO
                </a> */}

                <h1 className="mt-2 text-2xl font-bold text-primary-2 sm:text-3xl md:text-4xl">
                  Welcome Wheelsitnow
                </h1>

                <p className="mt-4 leading-relaxed text-gray-500">
                At Wheelsitnow, we are committed to providing you with a seamless and reliable transportation experience. Our platform offers real-time updates on your booking's status, detailed information on your driver's location, and much more. Whether you're scheduling a ride for immediate pickup or planning ahead, Wheelsitnow ensures that you stay informed and in control every step of the way.

                </p>
              </div>

              <div className="">
                <div className="flex flex-wrap w-full  ">
                  <div className={`w-full flex flex-col mt-2 pr-2 ${(registrationType === "") ? 'md:w-full' : 'md:w-1/2'}`}>
                    <label className="text-xl font-light pb-4">
                      Account Type
                    </label>
                    <Select
                      className="text-gray-500 text-lg md:w-full bg-white"
                      placeholder="Select registration type"
                      options={REGISTRATION_TYPES}
                      onChange={(value) => setRegistrationType(value)}
                      value={registrationType}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderColor: "#3F826D",
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderWidth: 2,
                          borderRadius: 9,
                        }),
                      }}
                    />
                  </div>

                  {registrationType?.value == "company" && (
                    <>
                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">
                          Company Name
                        </label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="text"
                          onChange={(e) => onCompanyNameChanged(e.target.value)}
                          placeholder={"Enter company name"}
                          value={companyName}
                        />
                      </div>

                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">
                          Company Address
                        </label>
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (locationAutocomplete !== null) {
                              const place = locationAutocomplete.getPlace();
                              const {
                                formatted_address,
                                geometry: { location },
                                place_id,
                              } = place;

                              setSelectedLocation({
                                address: formatted_address,
                                latitude: location.lat(),
                                longitude: location.lng(),
                                place_id,
                              });
                            } else {
                              console.log("Autocomplete is not loaded yet!");
                            }
                          }}
                          onLoad={(autocomplete) => {
                            setLocationAutocomplete(autocomplete);
                          }}
                          options={{
                            types: ["address"],
                            //componentRestrictions: { country: "za" },
                          }}
                        //defaultValue="Amsterdam"
                        >
                          <input
                            className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white w-full"
                            type="text"
                          ></input>
                        </Autocomplete>
                      </div>
                    </>
                  )}

                  {registrationType?.value == "fleet" && (
                    <>
                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">
                          Fleet Name
                        </label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="text"
                          onChange={(e) => onFleetNameChanged(e.target.value)}
                          placeholder={"Enter fleet name"}
                          value={fleetName}
                        />
                      </div>

                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">
                          Fleet Address
                        </label>
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (locationAutocomplete !== null) {
                              const place = locationAutocomplete.getPlace();
                              const {
                                formatted_address,
                                geometry: { location },
                                place_id,
                              } = place;

                              setSelectedLocation({
                                address: formatted_address,
                                latitude: location.lat(),
                                longitude: location.lng(),
                                place_id,
                              });
                            } else {
                              console.log("Autocomplete is not loaded yet!");
                            }
                          }}
                          onLoad={(autocomplete) => {
                            setLocationAutocomplete(autocomplete);
                          }}
                          options={{
                            types: ["address"],
                            //componentRestrictions: { country: "za" },
                          }}
                        //defaultValue="Amsterdam"
                        >
                          <input
                            className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white w-full"
                            type="text"
                          ></input>
                        </Autocomplete>
                      </div>
                    </>
                  )}

                  {registrationType != "" && (
                    <>
                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">Name</label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="text"
                          onChange={(e) => onNameChanged(e.target.value)}
                          placeholder={"Enter name"}
                          value={name}
                        />
                      </div>

                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">
                          Surname
                        </label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="text"
                          onChange={(e) => onSurnameChanged(e.target.value)}
                          placeholder={"Enter surname"}
                          value={surname}
                        />
                      </div>

                      <div className="w-full flex flex-col mt-2 pr-2 md:w-1/2">
                        <label className="text-xl font-light pb-4">Email</label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="email"
                          onChange={(e) => onEmailChanged(e.target.value)}
                          placeholder={"Enter email"}
                          value={email}
                        />
                      </div>

                      <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                        <label className="text-xl font-light pb-4">
                          Contact:
                        </label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="number"
                          onChange={(e) => onContactChanged(e.target.value)}
                          placeholder={"Enter contact"}
                          value={contact}
                        />
                      </div>

                      <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                        <label className="text-xl font-light pb-4">
                          Password:
                        </label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="password"
                          onChange={(e) => onPasswordChanged(e.target.value)}
                          placeholder={"Enter password"}
                          value={password}
                        />
                      </div>

                      <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                        <label className="text-xl font-light pb-4">
                          Confirm Password:
                        </label>
                        <input
                          className="border-2 border-teal-700 rounded-lg p-4 text-gray-500 text-lg bg-white"
                          type="password"
                          onChange={(e) =>
                            onConfirmPasswordChanged(e.target.value)
                          }
                          placeholder={"Enter password confirmation"}
                          value={confirmPassword}
                        />
                      </div>

                      <button
                        className="bg-teal-600 rounded-lg p-4 text-white font-semibold w-full mt-10 md:w-1/2 md:mt-12"
                        onClick={() => handleSubmit()}
                        disabled={false}
                      >
                        Register
                      </button>
                    </>
                  )}
                </div>

                <div className="pt-4">
                  <p className="mt-4 text-sm text-gray-500 sm:mt-0">
                    Already have an account?
                    <Link to="/login" className="text-primary-1 underline">
                      Log in
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
    </div>
  );
};

export default Register;
