import * as CarPermissions from '../modules/CarPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../../context'

const ViewCar = ({
    role
}) =>{

    const {id} = useParams();

    const [car, setCar] = useState({
        make: "",
        model: "",
        year: ""
    })


    const fetchCar = () => {
        axios.get(`${context.SERVER_URL}/api/cars/${id}`)
        .then((response)=>{

            const {make, model, year} = response.data;
            setCar({
                make,
                model,
                year
            })
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchCar()
    },[])

    return(
        <div className='p-3'>
            <div className='flex flex-row'>
                <Link 
                    to = {"/cars"}
                    className = "underline pr-2"
                >cars</Link> | 
                {
                    CarPermissions.isAuthorized("edit",role) &&
                    <Link 
                        to = {`/cars/edit/${id}`}
                        className = "underline pl-2"
                    >edit</Link>
                }
            </div>
            <h1 className='text-primary-2 text-lg font-bold'>View Company</h1>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Make:</label>
                <div className='text-gray-500 font-bold'>{car.make}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Model:</label>
                <div className='text-gray-500 font-bold'>{car.model}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Year:</label>
                <div className='text-gray-500 font-bold'>{car.year}</div>
            </div>
        </div>
    )
}

export default ViewCar;