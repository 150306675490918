export default{
    GET_TRIPS_DATA: "GET_TRIPS_DATA",
    GET_TRIP_DATA: "GET_TRIP_DATA",
    TRAILER_LOCATION_UPDATE: "TRAILER_LOCATION_UPDATE",
    ACCEPT_TRIP: "ACCEPT_TRIP",
    IS_LOADING: "IS_LOADING",
    ONROUTE_TRIP: "ONROUTE_TRIP",
    DECLINE_TRIP: "DECLINE_TRIP",
    COMPLETE_TRIP: "COMPLETE_TRIP",
    POINT_ARRIVED: "POINT_ARRIVED",
    POINT_LOADING: "POINT_LOADING",
    POINT_OFFLOADING: "POINT_OFFLOADING",
    POINT_COMPLETE: "POINT_COMPLETE",
    SOCKET_TRIP_POINT_UPDATE: "SOCKET_TRIP_POINT_UPDATE",
    SOCKET_TRIP_UPDATE: "SOCKET_TRIP_UPDATE",
}