import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ForgotPassword from '../../password/ForgotPassword';
import LandingContainer from '../../landing/container/LandingContainer';
import LoginContainer from '../../login/container/LoginContainer';
import Modal from 'react-modal'
import NavBar from './NavBar';
import NewGuestBooking from '../../guest/NewGuestBooking';
import RegisterByInviteContainer from '../../register/container/RegisterByInviteContainer';
import RegisterContainer from '../../register/container/RegisterContainer';
import ResetPassword from '../../password/ResetPassword';
import ViewGuestBooking from '../../guest/ViewGuestBooking';

const Navigation = ({
    userToken,
    logoutAction,
    firstName,
    role
}) =>{

  useEffect(()=>{
    Modal.setAppElement('#navigation');
  },[])


  if(!userToken)
  {
    return (
      <div className=' h-screen' id="navigation" >
          <Router>
            <Routes>
            <Route 
                path = "/"
                element = {<LandingContainer/>}
              />
              <Route 
                path = "/login"
                element = {<LoginContainer/>}
              />
              <Route 
                path = "/register"
                element = {<RegisterContainer/>}
              />
              <Route 
                path = "/registerbyinvite/:token"
                element = {<RegisterByInviteContainer/>}
              />
              <Route 
                path = "/forgotpassword"
                element = {<ForgotPassword/>}
              />
              <Route 
                path = "/passwordresetbyinvite/:token"
                element = {<ResetPassword/>}
              />
              <Route
                path = "/newGuestBooking"
                element = {<NewGuestBooking/>}
              />
              <Route
                path = "/viewGuestBooking/:id"
                element = {<ViewGuestBooking/>}
              />
            </Routes>
           
        </Router>
      </div>

    )
  }

  return (
    <div className='w-screen  h-screen bg-neutral-1' id="navigation">
        <Router>
          <NavBar
            userToken ={userToken}     
            logoutAction = {logoutAction}
            firstName = {firstName} 
            role={role}      
          />
        </Router>
    </div>
  );
}

export default Navigation;
