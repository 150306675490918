import {connect} from 'react-redux'
import NewBilling from '../components/NewBilling';
import { showErrorAction } from '../../common/modules/modal';
import { 
    submitBillingsAction,
    getCountriesAction 
} from '../modules/billings';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    countries: state.billings.countries

});

const mapActionCreators = {
    showErrorAction,
    submitBillingsAction,
    getCountriesAction
};

export default connect (mapStateToProps,mapActionCreators)(NewBilling); 