import { getChatsDataAction } from "../../routes/chats/modules/chats";

import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (bookingId) => {
    try {
        const socket = await getSocket();
        socket.on(`ChatNew_${bookingId}`, (data) => {
            ReduxStorage.store.dispatch(getChatsDataAction(bookingId));
        })
    } catch (error) {
        console.log("Error registering new cxhat receiver: ", error);
    }
}

export const unregister = async (bookingId) => { 
    try{
        const socket = await getSocket();
        socket.off(`ChatNew_${bookingId}`);
    }catch(error){
        console.log("Error unregistering new booking receiver", error);
    }
}