import React, {useEffect, useState,} from 'react'

import CancelIcon from '@mui/icons-material/Cancel';

const NewWeightBilling = ({
    onCancelBilling,
    onBillingChanged,
    driverRateRef,
    bookingRateRef,
    distanceToRef,
    distanceFromRef,
    driverRate,
    bookingRate,
    distanceFrom,
    distanceTo,
    index
}) =>{
    const [profitRate, setProfitRate] = useState(0);

    


    useEffect(()=>{
        let profitRate = 0;
        if (driverRate && bookingRate)
        {
            profitRate = Number(bookingRate) - Number(driverRate)
        }
        setProfitRate(profitRate)
    },[driverRate, bookingRate])
    
    

    return(
       
        
        <tr  className="divide-x divide-gray-200">
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
          <input 
                    className="w-full p-2 mt-2 rounded-lg border" 
                    type="number"
                    ref={distanceFromRef}
                    value={distanceFrom}
                    onChange={(e)=> onBillingChanged(e.target.value, "distanceFrom", index)}
                    placeholder={"Enter lower distance limit"}
                />
          </div>
        </th>

        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
          <input 
                    className="w-full p-2 mt-2 rounded-lg border" 
                    type="number" 
                    ref={distanceToRef} 
                    value={distanceTo}
                    onChange={(e)=> onBillingChanged(e.target.value, "distanceTo", index)}
                    placeholder={"Enter upper distance limit"}
                />
          </div>
        </th>
            
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
          <input 
                    className="w-full p-2 mt-2 rounded-lg border" 
                    type="number"
                    ref={bookingRateRef}
                    value={bookingRate}
                    onChange={(e)=> onBillingChanged(e.target.value, "bookingRate", index)}
                    placeholder={"Enter booking per km rate"}
                />
          </div>
        </th>
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
          <input 
                    className="w-full p-2 mt-2 rounded-lg border" 
                    type="number" 
                    ref={driverRateRef} 
                    value={driverRate}
                    onChange={(e)=> onBillingChanged(e.target.value, "driverRate", index)}
                    placeholder={"Enter driver per km rate"}
                />
          </div>
        </th>

        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
          <div className="w-full">
                <div className="w-full p-2 mt-2 rounded-lg border bg-white">{profitRate}</div>
            </div>
          </div>
        </th>
        

        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
          {
                (index == 0) ?
                <div className="p-4">

                </div>
                :   
                <button 
                    className="flex item-center justify-center p-1 bg-primary-1 text-white rounded-full h-fit"
                    onClick={()=>onCancelBilling(index)}
                >
                    <CancelIcon fontSize="large"/>
                </button>
            }
          </div>
        </th>
             
            
            

        </tr>


   
    )
}

export default NewWeightBilling;