import React, { useEffect, useState } from  "react"
import { TIMESLOTS, getDates, MILES } from "../../../util/date.util";

import Select from 'react-select'
import TripComponent from "./TripComponent";
import axios from "axios"
import context from "../../../context";
import * as TripNewReceiver from '../../../sockets/receivers/TripNewReceiver';
import ReactPaginate from "react-paginate";

const Trips  = ({
    role,
    trips,
    getTripsDataAction,
    currentPage,
    totalPages
}) =>{

    const [filters, setFilters] = useState({
        statusFilter: { value: '', label: 'Select Status' }, 
        searchFilter: '',
        fleetFilter: { value: '', label: 'Select Fleet' },
        dateFilter: { value: '', label: 'Select Date' },
        timeslotFilter: { value: '', label: 'Select Timeslot' },
        pageFilter: 1,
        mileFilter: { value: '', label: 'Select Mile' },
        limitFilter: 10
    });

    const [filterOptions, setFilterOptions] = useState({
        statuses:[
            { value: 'pending', label: 'Pending' },
            { value: 'scheduled', label: 'Scheduled' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'onroute', label: 'On Route' },
            { value: 'complete', label: 'Complete' },
        ],
        fleets: [],
        dates: getDates(),
        timeslots: TIMESLOTS,
        miles: MILES
    })

    const fetchFleets = () => {
        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{   
            const fleets = response.data.fleets.map((fleet) => ({ label: fleet.name, value: fleet._id }));
            setFilterOptions((prevState)=>({
                ...prevState,
                fleets
            }));

        }).catch((error)=>{
            console.log(error);
        })
    }

    const tripNewReceiverCallback = () =>{
        getTripsDataAction(filters)
    }

    useEffect(()=>{
        getTripsDataAction(filters);
        fetchFleets();
    },[])

    useEffect(()=>{
        setTimeout(()=> TripNewReceiver.register(tripNewReceiverCallback), 100);
        return () => TripNewReceiver.unregister();
    }, [])

    return (
        <div className="w-full bg-white overflow-auto p-3">
            <h1 className="text-primary-1 font-bold mb-2">Trips</h1>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col w-full md:flex-row gap-4 mb-5" >
                <div className="grid grid-cols-7 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Search filter</label>
                        <input 
                            className="w-full py-2 px-4 rounded-lg border"
                            placeholder="Search"
                            onChange={({target:{value}}) => setFilters((prevState)=>({
                                ...prevState,
                                searchFilter: value
                            }))}
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Date filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Date"
                            options={filterOptions.dates}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                dateFilter: value
                            }))}
                            value={filters.dateFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Timeslot filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Timeslot"
                            options={filterOptions.timeslots}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                timeslotFilter: value
                            }))}
                            value={filters.timeslotFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Status filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select status"
                            options={filterOptions.statuses}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                statusFilter: value
                            }))}
                            value={filters.statusFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Fleet filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Fleet"
                            options={filterOptions.fleets}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                fleetFilter: value
                            }))}
                            value={filters.fleetFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Mile filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Mile"
                            options={filterOptions.miles}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                mileFilter: value
                            }))}
                            value={filters.mileFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>getTripsDataAction(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = { 
                                statusFilter: { value: '', label: '' }, 
                                searchFilter: '',
                                fleetFilter: { value: '', label: '' },
                                dateFilter: { value: '', label: '' },
                                timeslotFilter: { value: '', label: '' },
                                mileFilter: { value: '', label: '' },
                                pageFilter: 1,
                                limitFilter: 10
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            getTripsDataAction(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>
            <div>
                {
                    trips.map((trip)=>(<TripComponent trip={trip} role={role}/>))
                }
            </div>

            <div className="flex flex-row w-full items-center justify-end mt-5">
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={({selected}) => {
                        let _filters = {
                            ...filters,
                            pageFilter: selected + 1
                        } 
                        getTripsDataAction(_filters);
                        setFilters(_filters);
                    }}
                    containerClassName={'pagination'}
                    pageClassName={'page'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'previous'}
                    nextClassName={'next'}
                    activeClassName={'active'}
                    disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}

export default Trips;