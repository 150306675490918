import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as FleetPermissions from "../modules/FleetPermissions"

import React, { useEffect, useState } from "react"

import {Link} from 'react-router-dom'
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from "../../../context";
import { COLOR_CODES } from '../../../util/general.util';
import ReactPaginate from 'react-paginate';

const Fleets = ({
    role,
    showLoaderAction
}) => {

    const [fleets, setFleets] = useState([]);
    const [totalPages, setTotalPages] = useState(100);
    const [filters, setFilters] = useState({
      searchFilter: "",
      pageFilter: 1,
      limitFilter: 10
    });

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    useEffect(()=>{
        fetchFleets(filters);
    },[])


    const fetchFleets = (filters) => {
        const { searchFilter, pageFilter, limitFilter } = filters;

        showLoaderAction(true)
        axios.get(`${context.SERVER_URL}/api/fleets?searchFilter=${searchFilter.trim()}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{  
            const { fleets, totalPages, currentPage } = response.data; 
            setFleets(fleets);
            setTotalPages(totalPages)

        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }

    const deleteFleet = (id) =>{

        const options = {
            title: 'Delete Fleet',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/fleets/${id}`)
                    .then((response)=>{
                        const _fleets = fleets.filter((fleet)=> fleet._id != id)
                        setFleets(_fleets); 
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };
        
        confirmAlert(options);
    }

    return(
        <div className="w-full bg-white p-5 overflow-auto">
            <div className="flex flex-row w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Fleets</h1>
                {
                    FleetPermissions.isAuthorized("new", role) &&
                    <a href="/fleets/new">
                        <div className="bg-primary-1 rounded-md text-white px-3 py-2">New Fleet</div>
                    </a>
                }
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-row w-full gap-4">
                <div className="grid grid-cols-2 gap-4 w-full">
                    <div className="col-span-1">
                        <input
                            className="w-full p-2 rounded-lg border"
                            placeholder="Search"
                            onChange={({target: {value}})=>{ 
                                setFilters((prevState)=>({
                                    ...prevState,
                                    searchFilter: value
                                }))
                            }}
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="flex col-span-1 gap-2 items-center">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2"
                        onClick={()=>fetchFleets(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2"
                        onClick={()=>{
                            let resetState = {
                                searchFilter: "",
                                pageFilter: 1,
                                limitFilter: 10
                            }
                            setFilters(resetState)
                            fetchFleets(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Status
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Created At
                            </th>

                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Address
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Select</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fleets.map((fleet, index) => {
                            return (
                                <tr key={fleet._id}>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-transparent",
                                            "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                        )}
                                    >
                                        <div className="font-medium text-primary-2">
                                            {fleet.name}
                                        </div>

                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.fleet?.status[fleet.status.toLowerCase()]}`}>
                                            {fleet.status}
                                        </span>
                                    </td>

                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {fleet.createdAt}
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{fleet.locationAddress}</div>
                                    </td>
                                    <td
                                        className="border-t border-transparent flex
                                            relative py-3.5 text-right text-sm font-medium"

                                    >


                                        {
                                            FleetPermissions.isAuthorized("view", role) &&
                                            <Link to={`/fleets/view/${fleet._id}`}>
                                                <div className="border p-2 m-2 rounded-md">View</div>
                                            </Link>
                                        }
                                        {
                                            FleetPermissions.isAuthorized("edit", role) &&
                                            <Link to ={`/fleets/edit/${fleet._id}`}>
                                                <div  className="border border-green-500 text-primary-1 p-2 m-2 rounded-md"> Edit </div>
                                            </Link>
                                        }
                                        {
                                            FleetPermissions.isAuthorized("delete", role) &&
                                            <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>deleteFleet(fleet._id)}> Delete </button>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="flex flex-row w-full items-center justify-end mt-5">
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={({selected}) => {
                        let _filters = {
                        ...filters,
                        pageFilter: selected + 1
                        } 
                        fetchFleets(_filters);
                        setFilters(_filters);
                    }}
                    containerClassName={'pagination'}
                    pageClassName={'page'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'previous'}
                    nextClassName={'next'}
                    activeClassName={'active'}
                    disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}

export default Fleets;




