import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showErrorAction, showLoaderAction } from '../../common/modules/modal';

export const initialRegisterState = {
    showError: false,
    errorMessage: ""
};


const {
    REGISTER,
} = actionConstants;

export const registerAction = ({data, navigate}) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.post(`${context.SERVER_URL}/api/register`,
        {
            ...data
        })
        .then(res=>{
            const { 
                userToken, 
                user:{
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    _id
                } 
            } = res.data;

            dispatch({
                type: REGISTER,
                payload:{
                    userToken,
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    id: _id
                }
            })

            navigate("/")

        })
        .catch(err=>{
            console.log(err)

            dispatch(showErrorAction({
                showError: true,
                errorMessage: err.response?.data?.message || "An error occured."
            }))
            console.log(err.response.data)
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }    
}


export const registerByInviteAction = ({data, navigate}) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.post(`${context.SERVER_URL}/api/registerByInvite`,
        {
            ...data
        })
        .then(res=>{
            const { 
                userToken, 
                user:{
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    _id
                } 
            } = res.data;

            dispatch({
                type: REGISTER,
                payload:{
                    userToken,
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    id: _id
                }
            })
            navigate("/")

        })
        .catch(err=>{
            dispatch(showErrorAction({
                showError: true,
                errorMessage: err.response?.data?.message || "An error occured."
            }))
        }).finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }    
}

function handleRegisterAction (state, action){
    return update(state, {
        login:{
            userToken: { $set: action.payload.userToken },
        },
        profile:{
            firstName: { $set: action.payload.firstName },
            lastName: { $set: action.payload.lastName},
            role: { $set: action.payload.role },
            passport: { $set: action.payload.passport },
            company: { $set: action.payload.company },
            dob: { $set: action.payload.dob },
            nationalId: { $set: action.payload.nationalId },
            address: { $set: action.payload.address },
            contactNumber: { $set: action.payload.contactNumber },
            status: { $set: action.payload.status },
            email: { $set: action.payload.email },
            fleet: { $set: action.payload.fleet },
            id: { $set: action.payload.id }
        }
    })
}

export const REGISTER_ACTION_HANDLERS = {
    REGISTER: handleRegisterAction,
}



