import {connect} from 'react-redux'
import EditCar from '../components/EditCar';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(EditCar); 