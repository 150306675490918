import React,{useEffect, useState} from "react";

import { Link } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import context from "../../../context/index"

const NewTrailer = () => {

    const [fleets, setFleets] = useState([]);

    const [selectedFleet, setSelectedFleet]= useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [cargoTypes, setCargoTypes] = useState([]);
    const [selectedCargoTypes, setSelectedCargoTypes] = useState([]);

    const [colors, setColors] = useState('');
    const [selectedColor, setSelectedColor] = useState('');

    const [trailerTypes, setTrailerTypes] = useState([]);
    const [selectedTrailerType, setSelectedTrailerType] = useState('')
    const [capacity, setCapacity] = useState({
        weight: 0,
        height: 0,
        width: 0,
        leng: 0
    });

    const submitTrailer = () => {

        axios.post(`${context.SERVER_URL}/api/trailers`,
        {
            data:
            {
                fleet: selectedFleet.value,
                capacity,
                registrationNumber,
                cargoTypes: cargoTypes.map((cargo) => cargo.value),
                trailerType: selectedTrailerType.value,
                color: selectedColor.value
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    const fetchFleets = () => {
        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{   
            const _fleets = response.data.map((fleet)=>{
                return { label:  fleet.name, value: fleet._id }
            });
            setFleets(_fleets);

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchColors = () => {
        axios.get(`${context.SERVER_URL}/api/colorTypes`)
        .then((response)=>{   
            const _colors = response.data.map((color)=>{
                return { label:  color, value: color }
            });
            setColors(_colors);

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchCargoTypes = () => {
        axios.get(`${context.SERVER_URL}/api/cargoTypes`)
        .then((response)=>{   
            const _cargoTypes = response.data.map((cargoType)=>{
                return { label:  cargoType, value: cargoType }
            });
            setCargoTypes(_cargoTypes);

        }).catch((error)=>{
            console.log(error);
        })
    }


    const fetchTrailerTypes = () => {
        axios.get(`${context.SERVER_URL}/api/trailerTypes`)
        .then((response)=>{   
            const _trailerTypes = response.data.map((trailerType)=>{
                return { label:  trailerType, value: trailerType }
            });
            setTrailerTypes(_trailerTypes);

        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        fetchFleets();
        fetchCargoTypes();
        fetchColors();
        fetchTrailerTypes();
    },[])

    const onCapacityChanged = (key, value) =>{
        setCapacity((prevState)=>({
            ...prevState,
            [key]: value == '' ? '' : Number(value)
        }))

    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Trailer</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/trailers"}
                    className = "underline pr-2"
                >trailers</Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-4">

                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Fleet:</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Fleet"
                        options={fleets}
                        onChange={(value)=>setSelectedFleet(value)}
                        value={selectedFleet}
                    />
                </div>
               
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Trailer Type:</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Trailer Type"
                        options={trailerTypes}
                        onChange={(value)=>setSelectedTrailerType(value)}
                        value={selectedTrailerType}
                    />
                </div>

                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Reg no:</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="text" 
                        value={registrationNumber} 
                        onChange={(e)=> setRegistrationNumber(e.target.value)}
                        placeholder={"Enter Registration Number"}
                    />
                </div>
                
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Weight:</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="number" 
                        value={capacity.weight} 
                        onChange={(e)=> onCapacityChanged("weight", e.target.value)}
                        placeholder={"Enter weight"}
                    />
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Length:</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="number" 
                        value={capacity.leng} 
                        onChange={(e)=> onCapacityChanged("leng", e.target.value)}
                        placeholder={"Enter length"}
                    />
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Width:</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="number" 
                        value={capacity.width} 
                        onChange={(e)=> onCapacityChanged("width", e.target.value)}
                        placeholder={"Enter width"}
                    />
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Height:</label>
                    <input 
                        className="w-full p-2 mt-2 rounded-lg" 
                        type="number" 
                        value={capacity.height} 
                        onChange={(e)=> onCapacityChanged("height", e.target.value)}
                        placeholder={"Enter height"}
                    />
                </div>

                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <label className="text-primary-1 font-bold">Color:</label>
                   <Select
                        className="w-full mt-2"
                        placeholder="Select Color"
                        options={colors}
                        onChange={(value)=>setSelectedColor(value)}
                        value={selectedColor}
                    />
                </div>

                <div className="col-span-1 md:col-span-2 lg:col-span-4">
                    <label className="text-primary-1 font-bold">Cargo Types:</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Color"
                        options={cargoTypes}
                        onChange={(value)=>setSelectedCargoTypes(value)}
                        value={selectedCargoTypes}
                        isMulti={true}
                    />
                </div>
            </div>

            
            <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> submitTrailer()}>Submit</button>
                        
        </div>
    )

}

export default NewTrailer;