import * as SupportPermissions from '../modules/SupportPermissions';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import context from '../../../context';

import { SUPPORT_STATUSES, SUPPORT_CATEGORIES } from '../../../util/general.util';

const EditSupport = ({
    role,
    getSupportDataAction,
    updateSupportAction,
    support
}) => {

    const { id } = useParams();
    const [status, setStatus] = useState(null);
    const [notes, setNotes] = useState('');
    const [category, setCategory] = useState(null);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (support) {
            let _status = SUPPORT_STATUSES?.find((option) => option.value === support?.status);
            let _category = SUPPORT_CATEGORIES?.find((option) => option.value === support?.category);
            setStatus(_status);
            setNotes(support?.notes);
            setMessage(support?.message);
            setCategory(_category);
        }
    }, [support]);

    useEffect(() => {
        getSupportDataAction(id);
    }, [id, getSupportDataAction]);

    return (
        <div className="min-h-screen w-full p-8 bg-gray-50 text-gray-800">
            <div className='flex justify-between items-center mb-6'>
                <h1 className="text-3xl font-extrabold text-gray-900">Edit Support</h1>
                <button
                    onClick={() => navigate("/supports")}
                    className="text-gray-500 hover:text-gray-700 transition duration-200"
                >
                    ← Back
                </button>
            </div>

            <div className=''>
                <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2 text-gray-700">Category</label>
                    <Select
                        className="w-full"
                        placeholder="Select Category"
                        options={SUPPORT_CATEGORIES}
                        onChange={(value) => setCategory(value)}
                        value={category}
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2 text-gray-700">Status</label>
                    <Select
                        className="w-full"
                        placeholder="Select Status"
                        options={SUPPORT_STATUSES}
                        onChange={(value) => setStatus(value)}
                        value={status}
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2 text-gray-700">Message</label>
                    <textarea
                        className="w-full p-4 rounded-lg border border-gray-300 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter your message"
                        rows="4"
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2 text-gray-700">Notes</label>
                    <textarea
                        className="w-full p-4 rounded-lg border border-gray-300 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Enter any related notes"
                        rows="4"
                    />
                </div>

                <button
                    className="w-full py-3 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition duration-200"
                    onClick={() => updateSupportAction({
                        status: status?.value,
                        category: category?.value,
                        message,
                        notes
                    }, navigate)}
                >
                    Update
                </button>
            </div>
        </div>
    );
};

export default EditSupport;
