import * as TrailerPermissions from '../modules/TrailerPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../../context'

const ViewTrailer = ({
    role
}) =>{

    const {id} = useParams();

    const [trailer, setTrailer] = useState({
        fleet: {
            name: ""
        },
        registrationNumber: "",
        capacity: {
            leng: 0,
            width: 0,
            height: 0,
            weight: 0
        },
        trailerType: '',
        color: '',
        cargoTypes: []
    })

    const fetchTrailer = () => {
        axios.get(`${context.SERVER_URL}/api/trailers/${id}`)
        .then((response)=>{
           setTrailer(response.data)
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        fetchTrailer()
    },[])

    return(
        <div className='p-5'>
            <div className='flex flex-row'>
                <Link 
                    to = {"/trailers"}
                    className = "underline pr-2"
                >trailers</Link> | 
                {
                    TrailerPermissions.isAuthorized("edit",role) &&
                    <Link 
                        to = {`/trailers/edit/${id}`}
                        className = "underline pl-2"
                    >edit</Link>
                }
            </div>
            <h1 className='text-primary-2 text-lg font-bold'>View Trailer</h1>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Fleet:</label>
                <div className='text-gray-500 font-bold'>{trailer.fleet.name}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Trailer Type:</label>
                <div className='text-gray-500 font-bold'>{trailer.trailerType}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Color:</label>
                <div className='text-gray-500 font-bold'>{trailer.color}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Registration Number:</label>
                <div className='text-gray-500 font-bold'>{trailer.registrationNumber}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Weight:</label>
                <div className='text-gray-500 font-bold'>{trailer.capacity.weight}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Width:</label>
                <div className='text-gray-500 font-bold'>{trailer.capacity.width}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Length:</label>
                <div className='text-gray-500 font-bold'>{trailer.capacity.leng}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Height:</label>
                <div className='text-gray-500 font-bold'>{trailer.capacity.height}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Cargo Types:</label>
                <div className='text-gray-500 font-bold'>
                    {
                        trailer.cargoTypes.join(", ")              
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewTrailer;