import {connect} from 'react-redux'
import Profile from '../components/Profile';
import { 
    getProfileDataAction
} from '../modules/profile'

const mapStateToProps = (state) =>({
    firstName: state.profile.firstName,
    lastName: state.profile.lastName,
    role: state.profile.role,
    passport: state.profile.passport,
    company: state.profile.company,
    dob: state.profile.dob,
    nationalId: state.profile.nationalId,
    address: state.profile.address,
    contactNumber: state.profile.contactNumber,
    status: state.profile.status,
    email: state.profile.email,
    fleet: state.profile.fleet,
    isLoading: state.profile.isLoading,
    id: state.profile.id
});

const mapActionCreators = {
  getProfileDataAction
};

export default connect (mapStateToProps,mapActionCreators)(Profile); 