import {connect} from 'react-redux'
import NewUser from '../components/NewUser';
import { showLoaderAction } from '../../common/modules/modal';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    showLoaderAction
});

const mapActionCreators = {
    showLoaderAction
};

export default connect (mapStateToProps,mapActionCreators)(NewUser); 