import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";

const EditWeightBilling = ({
  onBillingChanged,
  driverRateRef,
  bookingRateRef,
  distanceToRef,
  distanceFromRef,
  driverRate,
  bookingRate,
  distanceFrom,
  distanceTo,
  index,
  deleteEntry,
  id,
  onUpdateWeightBilling,
}) => {
  const [profitRate, setProfitRate] = useState(0);
  useEffect(() => {
    let profitRate = 0;
    if (driverRate && bookingRate) {
      profitRate = Number(bookingRate) - Number(driverRate);
    }
    setProfitRate(profitRate);
  }, [driverRate, bookingRate]);

  return (
    <>
      <tr className="divide-x divide-gray-200">
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
            <input
              className="w-full p-2 mt-2 rounded-lg border"
              type="number"
              ref={distanceFromRef}
              value={distanceFrom}
              onChange={(e) =>
                onBillingChanged(e.target.value, "distanceFrom", index)
              }
              placeholder={"Enter lower distance limit"}
            />
          </div>
        </th>
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
            <input
              className="w-full p-2 mt-2 rounded-lg border"
              type="number"
              ref={distanceToRef}
              value={distanceTo}
              onChange={(e) =>
                onBillingChanged(e.target.value, "distanceTo", index)
              }
              placeholder={"Enter upper distance limit"}
            />
          </div>
        </th>
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
            <input
              className="w-full p-2 mt-2 rounded-lg border"
              type="number"
              ref={bookingRateRef}
              value={bookingRate}
              onChange={(e) =>
                onBillingChanged(e.target.value, "bookingRate", index)
              }
              placeholder={"Enter booking per km rate"}
            />
          </div>
        </th>
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
            <input
              className="w-full p-2 mt-2 rounded-lg border"
              type="number"
              ref={driverRateRef}
              value={driverRate}
              onChange={(e) =>
                onBillingChanged(e.target.value, "driverRate", index)
              }
              placeholder={"Enter driver per km rate"}
            />
          </div>
        </th>
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="w-full px-4">
            <div className="w-full p-2 mt-2 rounded-lg border bg-white">
              {profitRate}
            </div>
          </div>
        </th>
        <th
          scope="col"
          className="py-3.5  text-left text-sm font-semibold text-primary-2 sm:pr-0"
        >
          <div className="flex flex-row w-full justify-center gap-4 px-4">
            <button
              className="px-2 py-2 text-gray-500 border-2  border-green-500 hover:bg-green-200 hover:text-green-800 bg-white  rounded-lg"
              onClick={() => onUpdateWeightBilling(index)}
            >
              {" "}
              Update{" "}
            </button>
            <button
              className="px-2 py-2 text-red-500 border-2 border-red-500 bg-white hover:bg-red-200 hover:text-red-800  rounded-lg"
              onClick={() => deleteEntry({ type: "weightBilling", id })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
        </th>
      </tr>
    </>
  );
};

export default EditWeightBilling;
