import { Link, Navigate, useParams } from "react-router-dom";
import React,{useState} from 'react'

import axios from 'axios'
import context from "../../context";

const ResetPassword = ({
    setToken
}) => {

    const {token} = useParams();
    const [password, setPassword] = useState(''); //1234
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState({
        show: false,
        message: ""
    })
    const onPasswordChanged = (password) =>{
        setPassword(password.trim());
    }

    const onConfirmPasswordChanged = (password) =>{
        setConfirmPassword(password.trim());
    }

    const handleSubmit = async () => {

        if (password == '' || token == null || confirmPassword == '') 
        {
            setShowErrorMessage({
                show: true,
                message: "Please complete all fields."
            })

            setTimeout(()=>{
                setShowErrorMessage({
                    show: false,
                    message: ""
                })
            }, 2000)
        }
        else if (password !== confirmPassword)
        {
            setShowErrorMessage({
                show: true,
                message: "Passwords do not match!"
            })

            setTimeout(()=>{
                setShowErrorMessage({
                    show: false,
                    message: ""
                })
            }, 2000)
        }
        else
        {
            axios.put(`${context.SERVER_URL}/api/passwordresetbyinvite`,
            {
                token,
                password       
            })
            .then(res=>{
                console.log(res);
                const token = res.data.userToken;
                setToken({token});
            })
            .catch(err=>{
                console.log(err)
                //alert("wrong user name and password");
                setShowErrorMessage({
                    show: true,
                    message: "User already Exists!"
                })
    
                setTimeout(()=>{
                    setShowErrorMessage({
                        show: false,
                        message: ""
                    })
                }, 2000)
            })
        }
        
    }

    return(
        <div className=" flex flex-col w-full h-full bg-green-50 items-center overflow-y-auto">
            <span className = "flex flex-row text-3xl font-bold underline py-2 text-primary-1"> Wheels 
                <span className="text-orange-custom text-4xl">it</span> 
                Now 
            </span>

            <div className="flex flex-col w-full md:w-2/3 px-10">
                <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                    <label className = "font-bold text-primary-1">Password:</label>
                    <input 
                        className = "border-2 border-neutral-7 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="password"
                        onChange = { e => onPasswordChanged(e.target.value) } 
                        placeholder = {"Enter password"}
                        value = {password}
                    />
                </div>

                <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                    <label className = "font-bold text-primary-1">Confirm Password:</label>
                    <input 
                        className = "border-2 border-neutral-7 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="password"
                        onChange = { e => onConfirmPasswordChanged(e.target.value) } 
                        placeholder = {"Enter password confirmation"}
                        value = {confirmPassword}
                    />
                </div>

                <button 
                    className="bg-primary-1 rounded-lg p-5 text-white font-semibold w-full mt-10 md:w-1/2 md:mt-2"
                    onClick= {()=>handleSubmit()}
                >
                    Submit
                </button>
            </div>

            {
                (showErrorMessage.show) &&
                <div className="mt-3 underline font-bold text-red-500">{showErrorMessage.message}</div>
            }
        </div>

    )
}

export default ResetPassword;