const NavigationPermissions = {
    dashboard: ["admin", "companyAdmin", "companyManager", "logisticsSpecialist", "user","fleetAdmin", "fleetManager", "driver"],
    companies:["admin", "companyAdmin", "companyManager", "logisticsSpecialist"],
    fleets:["admin","fleetAdmin","fleetManager", "logisticsSpecialist"],
    cars: ["admin", "fleetAdmin", "fleetManager", "logisticsSpecialist"],
    vehicles: ["admin", "fleetAdmin", "fleetManager", "logisticsSpecialist"],
    trailers:["admin", "fleetAdmin", "fleetManager", "logisticsSpecialist"],
    trips:["admin", "fleetAdmin", "fleetManager", "logisticsSpecialist", "driver"],
    users:["admin", "fleetAdmin", "fleetManager", "logisticsSpecialist", "companyAdmin", "companyManager"],
    bookings:["admin", "companyAdmin", "companyManager", "logisticsSpecialist", "user"],
    grouping:["admin", "logisticsSpecialist"],
    billings:["admin"],
    productCategories:["admin"],
    payments:["admin", "fleetAdmin", "fleetManager", "logisticsSpecialist", "companyAdmin", "companyManager", "user"],
}

export const isAuthorized = ( attribute, role ) => NavigationPermissions[attribute]?.includes(role); 