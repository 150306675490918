import {connect} from 'react-redux'
import Supports from '../components/Supports';
import { showLoaderAction, showErrorAction } from '../../common/modules/modal';
import { getSupportsDataAction } from '../modules/support';

const mapStateToProps = (state) =>({
    role:  state.profile.role,
    supports: state.supports.supports
});

const mapActionCreators = {
    showLoaderAction,
    showErrorAction,
    getSupportsDataAction
};

export default connect (mapStateToProps,mapActionCreators)(Supports); 