import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../context'

const ViewGuestBooking = () => {

    const {id} = useParams();
    const [booking,setBooking] = useState(null);

    const [loading, setLoading] = useState(true);

    const fetchBooking = () =>{
        axios.get(`${context.SERVER_URL}/api/guestbookings/${id}`)
        .then((res)=>{
            setBooking(res.data);
            setLoading(false);
        }).catch((err)=>{
            console.log(err)
        });    

    }


    useEffect(()=>{
        fetchBooking()
    },[]);

    if (loading == true)
    {
        return(
            <div
                className='flex h-full w-full items-center justify-center'
            >Loading...</div>
        )
    }

    if (booking == null)
    {
        return(
            <div
                className='flex h-full w-full items-center justify-center'
            >Could not find your booking.</div>
        )
    }

    return(
        <div className='p-3'>
            {/*<div className='flex flex-row'>
                <Link 
                    to = {"/bookings"}
                    className = "underline pr-2"
                >bookings</Link> | 
                <Link 
                    to = {`/bookings/edit/${id}`}
                    className = "underline pl-2"
                >edit</Link>
            </div>*/}
            <h1 className='text-primary-2 text-lg font-bold'>View Booking</h1>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Booking number:</label>
                <div className='text-gray-500 font-bold'>{booking._id}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>User:</label>
                <div className='text-gray-500 font-bold'>{`${booking.user.firstName} ${booking.user.lastName}`}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Status:</label>
                <div className='text-gray-500 font-bold'>{booking.status}</div>
            </div>

            <label className='text-primary-2 font-bold'>Path:</label>
            <div className="flex flex-col w-full p-3">
                <div className="flex flex-row w-full">
                    <div className="w-2/5 px-2 text-primary-1 font-bold">Address:</div>
                    <div className="px w-1/5 text-primary-1 font-bold">Point type:</div>
                    <div className="px w-1/5 text-primary-1 font-bold">Status:</div>
                    <div className="px w-2/5 text-primary-1 font-bold">Capacity:</div>
                </div>
                {
                    booking.path.map((point, index)=>(
                        <div className="flex flex-row w-full" key={index}>
                            <div className="w-2/5 px-2">{point.address} </div>
                            <div className="w-1/5">{(point.pointType == "location") ? "pickup" : "dropoff" }</div>
                            <div className="w-1/5">{point.status }</div>
                            <div className="flex flex-row w-2/5 gap-2">
                                <div>{`weight: ${point.capacity?.weight}  kg(s)`}</div>
                                <div>{`length: ${point.capacity?.leng}  m`}</div>
                                <div>{`width: ${point.capacity?.width}  m`}</div>
                                <div>{`height: ${point.capacity?.height}  m`}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )

}

export default ViewGuestBooking;