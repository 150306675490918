import {connect} from 'react-redux'
import Tracking from '../components/Tracking';
import { getTrackingTrailersDataAction } from '../modules/tracking';
const mapStateToProps = (state) =>({
    role: state.profile.role,
    trailers: state.tracking.trailers
});

const mapActionCreators = {
    getTrackingTrailersDataAction
};

export default connect (mapStateToProps,mapActionCreators)(Tracking); 