import React, { useState } from "react";
import { images } from "../../../../assets/index";
import axios from 'axios';
import context from '../../../../context/index';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

const countryCodes = [
  { id: 6, name: '+263 (Zimbabwe)' },
  { id: 1, name: '+1 (USA)' },
  { id: 2, name: '+44 (UK)' },
  { id: 3, name: '+86 (China)' },
  { id: 4, name: '+91 (India)' },
  { id: 5, name: '+27 (South Africa)' },
];

const Footer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    countryCode: "",
    category: "",
    message: "",
  });

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(countryCodes[0]);
  const [isLoading, setIsLoading] = useState(false);

  const filteredCountryCodes =
    query === ''
      ? countryCodes
      : countryCodes.filter((code) => {
        return code.name.toLowerCase().includes(query.toLowerCase())
      });

  const handleChange = (e) => {
    const { id, value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name || id]: value,
    }));
  };

  const handleCountryCodeChange = (value) => {
    setSelected(value);
    setFormData((prevData) => ({
      ...prevData,
      countryCode: value?.name,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios.post(`${context.SERVER_URL}/api/enquiries`, {
      data: formData
    })
      .then((response) => {
        console.log(response);
        alert("Thank you for your inquiry! We will get back to you soon.");
        // Reset form fields
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          countryCode: "",
          category: "",
          message: "",
        });
        setSelected(countryCodes[0]);
      })
      .catch((error) => {
        console.log(error);
        alert("An error occurred while submitting your inquiry. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <footer className="py-8 bg-white text-gray-400">
        <section className="bg-gray-100 mb-8">
          <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6">
            <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
              <div className="lg:col-span-2 lg:py-12">
                <p className="max-w-xl text-lg">
                  We're thrilled you're interested in trying our app! Thank you for showing your support.

                  To get started, please fill out this form so our team can reach out to you with more information. We can't wait to share more about how our app can benefit you!
                </p>
                <p className="max-w-xl text-lg">
                  Streamline your cargo transportation with our specialized
                  transport management system. Whether you're an individual or a
                  business, our system optimizes routes, making goods movement
                  easier than ever before.
                </p>
                <div className="mt-8">
                  <p className="text-xl font-bold text-gray-600">+263 77 234 5678</p>
                  <address className="mt-2 not-italic">
                    Masvingo, Zimbabwe
                  </address>
                </div>

                <div className="mt-8">
                  <p className="text-xl font-bold text-gray-600">+27 76 211 8959</p>
                  <address className="mt-2 not-italic">
                    Rosebank JHB, South Africa
                  </address>
                </div>
              </div>

              <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                <form onSubmit={handleSubmit} className="space-y-4" id="contact-form">
                  <div>
                    <label className="sr-only" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      className="w-full rounded-lg border p-3 text-sm"
                      placeholder="First Name"
                      type="text"
                      id="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div>
                    <label className="sr-only" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      className="w-full rounded-lg border p-3 text-sm"
                      placeholder="Last Name"
                      type="text"
                      id="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div>
                    <label className="sr-only" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="w-full rounded-lg border p-3 text-sm"
                      placeholder="Email address"
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="sr-only" htmlFor="countryCode">
                        Country Code
                      </label>
                      <Combobox value={selected} onChange={handleCountryCodeChange} onClose={() => setQuery('')}>
                        <div className="relative">
                          <ComboboxInput
                            className={clsx(
                              'w-full rounded-lg border p-3 text-sm',
                              'focus:outline-none focus:ring-2 focus:ring-black'
                            )}
                            displayValue={(code) => code?.name}
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder="Select Country Code"
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute mt-1 max-h-60 w-full max-w-xs overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredCountryCodes.map((code) => (
                            <ComboboxOption
                              key={code.id}
                              value={code}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-black text-white' : 'text-gray-900'
                                }`
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                    {code.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-black'
                                        }`}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </Combobox>
                    </div>

                    <div>
                      <label className="sr-only" htmlFor="contactNumber">
                        Contact Number
                      </label>
                      <input
                        className="w-full rounded-lg border p-3 text-sm"
                        placeholder="Contact Number"
                        type="tel"
                        id="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-400">
                      Category
                    </label>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                      {["general", "driver", "partner"].map((category) => (
                        <div key={category}>
                          <input
                            className="peer sr-only"
                            id={`category-${category}`}
                            type="radio"
                            name="category"
                            value={category}
                            checked={formData.category === category}
                            onChange={handleChange}
                            required
                          />
                          <label
                            htmlFor={`category-${category}`}
                            className={`block w-full rounded-lg border p-3 text-sm text-center capitalize cursor-pointer transition-colors
                          ${formData.category === category
                                ? "border-black bg-black text-white"
                                : "border-gray-200 text-gray-600 hover:border-gray-300"
                              }
                          peer-checked:border-black peer-checked:bg-black peer-checked:text-white`}
                          >
                            {category}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="sr-only" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      className="w-full rounded-lg border p-3 text-sm"
                      placeholder="Notes"
                      rows="8"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className={`inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <svg className="inline w-4 h-4 mr-2 animate-spin" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                          </svg>
                          Sending...
                        </>
                      ) : (
                        'Send Enquiry'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <div className="container flex flex-wrap items-center justify-center mx-auto space-y-4 sm:justify-between sm:space-y-0">
          <div className="flex flex-row pr-3 space-x-4 sm:space-x-8">
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-full ">
              <img className="" src={images.Logo} alt="wheelsitno" />
            </div>
            <ul className="flex flex-wrap items-center space-x-4 sm:space-x-8">
              <li>
                <a href="/">Terms of Use</a>
              </li>
              <li>
                <a href="/">Privacy</a>
              </li>
            </ul>
          </div>
          <ul className="flex flex-wrap pl-3 space-x-4 sm:space-x-8">
            <li>
              {/* <a href="/">Instagram</a> */}
            </li>
            <li>
              {/* <a href="/">Facebook</a> */}
            </li>
            <li>
              {/* <a href="/">Twitter</a> */}
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
