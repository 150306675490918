import "react-confirm-alert/src/react-confirm-alert.css"; //

import * as UserPermissions from "../modules/UserPermissions";

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import context from "../../../context/index";
import { COLOR_CODES } from "../../../util/general.util";
import ReactPaginate from "react-paginate";

const Users = ({ 
  role,
  showLoaderAction 
}) => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    statusFilter: { value: "", label: "Select Status" },
    searchFilter: "",
    fleetFilter: { value: "", label: "Select Fleet" },
    companyFilter: { value: "", label: "Select Company" },
    roleFilter: { value: "", label: "Select Role" },
    pageFilter: 1,
    limitFilter: 10
  });
  const [totalPages, setTotalPages] = useState(100);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [filterOptions, setFilterOptions] = useState({
    statuses: [],
    fleets: [],
    companies: [],
    roles: [],
  });

  const fetchFleets = () => {
    axios
      .get(`${context.SERVER_URL}/api/fleets`)
      .then((response) => {
        const fleets = response.data.fleets.map((fleet) => ({
          label: fleet.name,
          value: fleet._id,
        }));
        setFilterOptions((prevState) => ({
          ...prevState,
          fleets,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCompanies = () => {
    axios
      .get(`${context.SERVER_URL}/api/companies`)
      .then((response) => {
        const companies = response.data.companies.map((user) => ({
          label: user.name,
          value: user._id,
        }));
        setFilterOptions((prevState) => ({
          ...prevState,
          companies,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchUsers = (filters) => {
    showLoaderAction(true)
    const {
      searchFilter,
      companyFilter,
      fleetFilter,
      roleFilter,
      statusFilter,
      pageFilter,
      limitFilter
    } = filters;

    axios
      .get(
        `${context.SERVER_URL}/api/users?searchFilter=${searchFilter}&companyFilter=${companyFilter?.value}&fleetFilter=${fleetFilter?.value}&roleFilter=${roleFilter?.value}&statusFilter=${statusFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`
      )
      .then((res) => {
        const { users, totalPages, currentPage } = res.data;
        setUsers(users);
        setTotalPages(totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>{
        showLoaderAction(false);
      })
  };

  const fetchRoles = () => {
    axios
      .get(`${context.SERVER_URL}/api/userroles`)
      .then((res) => {
        const { company, fleet } = res.data;
        const companyRoles = company.map((role) => {
          return { label: role, value: role };
        });
        const fleetRoles = fleet.map((role) => {
          return { label: role, value: role };
        });

        setFilterOptions((prevState) => ({
          ...prevState,
          roles: [...companyRoles, ...fleetRoles],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchStatuses = () => {
    axios
      .get(`${context.SERVER_URL}/api/userstatuses`)
      .then((res) => {
        const statuses = res.data.map((status) => {
          return { label: status, value: status };
        });

        setFilterOptions((prevState) => ({
          ...prevState,
          statuses,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUsers(filters);
    fetchFleets();
    fetchCompanies();
    fetchRoles();
    fetchStatuses();
  }, []);

  const deleteUser = (id) => {
    const options = {
      title: "Delete User",
      message: "Are you sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios
              .delete(`${context.SERVER_URL}/api/users/${id}`)
              .then((response) => {
                const _users = users.filter((user) => user._id != id);
                console.log(_users);
                setUsers(_users);
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    };

    confirmAlert(options);
  };



  return (
    <div className="w-full bg-white p-5 overflow-auto">
      <div className="flex w-full justify-between items-center mb-2 px-8">
        <h1 className="text-primary-1 font-bold">Users</h1>
        {UserPermissions.isAuthorized("new", role) && (
          <Link to="/users/new">
            <div className="bg-primary-1 rounded-md text-white px-3 py-2">
              New User
            </div>
          </Link>
        )}
      </div>
      <div className="border border-gray-400 mt-1 mb-10" />

      <div className="flex flex-col w-full md:flex-row gap-4 mb-5">
        <div className="grid grid-cols-6 w-full gap-4">
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Search filter
            </label>
            <input
              className="w-full py-2 border px-4 rounded-lg"
              placeholder="Search"
              onChange={({ target: { value } }) =>
                setFilters((prevState) => ({
                  ...prevState,
                  searchFilter: value,
                }))
              }
              value={filters.searchFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Company filter
            </label>
            <Select
              className="w-full"
              placeholder="Select Date"
              options={filterOptions.companies}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  companyFilter: value,
                }))
              }
              value={filters.companyFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Fleet filter
            </label>
            <Select
              className="w-full"
              placeholder="Select Fleet"
              options={filterOptions.fleets}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  fleetFilter: value,
                }))
              }
              value={filters.fleetFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Role filter
            </label>
            <Select
              className="w-full"
              placeholder="Select status"
              options={filterOptions.roles}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  roleFilter: value,
                }))
              }
              value={filters.roleFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Status filter
            </label>
            <Select
              className="w-full"
              placeholder="Select status"
              options={filterOptions.statuses}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  statusFilter: value,
                }))
              }
              value={filters.statusFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Limit filter
            </label>
            <input
              className="w-fit border border-gray-200 rounded-md p-2"
              placeholder=""
              type = "number"
              onChange={({target: { value }}) =>{
                setFilters((prevState) => ({
                  ...prevState,
                  limitFilter: value,
                }))
              }}
              value={filters.limitFilter}
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 items-end">
          <button
            className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
            onClick={() => fetchUsers(filters)}
          >
            {" "}
            Search
          </button>
          <button
            className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
            onClick={() => {
              const resetState = {
                searchFilter: "",
                statusFilter: { value: "", label: "Select Status" },
                fleetFilter: { value: "", label: "Select Fleet" },
                companyFilter: { value: "", label: "Select Company" },
                roleFilter: { value: "", label: "Select Role" },
                pageFilter: 1,
                limitFilter: 10
              };
              setFilters((prevState) => ({
                ...prevState,
                ...resetState,
              }));
              fetchUsers(resetState);
            }}
          >
            {" "}
            Reset
          </button>
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
            >
              Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
            >
              Surname
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
            >
              Contact
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
            >
              Role
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
            >
              Company
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
            >
              Fleet
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
            >
              Status
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Select</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            return (
              <tr key={user._id}>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-transparent",
                    "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                  )}
                >
                  <div className="font-medium text-primary-2">
                    {user.firstName}
                  </div>
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  <span>{user.lastName}</span>
                </td>

                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {user.contactNumber}
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "px-3 py-3.5 text-sm text-gray-500"
                  )}
                >
                  <div className="">{user.email}</div>
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "px-3 py-3.5 text-sm text-gray-500"
                  )}
                >
                  <div className="">{user.role}</div>
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "px-3 py-3.5 text-sm text-gray-500"
                  )}
                >
                  <div className="">{user?.company?.name}</div>
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "px-3 py-3.5 text-sm text-gray-500"
                  )}
                >
                  <div className="">{user?.fleet?.name}</div>
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "px-3 py-3.5 text-sm text-gray-500"
                  )}
                >
                  <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.user?.status[user.status.toLowerCase()]}`}>
                    {user?.status}
                  </span>
                </td>
                <td
                  className={classNames(
                    index === 0 ? "" : "border-t border-transparent",
                    "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex"
                  )}
                >
                  {UserPermissions.isAuthorized("view", role) && (
                    <Link to={`/users/view/${user._id}`}>
                      <div className="border p-2 m-2 rounded-md">View</div>
                    </Link>
                  )}
                  {UserPermissions.isAuthorized("edit", role) && (
                    <Link to={`/users/edit/${user._id}`}>
                      <div className="border border-green-500 text-primary-1 p-2 m-2 rounded-md">
                        {" "}
                        Edit{" "}
                      </div>
                    </Link>
                  )}
                  {UserPermissions.isAuthorized("delete", role) && (
                    <button
                      className="border border-red-400 text-red-400 p-2 m-2 rounded-md"
                      onClick={() => deleteUser(user._id)}
                    >
                      {" "}
                      Delete{" "}
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-row w-full items-center justify-end mt-5">
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({selected}) => {
            let _filters = {
              ...filters,
              pageFilter: selected + 1
            } 
            fetchUsers(_filters);
            setFilters(_filters);
          }}
          containerClassName={'pagination'}
          pageClassName={'page'}
          pageLinkClassName={'page-link'}
          previousClassName={'previous'}
          nextClassName={'next'}
          activeClassName={'active'}
          disabledClassName={'disabled'}      
        />
      </div>
    </div>
  );
};

export default Users;
