import {connect} from 'react-redux'
import Chats from '../components/Chats';
import { 
    getChatsDataAction,
    markAsReadAction,
    createChatAction 
} from '../modules/chats';

const mapStateToProps = (state) =>({
    role:  state.profile.role,
    currentUserId: state.profile.id,
    chats: state.chats.chats
});

const mapActionCreators = {
    getChatsDataAction,
    markAsReadAction,
    createChatAction 
};

export default connect (mapStateToProps,mapActionCreators)(Chats); 