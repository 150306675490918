import {
    Autocomplete,
    DirectionsRenderer,
    GoogleMap,
    Marker,
    useJsApiLoader,
} from "@react-google-maps/api";
import { Link, useNavigate } from "react-router-dom";
import React,{createRef, useEffect, useRef, useState} from "react";
import { TIMESLOTS, getDates } from "../../util/date.util";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import OtpInput from "react-otp-input";
import ReduxStorage from '../../store/store'
import Select from "react-select";
import axios from "axios";
import context from "../../context/index"
import { showErrorAction } from "../common/modules/modal";

const libraries = ["places"];
const POINT_TYPE = [
    { label: "Pick", value: "location" },
    { label: "drop", value: "destination" }
]

const NewGuestBooking = () => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    })

    const [companies, setCompanies] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');

    const [selectedTimeslot, setSelectedTimeslot] = useState('');

    const [directions, setDirections]= useState(null);

    const mapRef = useRef();

    const [cargoTypes, setCargoTypes] = useState([]);
    const [selectedCargoType, setSelectedCargoType] = useState([]);

    const [shared, setShared] = useState(false);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [otpCode, setOtpCode] = useState('');
    const [showOtpDialog, setShowOtpDialog] = useState(false);
    const [bookDisabled, setBookDisabled] = useState(true);
    const [otpIsLoading, setOtpIsLoading] = useState(true);

    const [ showBooked, setShowBooked ] = useState({
        show: false,
        bookingLink: ''
    });

    const navigate = useNavigate();


    useEffect(()=>{

        fetchCargoTypes();
        fetchCompanies();
    },[])

    useEffect(()=>{
        if(otpCode.length == 4){
            setBookDisabled(false);
        }
        else
        {
            setBookDisabled(true);
        }
    },[otpCode]);

    const sendOtp = () =>{
        // axios send OTP
        setOtpIsLoading(true);
        setShowOtpDialog(true);
        setOtpCode('')

        axios.post(`${context.SERVER_URL}/api/getOtpSMS`,
        {
            data:
            {
                contactNumber,
            }

        }).then((response)=>{

            console.log(response)
            setOtpIsLoading(false);

        }).catch((error)=>{
            console.log(error);
            setOtpIsLoading(false);
            ReduxStorage.store.dispatch(showErrorAction({
                showError: true,
                errorMessage: error.message
            }))
        })

    }

    const onCancel = () => {
        setOtpCode('')
        setShowOtpDialog(false);
        setOtpIsLoading(true);

        if(showBooked.show == true)
        {
            navigate('/');
        }
    }


    const renderOtpDialog = () =>{

        return(
            <div className="flex w-full h-full absolute top-0 left-0 items-center justify-center bg-opacity-50 bg-gray-100">

                <div className="flex flex-col items-center justify-center sm:w-full md:w-1/4 lg:w-1/2 h-fit p-2 bg-gray-50 rounded-lg">
                    <div className="flex flex-row w-full justify-end justify-self-start">
                        <button
                            className='pt-1 pr-1'
                            onClick={()=> onCancel()}
                        >
                            <CancelIcon fontSize="medium"/>
                        </button>
                    </div>

                    <h1 className="text-primary-1 font-bold">OTP Verification</h1>
                    { otpIsLoading && <div className="font-bold text-gray-500">Loading .....</div>}
                    {
                        (!otpIsLoading && !showBooked.show) &&
                        <div className="flex flex-col w-full items-center justify-center px-5 pb-5">
                            <div className="w-full flex flex-row items-center justify-between mb-5">
                                <div className="text-gray-500"> {`Code has been sent to: ${contactNumber}, please enter code to proceed. Code expires in 10 minutes.`} </div>
                                <button
                                    onClick={()=>sendOtp()}
                                    className="bg-primary-1 text-white font-bold rounded-lg px-4 py-2"
                                >Resend</button>
                            </div>

                            <OtpInput
                                containerStyle={{
                                    width: 100 + "%",
                                    height: 100,
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}

                                inputStyle={{
                                    height: 60,
                                    width: 60,
                                    borderColor: "#3F826D",
                                    borderWidth: 2,
                                }}
                                value={otpCode}
                                onChange={(value)=>setOtpCode(value)}
                                numInputs={4}
                                separator={<span className="p-5">-</span>}
                            />

                            <button
                                disabled={bookDisabled}
                                onClick = {()=>submitBooking()}
                                className={`py-3 px-10 font-bold w-fit ${ bookDisabled ? "bg-gray-300 text-gray-500": "bg-primary-1 text-white" } rounded-lg mt-10`}
                            >
                                Book
                            </button>
                        </div>
                    }
                    {
                        showBooked.show &&
                        <div className="flex flex-col w-full items-center justify-center">
                            <div className="text-gray-500 font-bold">
                                Trip Booked !! Use the link below to access your trip. Make sure to copy this link and secure it.
                            </div>
                            <a
                                href={showBooked.bookingLink}
                                className="p-5 text-gray-500 underline"
                                target="_blank"
                            >
                                { showBooked.bookingLink }
                            </a>

                            <button
                                className="bg-primary-1 text-white font-bold px-10 py-3 mt-3 mb-5 rounded-lg"
                                onClick={ () => navigate('/')}
                            >
                                Done
                            </button>
                        </div>
                    }


                </div>

            </div>
        )
    }
    const centerMap = async () =>{

        const pointsLatLng = await points.map(async(point)=>{

            const { geometry: { location }} = await point.ref.current?.getPlace();

            return {
                lat: location?.lat(),
                lng: location?.lng()
            }
        });

        if (pointsLatLng[0].latitude != null && pointsLatLng[pointsLatLng.length-1].latitude != null)
        {
            var bounds = new window.google.maps.LatLngBounds();

            pointsLatLng.forEach((point)=>{
                if(point.latitude != null && point.longitude != null)
                {
                    bounds.extend(
                        {
                            lat: point.latitude,
                            lng: point.longitude
                        }
                    );
                }
            });

            mapRef.current?.setCenter(bounds.getCenter());
            mapRef.current?.fitBounds(bounds);
        }

    }

    const calculateRoute = (points) => {
        let pointsLatLng = points.map((point)=>{
            return {
                lat: point.latitude,
                lng: point.longitude
            }
        });

        const origin = pointsLatLng.shift();
        const destination = pointsLatLng.pop();
        let waypoints = [];
        pointsLatLng.forEach((point) => {
            if(point.lat != null)
            {
                waypoints.push({
                    location: point,
                    stopover: true
                })
            }
        });

        if (origin.lat != null && destination.lat != null)
        {
            const directionsService = new window.google.maps.DirectionsService();
            //const origin = { lat: pointsLatLng[0].lat, lng: pointsLatLng[0].lng };
            //const destination = { lat: pointsLatLng[pointsLatLng.length-1].lat, lng: pointsLatLng[pointsLatLng.length-1].lng };

            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        console.log(result)
                        setDirections(result);
                    } else {
                    console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }

    }

    const fetchCompanies = () =>{

        axios.get(`${context.SERVER_URL}/api/companies`)
        .then((response)=>{
            const companies = response.data.companies.map((company) => {
                return { label: company.name, value: company._id}
            });

            setCompanies(companies);
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const fetchCargoTypes = () => {
        axios.get(`${context.SERVER_URL}/api/cargoTypes`)
        .then((response)=>{
            const _cargoTypes = response.data.map((cargoType)=>{
                return { label:  cargoType, value: cargoType }
            });
            setCargoTypes(_cargoTypes);

        }).catch((error)=>{
            console.log(error);
        })
    }

    const submitBooking = () => {

        setOtpIsLoading(true);
        //generate path
        let path = [];
        let count = 0;
        points.forEach((point)=>{
            if (point.latitude != null)
            {
                path.push({
                    pointType: point.type?.value,
                    order: count,
                    capacity: point.capacity,
                    address: point.address,
                    latitude: point.latitude,
                    longitude: point.longitude
                })

                count++;
            }
        });

        axios.post(`${context.SERVER_URL}/api/guestbookings`,
        {
            data:
            {
                firstName,
                lastName,
                contactNumber,
                email,
                timeslot: selectedTimeslot?.value,
                date: selectedDate?.value,
                path,
                shared,
                otpCode,
                cargoType: selectedCargoType?.value,
                distance: (directions == null) ? 0 : (directions.routes[0].legs.reduce((value, leg)=> value + leg.distance.value, 0)/1000).toFixed(2)
            }

        }).then((response)=>{

            console.log(response);
            setOtpIsLoading(false);
            const { bookingLink } = response.data
            setShowBooked((prevState)=>({
                ...prevState,
                show: true,
                bookingLink
            }));

        }).catch((error)=>{
            console.log(error);
            setOtpIsLoading(false);
            ReduxStorage.store.dispatch(showErrorAction({
                showError: true,
                errorMessage: error.message
            }));
        })
    }

    const [points, setPoints] = useState([

        {
            identifier: (Math.random() * 1000),
            type: { label: "Pick", value: "location"},
            showAddStop: true,
            showCancel: false,
            disableTypeSelect: true,
            disableCapacity: false,
            placeholder: "Enter Location",
            capacity: {
                leng: 0,
                weight: 0,
                width: 0,
                height: 0
            },
            autoComplete: null,
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null

        },
        {
            identifier: (Math.random() * 1000),
            type: { label: "Drop", value: "destination" },
            disableTypeSelect: true,
            disableCapacity: true,
            showAddStop: false,
            showCancel: false,
            placeholder: "Enter Destination",
            capacity:{
                leng: 0,
                weight: 0,
                width: 0,
                height: 0
            },
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null
        }
    ]);

    const onAddStop = () => {

        let _points = [...points];

        let destination = _points.pop();

        let _newPoint = {
            identifier: (Math.random() * 1000),
            type: { label: "Pick", value: "location"},
            showAddStop: false,
            showCancel: true,
            disableTypeSelect: false,
            disableCapacity: false,
            placeholder: "Enter Stop Address",
            capacity:{
                leng: 0,
                weight: 0,
                width: 0,
                height: 0
            },
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null
        };

        let _newPoints = [ ..._points, _newPoint, destination];

        setPoints(_newPoints);
    }

    const onPointChanged = (index,field,value) => {

        let _points = [...points];

        if (field == "capacity"){
            _points[index][field] = {
                ..._points[index][field],
                ...value
            };
        }
        else
        {
            _points[index][field] = value;
        }


        if (field == "capacity" || field == "type")
        {
            _points[_points.length-1]["capacity"] = getFinalDestinationCapacity([..._points], index);
        }
        setPoints(_points);

    }

    const getFinalDestinationCapacity = (points, index) => {
        // update the destination capacity
        let _points = [...points];
        _points.pop() // remove last point
        let finalDestinationCapacity = _points.reduce((accumulated, point) =>{
            let _width = (point.capacity.width == "") ? 0 : point.capacity.width;
            let _leng = (point.capacity.leng == "") ? 0 : point.capacity.leng;
            let _height = (point.capacity.height == "") ? 0 : point.capacity.height;
            let _weight = (point.capacity.weight == "") ? 0 : point.capacity.weight;

            if(point.type?.value == "location")
            {
                accumulated.leng += _leng;
                accumulated.weight += _weight;
                accumulated.width += _width;
                accumulated.height += _height;
                return (accumulated)
            }
            else
            {
                accumulated.leng -= _leng;
                accumulated.weight -= _weight;
                accumulated.width -= _width;
                accumulated.height -= _height;
                return (accumulated)
            }
        },
        {
            leng: 0,
            weight: 0,
            width: 0,
            height: 0
        });

        return  finalDestinationCapacity;

    }

    const removePoint = (identifier) =>{
        let _points = points.filter((point)=> point.identifier != identifier );
        _points[_points.length-1]["capacity"] = getFinalDestinationCapacity([..._points]);
        setPoints(_points);
        calculateRoute(_points);
    }


    const renderPoint = (point, index)=>{
        return(
            <div key= {point.identifier} className="flex flex-col w-full my-3">
                <div className="flex flex-row w-full gap-2 items-start justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 w-full gap-4">
                        <Autocomplete
                            className="col-span-1 lg:col-span-2"
                            //ref={point.ref}
                            onPlaceChanged={() => {

                                const { geometry:{ location }, formatted_address } = point.ref.current?.getPlace();
                                const _points = [...points];
                                _points[index] = {
                                    ..._points[index],
                                    address: formatted_address,
                                    latitude: location.lat(),
                                    longitude: location.lng()
                                }
                                setPoints(_points)
                                calculateRoute(_points)
                            }}
                            onLoad={(autocomplete)=>{
                                point.ref.current = autocomplete;
                            }}
                            options={{
                                types: ["address"],
                                //componentRestrictions: { country: "za" },
                            }}
                            //defaultValue="Amsterdam"
                        >
                            <input className="rounded-lg w-full py-3 px-2 truncate" placeholder={point.placeholder} type="text"></input>
                        </Autocomplete>

                        <div className="col-span-1 md:col-span-2 lg:col-span-4 grid md:grid-cols-2 lg:grid-cols-2 gap-4 order-last lg:order-none">
                            <div className="col-span-1 flex flex-row items-center">
                                <input
                                    disabled={point.disableCapacity}
                                    className="py-3 px-2 w-full text-gray-500 rounded-l-lg"
                                    type="number"
                                    value={point.capacity?.leng}
                                    onChange={({target: {value}}) => onPointChanged(index, "capacity", { leng: value == "" ? "" : parseFloat(value)})}
                                />
                                <div className="flex items-center h-full p-1 text-white text-xs bg-primary-1 rounded-r-lg">L(m)</div>
                            </div>
                            <div className="col-span-1 flex flex-row items-center">
                                <input
                                    disabled={point.disableCapacity}
                                    type="number"
                                    className="py-3 px-2 w-full text-gray-500 rounded-l-lg"
                                    value={point.capacity?.width}
                                    onChange={({target: {value}}) => onPointChanged(index, "capacity", { width: value == "" ? "" : parseFloat(value) })}
                                />
                                <div className="flex items-center h-full p-1 text-white text-xs bg-primary-1 rounded-r-lg">W(m)</div>
                            </div>
                            <div className="col-span-1 flex flex-row items-center">
                                <input
                                    disabled={point.disableCapacity}
                                    type="number"
                                    className="py-3 px-2 w-full text-gray-500 rounded-l-lg"
                                    value={point.capacity?.height}
                                    onChange={({target: {value}}) => onPointChanged(index, "capacity", { height: value == "" ? "" : parseFloat(value) })}
                                />
                                <div className="flex items-center h-full p-1 text-white text-xs bg-primary-1 rounded-r-lg">H(m)</div>
                            </div>
                            <div className="col-span-1 flex flex-row items-center">
                                <input
                                    disabled={point.disableCapacity}
                                    type="number"
                                    className="py-3 px-2 w-full text-gray-500 rounded-l-lg"
                                    value={point.capacity?.weight}
                                    onChange={({target: {value}}) => onPointChanged(index, "capacity", { weight: value == "" ? "" : parseFloat(value)})}
                                />
                                <div className="flex items-center h-full p-1 text-white text-xs bg-primary-1 rounded-r-lg">W(kg)</div>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <Select
                                className="w-full"
                                placeholder="Stop type"
                                options={POINT_TYPE}
                                onChange={(value)=> onPointChanged(index, "type" ,value)}
                                value={point.type}
                                isDisabled={point.disableTypeSelect}
                            />
                        </div>
                    </div>

                    <div className="">
                        {
                            point.showCancel &&
                            <button
                                className="p-1 bg-primary-1 text-white rounded-full"
                                onClick={()=>removePoint(point.identifier)}
                            ><CancelIcon fontSize="large"/></button>
                        }
                        {
                            point.showAddStop &&
                            <button
                            // disabled = {(point.ref.current?.getPlace() != null) ? false : true }
                                className="flex item-center justify-center p-1 bg-primary-1 text-white rounded-full"
                                onClick={()=>onAddStop()}
                            ><AddCircleIcon fontSize="large"/></button>
                        }
                    </div>
                </div>
                { !point.disableCapacity && <div className='border border-gray-400 mt-2'/> }
            </div>
        )
    }


    if ( !isLoaded)
    {
        return(
            <div className="w-full h-full p-5">
                <h1 className="text-primary-1 font-bold">New Guest Booking</h1>
                <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
            </div>
        )
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Guest Booking</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link
                    to = {"/"}
                    className = "underline pr-2"
                >back</Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 w-full mb-10">

                <div className="cols-span-1">
                    <label className="text-primary-1 font-bold">Date</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Date"
                        options={getDates()}
                        onChange={(value)=>setSelectedDate(value)}
                        value={selectedDate}
                    />
                </div>

                <div className="col-span-1">
                    <label className="text-primary-1 font-bold">Timeslot</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Timeslot"
                        options={TIMESLOTS}
                        onChange={(value)=>setSelectedTimeslot(value)}
                        value={selectedTimeslot}
                    />
                </div>

                <div className="col-span-1">
                    <label className="text-primary-1 font-bold">Share Space</label>
                    <div className=" flex flex-row w-full rounded-lg text-gray-500 py-1 px-2 border-2 border-gray-300 bg-white mt-2">
                        <div className="mr-5">Less than Truck Load(LTL)</div>
                        <input
                            type="checkbox"
                            checked={shared}
                            onChange = { ()=>setShared((prev) => !prev ) }
                        />
                    </div>
                </div>
                <div className="col-span-1 md:col-span-2">
                    <label className="text-primary-1 font-bold">Cargo type</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select cargo type"
                        options={cargoTypes}
                        onChange={(value)=>setSelectedCargoType(value)}
                        value={selectedCargoType}
                    />
                </div>

            </div>

            <div className="w-full flex flex-col">
                <label className="text-primary-1 font-bold">Stops</label>

                {
                    points.map((point, index)=>renderPoint(point, index))
                }
            </div>


            <div className="w-full h-1/2 my-10">
                <label className="text-primary-1 font-bold">Map</label>

                {
                    <GoogleMap
                        mapContainerStyle={{
                            width: 100 + "%",
                            height: 100 + "%",
                            borderRadius: 10,
                            borderColor: "GrayText",
                            borderWidth: 2,
                            marginTop: 4
                        }}
                        zoom={15}
                        onLoad={(map)=>{
                            mapRef.current = map;
                        }}
                    >
                        {
                            directions &&
                            <div className="absolute left-3 top-16 bg-white p-4 text-xl text-red-500 font-bold border-gray-300 border-2">
                                { `${(directions.routes[0].legs.reduce((value, leg)=> value + leg.distance.value, 0)/1000).toFixed(2)} km` }
                            </div>
                        }
                        {
                            directions &&
                            <DirectionsRenderer
                                options={{
                                    markerOptions:{
                                        icon:{
                                            path:'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                                            fillColor: 'rgb(0,0,255)',
                                            fillOpacity: 1,
                                            scale: 1.2,
                                            strokeColor: 'rgba(255, 255, 255, 0.6)',
                                            strokeWeight: 15,
                                        },
                                        anchorPoint:{
                                            x: 0.5,
                                            y: 0.5
                                        }
                                    }
                                }}
                                directions={directions}
                            />
                        }
                    </GoogleMap>
                }
            </div>

            <div className="grid grid-cols-2 gap-4 w-full mt-5">
                <div className = "col-span-1 flex flex-col">
                    <label className = "font-bold text-primary-1">Name</label>
                    <input
                        className = "border-2 border-primary-1 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="text"
                        onChange = {e => setFirstName(e.target.value) }
                        placeholder = {"Enter name"}
                        value = {firstName}
                    />
                </div>

                <div className = "col-span-1 flex flex-col">
                    <label className = "font-bold text-primary-1">Surname</label>
                    <input
                        className = "border-2 border-primary-1 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="text"
                        onChange = {e => setLastName(e.target.value) }
                        placeholder = {"Enter surname"}
                        value = {lastName}
                    />
                </div>

                <div className = "col-span-1 flex flex-col">
                    <label className = "font-bold text-primary-1">Email</label>
                    <input
                        className = "border-2 border-primary-1 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="email"
                        onChange = {e => setEmail(e.target.value) }
                        placeholder = {"Enter email"}
                        value = {email}
                    />
                </div>

                <div className="col-span-1 flex flex-col">
                    <label className = "font-bold text-primary-1">Contact:</label>
                    <input
                        className = "border-2 border-neutral-7 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="number"
                        onChange = { e => setContactNumber(e.target.value) }
                        placeholder = {"Enter contact"}
                        value = {contactNumber}
                    />
                </div>
            </div>



            <div className="flex flex-row w-full items-center justify-end">
                <button className="mt-5 py-3 px-10 bg-primary-1 text-white font-bold rounded-md" onClick={()=> sendOtp()}>Next</button>

            </div>
            {
                showOtpDialog &&
                renderOtpDialog()
            }
        </div>
    )

}

export default NewGuestBooking;
