import React, { useState, useEffect } from "react";
import { images } from "../../../../../../src/assets/index";
import { Link } from "react-router-dom";
import ScrollLink from "../../../../../util/ScrollLink";
import BookingModal from "../../../../../util/BookingModal";

const Banner = () => {
  const [bookingNumber, setBookingNumber] = useState("");
  const [showErrorMessage, setShowErroMessage] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const slideshowImages = [
    images.landingPicture01,
    images.landingPicture02,
    images.landingPicture03
  ];

  const handleJoinNowClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    const element = document.querySelector("#contact-form");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % slideshowImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white">
      <section className="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <p className="text-base font-semibold tracking-wider text-primary-2 uppercase">
                Join for seamless, affordable goods transport.
              </p>
              <h1 className="mt-4 text-4xl font-bold text-primary-1 lg:mt-8 sm:text-6xl xl:text-6xl">
                Your one stop shop for your Logistics Solutions
              </h1>
              <p className="mt-4 text-base text-neutral-7 lg:mt-8 sm:text-xl">
                Wheelsit can provide you with up-to-date information on your
                booking's status, driver's location, and more.
              </p>

              {/* <ScrollLink
                to="#contact-form"
                className="inline-flex items-center px-6 py-4 mt-8 font-semibold text-primary-1 hover:text-white hover:no-underline transition-all duration-200 bg-neutral-4 rounded-full lg:mt-16 hover:bg-primary-1 focus:bg-primary-1"
              >
                Already joined us? Log in  <svg
                  className="w-6 h-6 ml-8 -mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg> </ScrollLink> */}




              <div className="space-x-2">

            <a href="#contact-form"
              onClick={handleJoinNowClick}
              className="inline-flex items-center px-6 py-4 mt-8 font-semibold text-primary-1 hover:text-white hover:no-underline transition-all duration-200 bg-neutral-4 rounded-full lg:mt-16 hover:bg-primary-1 focus:bg-primary-1"
              >
              Join Now
            </a>
            {/* ... (keep the sign-up link if needed) */}
          </div>

          <BookingModal isOpen={isModalOpen} onClose={handleModalClose} />
            </div>

            <div>
              <img
                className="w-full"
                src={slideshowImages[currentImageIndex]}
                alt="Slideshow"
              />
            </div>
          </div>

          <section className="py-10  sm:py-16 lg:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div className="max-w-xl mx-auto">
                <div className="sm:p-2 sm:border-2 sm:border-transparent sm:rounded-full">
                  <div className="flex flex-col items-start sm:flex-row sm:justify-center">
                    <div className="flex-1 w-full min-w-0">
                      <div className="relative text-gray-400 focus-within:text-gray-600">
                        <label htmlFor="email" className="sr-only"></label>
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"
                            />
                          </svg>
                        </div>
                        <input
                          placeholder="Find your booking"
                          type="text"
                          className="block w-full py-4 pl-10 pr-4 text-base text-black placeholder-gray-500 transition-all duration-200 border rounded-full focus:border-transparent focus:ring-0"
                          required=""
                          value={bookingNumber}
                          onChange={({ target: { value } }) =>
                            setBookingNumber(value)
                          }
                        />
                      </div>

                    </div>

                    <Link
                      onClick={(e) => {
                        if (bookingNumber.length != 24) {
                          e.preventDefault();
                          setShowErroMessage(true);
                          setTimeout(() => {
                            setShowErroMessage(false);
                          }, 3000);
                        }
                      }}
                      className="inline-flex items-center px-6 py-4 ml-6 font-semibold hover:text-primary-1 text-white hover:no-underline transition-all duration-200 hover:bg-neutral-4 rounded-full  bg-primary-1 focus:bg-primary-1"
                    >
                      Search
                      <svg
                        className="w-5 h-5 ml-3 -mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start mt-8 sm:justify-center sm:px-0">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                  ></path>
                </svg>
                <span className="ml-2 text-sm text-gray-600">
                  {" "}
                  Your data is complely secured with us. We don’t share with
                  anyone.{" "}
                </span>
              </div>
              <div className="text-center">
                {showErrorMessage && <div className='text-xl text-red-500 py-1'>Your booking number should be 24 characters</div>}
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};
export default Banner;
