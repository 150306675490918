import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as TrailerPermissions from '../modules/TrailerPermissions'

import React, { useEffect, useState } from "react"

import {Link} from 'react-router-dom'
import Select from 'react-select'
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from "../../../context";
import {AVAILABILITY_OPTIONS, COLOR_CODES} from '../../../util/general.util'
import ReactPaginate from 'react-paginate';

const Trailers = ({
    role,
    showLoaderAction
}) => {

    const [trailers, setTrailers] = useState([]);
    const [totalPages, setTotalPages] = useState(100);
    const [filters, setFilters] = useState({
        searchFilter: '',
        fleetFilter: { value: '', label: 'Select Fleet' },
        cargoTypeFilter: { value: '', label: 'Select Cargo' },
        trailerTypeFilter: { value: '', label: 'Select Trailer' },
        availabilityFilter: { value: '', label: 'Select Availability' },
        pageFilter: 1,
        limitFilter: 10
    });

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const [filterOptions, setFilterOptions] = useState({
        fleets:[],
        cargoTypes: [],
        trailerTypes: [],
        availability: AVAILABILITY_OPTIONS
    })

    useEffect(()=>{
        fetchTrailers(filters);
        fetchFleets();
        fetchCargoTypes();
        fetchTrailerTypes();
    },[])

    const fetchCargoTypes = () => {
        axios.get(`${context.SERVER_URL}/api/cargoTypes`)
        .then((response)=>{   
            const cargoTypes = response.data.map((cargoType)=>{
                return { label:  cargoType, value: cargoType }
            });
            setFilterOptions((prevState)=>({
                ...prevState,
                cargoTypes
            }));

        }).catch((error)=>{
            console.log(error);
        })
    }


    const fetchTrailerTypes = () => {
        // showLoaderAction(true)
        axios.get(`${context.SERVER_URL}/api/trailerTypes`)
        .then((response)=>{   
            console.log("trailer types", response.data)
            const trailerTypes = response.data.map((trailerType)=>{
                return { label:  trailerType, value: trailerType }
            });
            setFilterOptions((prevState)=>({
                ...prevState,
                trailerTypes
            }));
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            // showLoaderAction(false)
        })
    }

    const fetchFleets = () => {
        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{   
            const fleets = response.data.fleets.map((fleet) => ({ label: fleet.name, value: fleet._id }));
            setFilterOptions((prevState)=>({
                ...prevState,
                fleets
            }));

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchTrailers = (filters) => {
        showLoaderAction(true);
        const {searchFilter, fleetFilter, trailerTypeFilter, cargoTypeFilter, availabilityFilter, pageFilter, limitFilter} = filters;

        axios.get(`${context.SERVER_URL}/api/trailers?searchFilter=${searchFilter}&fleetFilter=${fleetFilter?.value}&trailerTypeFilter=${trailerTypeFilter?.value}&cargoTypeFilter=${cargoTypeFilter?.value}&availabilityFilter=${availabilityFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{
            const {trailers, totalPages, currentPage}  = response.data;  
            setTrailers(trailers);
            setTotalPages(totalPages);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false)
        })
    }


    const deleteTrailer = (id) =>{

        const options = {
            title: 'Delete Trailer',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/trailers/${id}`)
                    .then((response)=>{
                        const _trailers = trailers.filter((trailer)=> trailer._id != id)
                        setTrailers(_trailers); 
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };
        
        confirmAlert(options);
    }

    return(
        <div className="w-full p-5 overflow-y-auto bg-white">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Trailers</h1>
                {
                    TrailerPermissions.isAuthorized("new",role) &&
                    <a href="/trailers/new">
                        <div className="bg-primary-1 rounded-md text-white px-3 py-2">New Trailer</div>
                    </a>
                }
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>
            <div className="flex flex-col w-full md:flex-row gap-4" >
                <div className="grid grid-cols-6 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Search filter</label>
                        <input 
                            className="w-full py-2 px-4 rounded-lg border"
                            placeholder="Search"
                            onChange={({target:{value}}) => setFilters((prevState)=>({
                                ...prevState,
                                searchFilter: value
                            }))}
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Trailer type filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Trailer Type"
                            options={filterOptions.trailerTypes}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                trailerTypeFilter: value
                            }))}
                            value={filters.trailerTypeFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Cargo type filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Year"
                            options={filterOptions.cargoTypes}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                cargoTypeFilter: value
                            }))}
                            value={filters.cargoTypeFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Fleet filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Fleet"
                            options={filterOptions.fleets}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                fleetFilter: value
                            }))}
                            value={filters.fleetFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Availability filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Availability"
                            options={filterOptions.availability}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                availabilityFilter: value
                            }))}
                            value={filters.availabilityFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>fetchTrailers(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = { 
                                fleetFilter: { value: '', label: 'Select Fleet' },
                                cargoTypeFilter: { value: '', label: 'Select Cargo' },
                                trailerTypeFilter: { value: '', label: 'Select Trailer' },
                                availabilityFilter: { value: '', label: 'Select Availability' },
                                searchFilter: '',
                                pageFilter: 1,
                                limitFilter: 10
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }));
                            fetchTrailers(resetState);
                        }}
                    > Reset</button>
                </div>
            </div>
            
            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                            >
                                Fleet
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Trailer
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Cargo
                            </th>

                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Reg
                            </th>

                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Availability
                            </th>

                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Weight
                            </th>

                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Width
                            </th>

                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Height
                            </th>

                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Length
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Select</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {trailers.map((trailer, index) => {
                            return (
                                <tr key={trailer._id}>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-transparent",
                                            "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                        )}
                                    >
                                        <div className="font-medium text-primary-2">
                                            {trailer.fleet.name}
                                        </div>

                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {trailer.trailerType}
                                    </td>

                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {trailer.cargoTypes.join(', ')}
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{trailer.registrationNumber}</div>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.trailer?.availability[trailer.availability.toLowerCase()]}`}>
                                            {trailer.availability}
                                        </span>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{trailer.capacity.weight}</div>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{trailer.capacity.width}</div>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{trailer.capacity.height}</div>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{trailer.capacity.leng}</div>
                                    </td>
                                    <td
                                        className="border-t border-transparent flex
                                            relative py-3.5 text-right text-sm font-medium"

                                    >


                                        {
                                            TrailerPermissions.isAuthorized("view", role) &&
                                            <Link to={`/trailers/view/${trailer._id}`}>
                                                <div className="border p-2 m-2 rounded-md">View</div>
                                            </Link>
                                        }
                                        {
                                            TrailerPermissions.isAuthorized("edit", role) &&
                                            <Link to ={`/trailers/edit/${trailer._id}`}>
                                                <div  className="border border-green-500 text-primary-1 p-2 m-2 rounded-md"> Edit </div>
                                            </Link>
                                        }
                                        {
                                            TrailerPermissions.isAuthorized("delete", role) &&
                                            <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>deleteTrailer(trailer._id)}> Delete </button>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="flex flex-row w-full items-center justify-end mt-5">
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={({selected}) => {
                        let _filters = {
                            ...filters,
                            pageFilter: selected + 1 
                        }
                        fetchTrailers(_filters);
                        setFilters(_filters);
                    }}
                    containerClassName={'pagination'}
                    pageClassName={'page'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'previous'}
                    nextClassName={'next'}
                    activeClassName={'active'}
                    disabledClassName={'disabled'}      
                />
            </div>                
        </div>
    )
}
export default Trailers;