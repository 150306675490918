import React from 'react'
import Modal from 'react-modal'
import { ColorRing } from 'react-loader-spinner'

const LoaderModal = ({
    showLoader
}) => {

    return(
        <div>
            <Modal
                isOpen={showLoader}
                ariaHideApp={false}
                //onAfterOpen={afterOpenModal}
                //onRequestsClose={closeModal}
                style={style}
                //contentLabel="Example Modal"
            >
                <ColorRing
                    visible={true}
                    height= {30 + "%"}
                    width= {30 + "%"}
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={["#333333", "#333333", "#333333", "#333333", "#333333"]}
                />
            </Modal>
        </div>
    )

}

const style = {
    content: {
        display: "flex",
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
        // transform: 'translate(-50%, -50%)',
        justifyContent: "center",
        alignItems: "center",
        width: 100 + "%",
        height: 100 + "%",
        background: "transparent",
    },

}

export default LoaderModal;