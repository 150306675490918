import React, { useEffect, useState } from "react";
import axios from "axios";
import context from "../../../context";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as PaymentPermissions from "../modules/PaymentPermissions";
import Select from "react-select";

const Payments = ({ role }) => {
    const [payments, setPayments] = useState([]);

    const [filters, setFilters] = useState({
        dateFromFilter: { value: '', label: '' },
        dateToFilter: { value: '', label: '' },
        bookingFilter: { value: '', label: '' }
    });

    useEffect(() => {
        fetchPayments(filters);
    }, []);

    const fetchPayments = (filters) => {
        const { dateFromFilter, dateToFilter, bookingFilter } = filters;
        axios.get(`${context.SERVER_URL}/api/payments?dateFromFilter=${dateFromFilter?.value}&dateToFilter=${dateToFilter?.value}&bookingFilter=${bookingFilter?.value}`)
            .then((response) => {
                setPayments(response.data);
            }).catch((error) => {
                console.log(error);
            });
    };

    const renderPayment = (payment, index) => {
        return (
            <tr className="border-b border-gray-300 text-sm text-left" key={index}>
                <td className="text-black font-normal">{payment.booking}</td>
                <td className="text-black font-normal">{payment.createdAt}</td>
                <td className="text-black font-normal">{payment.amountGross}</td>
                <td className="text-black font-normal">{payment.amountFee}</td>
                <td className="text-black font-normal">{payment.amountNet}</td>

                {PaymentPermissions.isAuthorized("view", role) && (
                    <td className="flex flex-row">
                        <Link to={`/payments/view/${payment._id}`}>
                            <div className="px-2 py-1 text-black border border-black bg-white rounded-lg mr-2 hover:bg-gray-100">
                                View
                            </div>
                        </Link>
                    </td>
                )}
            </tr>
        );
    };

    const deleteCar = (id) => {
        const options = {
            title: "Delete Payment",
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        axios.delete(`${context.SERVER_URL}/api/payments/${id}`)
                            .then((response) => {
                                const _payments = payments.filter((payment) => payment._id !== id);
                                setPayments(_payments);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    };

    return (
        <div className="w-full h-full p-5 overflow-y-auto bg-white">
            <div className="flex w-full justify-between mb-4 items-center">
                <h1 className="text-black font-bold text-2xl">Payments</h1>
            </div>
            <div className="border border-gray-400 my-4" />

            <div className="flex flex-col md:flex-row w-full gap-4">
                <div className="grid grid-cols-3 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-black font-semibold">Date from filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Date From"
                            options={[]}
                            onChange={(value) => setFilters((prevState) => ({
                                ...prevState,
                                dateFromFilter: value
                            }))}
                            value={filters.dateFromFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-black font-semibold">Date to filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Date To"
                            options={[]}
                            onChange={(value) => setFilters((prevState) => ({
                                ...prevState,
                                dateToFilter: value
                            }))}
                            value={filters.dateToFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-black font-semibold">Booking filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Booking"
                            options={[]}
                            onChange={(value) => setFilters((prevState) => ({
                                ...prevState,
                                bookingFilter: value
                            }))}
                            value={filters.bookingFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-black text-white rounded-lg px-4 py-2 hover:bg-gray-800"
                        onClick={() => fetchPayments(filters)}
                    >
                        Search
                    </button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 hover:bg-gray-600"
                        onClick={() => {
                            const resetState = {
                                dateFromFilter: { value: '', label: '' },
                                dateToFilter: { value: '', label: '' },
                                bookingFilter: { value: '', label: '' }
                            };
                            setFilters((prevState) => ({
                                ...prevState,
                                ...resetState
                            }));
                            fetchPayments(resetState);
                        }}
                    >
                        Reset
                    </button>
                </div>
            </div>

            <table className="w-full mt-8">
                <thead className="border-b border-gray-300">
                    <tr className="text-left">
                        <th className="text-black p-2">Booking</th>
                        <th className="text-black p-2">Date</th>
                        <th className="text-black p-2">Amount Gross</th>
                        <th className="text-black p-2">Amount Fee</th>
                        <th className="text-black p-2">Amount Net</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment, index) => renderPayment(payment, index))}
                </tbody>
            </table>
        </div>
    );
};

export default Payments;
