import * as SupportPermissions from '../modules/SupportPermissions';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

const ViewSupport = ({
    role,
    support,
    getSupportDataAction
}) => {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getSupportDataAction(id);
    }, [id, getSupportDataAction]);

    return (
        <div className='p-8 bg-gray-50 text-gray-800 min-h-screen w-full'>
            <div className='flex flex-row mb-6'>
                <button
                    onClick={() => navigate("/supports")}
                    className="text-gray-500 hover:text-gray-700 font-medium transition duration-200"
                >
                    ← Back to Supports
                </button>
            </div>

            <h1 className='text-3xl font-extrabold text-gray-900 mb-8'>Support Details</h1>

            <div className='space-y-4'>
                <div className='flex items-center'>
                    <label className='text-lg font-semibold mr-4'>Created At:</label>
                    <span className='text-gray-700'>{support?.createdAt}</span>
                </div>

                <div className='flex items-center'>
                    <label className='text-lg font-semibold mr-4'>Name:</label>
                    <span className='text-gray-700'>{`${support?.user?.firstName} ${support?.user?.lastName}`}</span>
                </div>

                <div className='flex items-center'>
                    <label className='text-lg font-semibold mr-4'>Role:</label>
                    <span className='text-gray-700'>{support?.user?.role}</span>
                </div>

                <div className='flex items-center'>
                    <label className='text-lg font-semibold mr-4'>Category:</label>
                    <span className='text-gray-700'>{support?.category}</span>
                </div>

                <div className='mt-6'>
                    <label className='text-lg font-semibold mb-2 block'>Message:</label>
                    <textarea
                        className='w-full p-4 rounded-lg border border-gray-300 bg-gray-100 focus:outline-none'
                        disabled
                        value={support?.message}
                        rows="4"
                    />
                </div>

                <div className='mt-6'>
                    <label className='text-lg font-semibold mb-2 block'>Notes:</label>
                    <textarea
                        className='w-full p-4 rounded-lg border border-gray-300 bg-gray-100 focus:outline-none'
                        disabled
                        value={support?.notes}
                        rows="4"
                    />
                </div>
            </div>

            <div className="flex flex-wrap gap-4 mt-8">
                {support?.booking && (
                    <button
                        className='bg-gray-500 text-white font-semibold rounded-lg py-2 px-6 hover:bg-gray-600 transition duration-200'
                        onClick={() => navigate(`/bookings/view/${support?.booking}`)}
                    >
                        View Booking
                    </button>
                )}
                {support?.trip && (
                    <button
                        className='bg-green-500 text-white font-semibold rounded-lg py-2 px-6 hover:bg-green-600 transition duration-200'
                        onClick={() => navigate(`/trips/view/${support?.trip}`)}
                    >
                        View Trip
                    </button>
                )}
            </div>
        </div>
    );
}

export default ViewSupport;
