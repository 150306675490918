import * as UserPermissions from '../modules/UserPermissions'

import {
    Autocomplete,
    useJsApiLoader,
} from "@react-google-maps/api";
import {Link, useParams} from 'react-router-dom'
import React,{useEffect, useRef, useState} from "react";

import Select from "react-select";
import axios from "axios";
import context from "../../../context/index"

const libraries = ["places"];

const EditUser = ({
    role
}) => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    })

    const {id} = useParams();
    
    const [companies, setCompanies] = useState([]);
    const [fleets, setFleets] = useState([]);

    const [roles, setRoles] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        role:"",
        address: "",
        passport: "",
        company: "",
        fleet: "",
        dob: "",
        nationalId: "",
        contactNumber: "",
        status: "",
        email:""
    });

    const addressRef = useRef()

    const [locationAutocomplete,setLocationAutocomplete] = useState(null);

    const onUserValueChanged = (key, value) =>{

        setUser((prevState)=>{
            return{
                ...prevState,
                [key]: value
            }
        })
    }


    
    const fetchCompanies = () =>{

        axios.get(`${context.SERVER_URL}/api/companies`)
        .then((response)=>{
            const _companies = response.data.map((company) => {
                return { label: company.name, value: company._id}
            });

            setCompanies(_companies);
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const fetchFleets = () =>{

        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{
            const _fleets = response.data.map((fleet) => {
                return { label: fleet.name, value: fleet._id}
            });

            setFleets(_fleets);
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const fetchUser = () =>{
        axios.get(`${context.SERVER_URL}/api/users/${id}`)
        .then((res)=>{
            const {
                firstName,
                lastName,
                role,
                passport,
                company,
                dob,
                nationalId,
                address,
                contactNumber,
                status,
                email,
                fleet
            } = res.data

            setUser((prevState)=>{
                return{
                    ...prevState,
                    firstName,
                    lastName,
                    role: { label: role, value: role },
                    passport,
                    company: { label:company?.name, value: company?._id },
                    fleet: { label: fleet?.name, value: fleet?._id },
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status: {label: status, value: status},
                    email
                }
            });

            addressRef.current.value = address;
        }).catch((err)=>{
            console.log(err)
        });    

    }

    const fetchRoles = () =>{
        axios.get(`${context.SERVER_URL}/api/userroles`)
        .then((res)=>{

            const { company, fleet } = res.data; 
            const roles = [...company, ...fleet ].map((role)=>{
                return { label: role , value: role }
            });
            setRoles(roles);
        }).catch((err)=>{
            console.log(err)
        });    
    }

    const fetchStatuses = () =>{
        axios.get(`${context.SERVER_URL}/api/userstatuses`)
        .then((res)=>{
            const statuses = res.data.map((status)=>{
                return { label: status , value: status }
            });
            setStatuses(statuses);
        }).catch((err)=>{
            console.log(err)
        });    
    }

    useEffect(()=>{
        fetchCompanies();
        fetchUser();
        fetchRoles();
        fetchStatuses();
        fetchFleets();
    },[]);

    const updateUser = () => {

        axios.put(`${context.SERVER_URL}/api/users/${id}`,
        {
            data:{
                ...user,
                role: user.role?.value,
                status: user.status?.value,
                company: (["logisticsSpecialist","admin", "companyManager", "user", "companyAdmin"].includes(user.role?.value)) ? user.company?.value : null,
                fleet: (["driver", "fleetAdmin", "fleetManager"].includes(user.role?.value)) ? user.fleet?.value : null
            }
        })
        .then((response)=>{
            fetchUser();
        })
        .catch((error)=>{
            console.log(error)
        })

    }
    
    if ( !isLoaded)
    {
        return(
            <div className="w-full h-full p-5">
                <h1 className="text-primary-1 font-bold">Edit Booking</h1>
                <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
            </div>
        )
    }

    return(
        <div className="w-full h-full p-3 overflow-auto">
            <div className='flex justify-between items-center pt-2'>
            <h1 className="text-primary-1 font-bold">Edit User</h1>
            <div className='flex flex-row text-gray-500'>
                <Link 
                    to = {"/users"}
                    className = "hover:no-underline text-primary-1  pr-2"
                >users</Link> | 
                {
                    UserPermissions.isAuthorized("view",role) &&
                    <Link 
                        to = {`/users/view/${id}`}
                        className = "hover:no-underline text-primary-1 pl-2"
                    >view</Link>
                }
            </div>
            </div>
            
            <div className='border border-gray-400 mt-1'/>
            

            <div className="flex flex-wrap w-full mt-5">
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    {
                        ["logisticsSpecialist","admin", "companyManager", "user", "companyAdmin"].includes(user.role?.value) &&
                        <>
                            <label className = "font-bold text-primary-1 text-xs">Company</label>
                            <Select
                                className = "text-gray-500 text-xs"
                                placeholder="Select company"
                                options={companies}
                                onChange={(value)=>onUserValueChanged("company",value)}
                                value={user.company}
                                styles={{
                                    control: (styles) => ({ 
                                        ...styles, 
                                        borderColor: "#e2e8f0", 
                                        borderWidth: 1,
                                        borderRadius: 9,
                                        boxShadow:"1px 1px 0px rgba(0,0,0,0.1)"
                                        /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`, 
                                        ':hover' : { 
                                        borderColor: getColor(!hasError), 
                                        boxShadow: `0 0 0 1px ${getColor(!hasError)}`,  
                                        }*/ 
                                    })
                                }}
                            />
                        </>
                    }
                    {
                        ["driver", "fleetAdmin", "fleetManager"].includes(user.role?.value) &&
                        <>
                            <label className = "font-bold text-primary-1 text-xs">Fleet</label>
                            <Select
                                className = "text-gray-500 text-xs"
                                placeholder="Select Fleet"
                                options={fleets}
                                onChange={(value)=>onUserValueChanged("fleet",value)}
                                value={user.fleet}
                                styles={{
                                    control: (styles) => ({ 
                                        ...styles, 
                                        borderColor: "#e2e8f0", 
                                        borderWidth: 1,
                                        borderRadius: 9,
                                        boxShadow:"1px 1px 0px rgba(0,0,0,0.1)"
                                        /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`, 
                                        ':hover' : { 
                                        borderColor: getColor(!hasError), 
                                        boxShadow: `0 0 0 1px ${getColor(!hasError)}`,  
                                        }*/ 
                                    })
                                }}
                            />
                        </>
                    }
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Name</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="text" 
                        onChange = {e => onUserValueChanged( "firstName", e.target.value) }
                        placeholder = {"Enter name"}
                        value = {user.firstName}
                    />
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Surname</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="text" 
                        onChange = {e => onUserValueChanged("lastName",e.target.value) }
                        placeholder = {"Enter surname"}
                        value = {user.lastName}
                    />
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Email</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="email" 
                        onChange = {e => onUserValueChanged("email",e.target.value) }
                        placeholder = {"Enter email"}
                        value = {user.email}
                    />
                </div>
                
                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Contact:</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="number"
                        onChange = { e => onUserValueChanged("contactNumber",e.target.value) } 
                        placeholder = {"Enter contact"}
                        value = {user.contactNumber}
                    />
                </div>

                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Passport:</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="text"
                        onChange = { e => onUserValueChanged("passport",e.target.value) } 
                        placeholder = {"Enter passport no"}
                        value = {user.passport}
                    />
                </div>

                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">National ID:</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="text"
                        onChange = { e => onUserValueChanged("nationalId",e.target.value) } 
                        placeholder = {"Enter national ID"}
                        value = {user.nationalId}
                    />
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Address</label>
                    <Autocomplete      
                        onPlaceChanged={() => {

                            if (locationAutocomplete !== null) {

                                const place = locationAutocomplete.getPlace();
                                const { formatted_address} = place;
                                onUserValueChanged("address", formatted_address)      
                            }
                            else
                            {
                                console.log('Autocomplete is not loaded yet!')
                            }
                        }}
                        onLoad={(autocomplete)=>{
                            setLocationAutocomplete(autocomplete);
                        }}
                        options={{
                            types: ["address"],
                            //componentRestrictions: { country: "za" },
                        }}
                        //defaultValue={user.address}
                    >
                        <input ref={addressRef} className="flex w-full h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs" type="text"></input>
                    </Autocomplete>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Status</label>
                    <Select
                        className = "text-gray-500 text-xs"
                        placeholder="Select company"
                        options={statuses}
                        onChange={(value)=>onUserValueChanged("status",value)}
                        value={user.status}
                        styles={{
                            control: (styles) => ({ 
                                ...styles, 
                                borderColor: "#e2e8f0", 
                                borderWidth: 1,
                                borderRadius: 9,
                                boxShadow:"1px 1px 0px rgba(0,0,0,0.1)"
                                /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`, 
                                ':hover' : { 
                                  borderColor: getColor(!hasError), 
                                  boxShadow: `0 0 0 1px ${getColor(!hasError)}`,  
                                }*/ 
                            })
                        }}
                    />
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Role</label>
                    <Select
                        className = "text-gray-500 text-xs"
                        placeholder="Select company"
                        options={roles}
                        onChange={(value)=>onUserValueChanged("role",value)}
                        value={user.role}
                        styles={{
                            control: (styles) => ({ 
                                ...styles, 
                                borderColor: "#e2e8f0", 
                                borderWidth: 1,
                                borderRadius: 9,
                                boxShadow:"1px 1px 0px rgba(0,0,0,0.1)",
                                //color: "rgba(226, 232, 240, 0.5)"
                                /*boxShadow: `0 0 0 1px ${getColor(!hasError)}`, 
                                ':hover' : { 
                                  borderColor: getColor(!hasError), 
                                  boxShadow: `0 0 0 1px ${getColor(!hasError)}`,  
                                }*/ 
                            })
                        }}
                    />
                </div>

                <button 
                    className="bg-primary-1 rounded-lg text-white font-semibold w-full py-2 mt-6 md:w-1/4 "
                    onClick= {()=>updateUser()}
                >
                    Update
                </button>
            </div>
                                    
        </div>
    )
}

export default EditUser;