import 'react-tabs/style/react-tabs.css';

import React, {useState} from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import CancelIcon from '@mui/icons-material/Cancel';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Link } from "react-router-dom";

const Trips = ({
    trips
}) =>{

    const [expanded, setExpanded] = useState(false);

    const renderTrip = (trip, index) =>{
        const date = new Date(trip.date).toLocaleDateString()

        return (
            <div className="flex flex-col border-2 border-gray-300 text-xs p-3 rounded-lg bg-white shadow" key={index}>

                <div className="flex flex-wrap bg-green-200">
                    <div className="flex flex-col">
                        <label className="text-primary-1 font-bold">Date</label>
                        <div className=" rounded-lg py-3">{date}</div>
                    </div>
                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Time</label>
                        <div className=" rounded-lg py-3">{trip.timeslot}</div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Status</label>
                        <div className=" rounded-lg py-3">{trip.status}</div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Trailer</label>
                        <div className=" rounded-lg mt-1">
                            {
                                (trip.trailer) ?
                                    `${trip?.trailer?.registrationNumber}`
                                :
                                    "unassigned"
                            }
                        </div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Vehicle</label>
                        <div className=" rounded-lg mt-1">
                            {
                                (trip.vehicle) ?
                                    `${trip?.vehicle?.registrationNumber}-${trip?.vehicle?.car?.make}-${trip?.vehicle?.car?.model}`
                                :
                                    "unassigned"
                            }
                        </div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Driver</label>
                        <div className=" rounded-lg py-3">{
                            (trip.driver) ?
                                `${trip?.driver?.firstName} ${trip?.driver?.lastName}`
                            :
                                "unassigned"
                            }
                        </div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold py-2"></label>
                        <Link className="flex justify-center items-center py-1 h-10 px-3 mx-2 text-gray-500 border-2 border-gray-500 bg-gray-200 rounded-lg" to={`/trips/view/${trip._id}`}>View</Link>
                    </div>
                </div>    
            </div>
        )
    }

    const renderTabView = () => {

        return(
            <Tabs
                className="mt-2"
            >
                <TabList>
                    <Tab>Trips</Tab>
                    <Tab>Pending</Tab>
                    <Tab>Scheduled</Tab>
                    <Tab>Accepted</Tab>
                    <Tab>On Route</Tab>
                    <Tab>Complete</Tab>
                </TabList>

                <TabPanel
                    className="h-1/4 overflox-x-auto"
                >
                    {
                        trips.trips.map((trip, index)=>renderTrip(trip, index))
                    }                   
                </TabPanel>
                <TabPanel>
                    {
                        trips.pending.map((trip, index)=>renderTrip(trip, index))
                    }  
                </TabPanel>
                <TabPanel>
                    {
                        trips.scheduled.map((trip, index)=>renderTrip(trip, index))
                    }                  
                </TabPanel>
                <TabPanel>
                    {
                        trips.accepted.map((trip, index)=>renderTrip(trip, index))
                    }                  
                </TabPanel>
                <TabPanel>
                    {
                        trips.onroute.map((trip, index)=>renderTrip(trip, index))
                    }                  
                </TabPanel>
                <TabPanel>
                    {
                        trips.complete.map((trip, index)=>renderTrip(trip, index))
                    }                  
                </TabPanel>
            </Tabs>
        )
    }


    return(
        <div className="w-full rounded-lg bg-white p-3 mb-2" key = {"trips"}>
            <div className="flex flex-row justify-end">
                <button
                    onClick={()=>setExpanded((prevState)=> !prevState) }
                >
                    { expanded == false ? <ExpandCircleDownIcon/> : <CancelIcon/> }
                </button>
            </div>
            <div className="flex flex-wrap w-full">
                <div className="w-[65px] px-2">
                    <div className="font-bold">Trips</div>
                    <div className="">{trips.trips?.length}</div>                    
                </div>
                <div className="w-[85px] px-2">
                    <div className="font-bold">pending</div>
                    <div className="">{trips.pending?.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">scheduled</div>
                    <div className="">{trips.scheduled?.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">accepted</div>
                    <div className="">{trips.accepted?.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">on route</div>
                    <div className="">{trips.onroute?.length}</div>
                </div>
                <div className="w-[95px] px-2">
                    <div className="font-bold">complete</div>
                    <div className="">{trips.complete?.length}</div>
                </div>
            </div>
            {
                (expanded == true) &&
                renderTabView()            
            }

        </div>
    )
}

export default Trips;
