import {connect} from 'react-redux'
import EditBilling from '../components/EditBilling';
import { showErrorAction } from '../../common/modules/modal';
import { 
    updateWeightBillingAction,
    deleteWeightBillingAction,
    deleteWeightRangeAction,
    updateWeightRangeAction,
    getCountriesAction,
    getBillingAction,
    addWeightBillingsAction 
} from '../modules/billings';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    countries: state.billings.countries,
    billing: state.billings.billing
});

const mapActionCreators = {
    showErrorAction,
    getCountriesAction,
    updateWeightBillingAction,
    deleteWeightBillingAction,    
    updateWeightRangeAction,
    deleteWeightRangeAction,
    getBillingAction,
    addWeightBillingsAction
};

export default connect (mapStateToProps,mapActionCreators)(EditBilling); 