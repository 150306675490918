import * as TripPermissions from '../modules/TripPermissions';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import context from "../../../context";

const AssignTrip = ({ role }) => {
    const { id } = useParams();
    const [trip, setTrip] = useState("");
    const [drivers, setDrivers] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedTrailer, setSelectedTrailer] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedFleet, setSelectedFleet] = useState(null);

    const fetchTrip = () => {
        axios.get(`${context.SERVER_URL}/api/trips/${id}`)
            .then((res) => {
                setTrip(res.data);
            }).catch((err) => {
                console.log(err);
            });
    };

    const fetchTrailers = () => {
        axios.get(`${context.SERVER_URL}/api/trailers`)
            .then((response) => {
                const _trailers = response.data.trailers.map((trailer) => ({
                    label: `Fleet: ${trailer.fleet?.name} | Reg: ${trailer.registrationNumber} | Cap: L-${trailer.capacity?.leng}m, W-${trailer.capacity?.width}m, H-${trailer.capacity?.height}m`,
                    value: { fleet: trailer.fleet?._id, trailer: trailer._id }
                }));
                setTrailers(_trailers);
            }).catch((error) => {
                console.log(error);
            });
    };

    const fetchVehicles = () => {
        axios.get(`${context.SERVER_URL}/api/vehicles?fleetFilter=${selectedTrailer?.value?.fleet}`)
            .then((response) => {
                const _vehicles = response.data.vehicles.map((vehicle) => ({
                    label: `Fleet: ${vehicle.fleet?.name} | Reg: ${vehicle.registrationNumber} | Make: ${vehicle.car?.make} | Model: ${vehicle.car?.model} | Year: ${vehicle.car?.year}`,
                    value: vehicle._id
                }));
                setVehicles(_vehicles);
            }).catch((error) => {
                console.log(error);
            });
    };

    const fetchUsers = () => {
        axios.get(`${context.SERVER_URL}/api/users?roleFilter=driver&fleetFilter=${selectedTrailer?.value?.fleet}`)
            .then((response) => {
                const _drivers = response.data.users.map((driver) => ({
                    label: `Fleet: ${driver.fleet?.name} | Name: ${driver.firstName} ${driver.lastName}`,
                    value: driver._id
                }));
                setDrivers(_drivers);
            }).catch((err) => {
                console.log(err);
            });
    };

    console.log(trip.path);

    useEffect(() => {
        fetchTrip();
    }, []);

    useEffect(() => {
        fetchTrailers();
    }, [trip]);

    useEffect(() => {
        if (selectedTrailer) {
            fetchUsers();
            fetchVehicles();
        }
    }, [selectedTrailer]);

    useEffect(() => {
        setSelectedDriver(null);
        setSelectedVehicle(null);
    }, [selectedFleet]);

    const handleAssign = () => {
        axios.put(`${context.SERVER_URL}/api/trips/${id}/assign`, {
            data: {
                trailer: selectedTrailer?.value?.trailer,
                driver: selectedDriver?.value,
                vehicle: selectedVehicle?.value
            }
        }).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="flex flex-col border border-gray-300 p-6 w-full bg-white rounded-lg shadow-lg">
            <div className="flex flex-row mb-4">
                <Link
                    to="/trips"
                    className="underline text-black hover:text-gray-600 pr-2"
                >
                    Trips
                </Link>
                |
                {TripPermissions.isAuthorized("view", role) && (
                    <Link
                        to={`/trips/view/${id}`}
                        className="underline text-black hover:text-gray-600 pl-2"
                    >
                        View
                    </Link>
                )}
            </div>
            <h1 className="text-xl font-bold text-black mb-4">Assign Trip</h1>
            <div className="flex flex-wrap bg-gray-100 p-4 rounded-lg mb-6">
                <div className="flex flex-col mr-4 mb-4">
                    <label className="text-sm font-semibold text-black mb-1">Date</label>
                    <div className="rounded-lg py-2 px-3 bg-white border border-gray-300">
                        {new Date(trip.date).toLocaleDateString()}
                    </div>
                </div>
                <div className="flex flex-col">
                    <label className="text-sm font-semibold text-black mb-1">Time</label>
                    <div className="rounded-lg py-2 px-3 bg-white border border-gray-300">
                        {trip.timeslot}
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full mb-6">
                <div className="flex flex-col mb-4">
                    <label className="text-sm font-semibold text-black mb-1">Trailer</label>
                    <Select
                        className="w-full"
                        placeholder="Select Trailer"
                        options={trailers}
                        onChange={(value) => {
                            setSelectedTrailer(value);
                            if (value?.value?.fleet !== selectedFleet) {
                                setSelectedFleet(value.value.fleet);
                            }
                        }}
                        value={selectedTrailer}
                    />
                </div>

                {selectedTrailer && (
                    <>
                        <div className="flex flex-col mb-4">
                            <label className="text-sm font-semibold text-black mb-1">Vehicle</label>
                            <Select
                                className="w-full"
                                placeholder="Select Vehicle"
                                options={vehicles}
                                onChange={(value) => setSelectedVehicle(value)}
                                value={selectedVehicle}
                            />
                        </div>

                        <div className="flex flex-col mb-4">
                            <label className="text-sm font-semibold text-black mb-1">Driver</label>
                            <Select
                                className="w-full"
                                placeholder="Select Driver"
                                options={drivers}
                                onChange={(value) => setSelectedDriver(value)}
                                value={selectedDriver}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className="mb-6 w-1/3">
                <button
                    className={
                        !selectedDriver || !selectedTrailer || !selectedVehicle
                            ? "w-full py-2 px-4 rounded-lg text-white font-bold bg-gray-400 cursor-not-allowed"
                            : "w-full py-2 px-4 rounded-lg text-white font-bold bg-black hover:bg-gray-800"
                    }
                    disabled={!selectedDriver || !selectedTrailer || !selectedVehicle}
                    onClick={handleAssign}
                >
                    Assign
                </button>
            </div>

            <div>
                <div className="flex flex-row w-full font-semibold text-black mb-2">
                    <div className="w-1/2 px-2">Address</div>
                    <div className="w-1/4 px-2">Point Type</div>
                    <div className="w-1/4 px-2">Capacity</div>
                </div>

                {trip?.path?.map((point, index) => (
                    <div className="flex flex-row w-full p-3 border-t border-gray-200" key={index}>
                        <div className="w-1/2 px-2">{point.address}</div>
                        <div className="w-1/4 px-2">{point.pointType}</div>
                        <div className="w-1/4 px-2">
                            {
                                point.capacity.map((pckg) => (
                                    <>
                                        <div>{`Weight: ${pckg?.weight} kg`}</div>
                                        <div>{`Length: ${pckg?.leng} m`}</div>
                                        <div>{`Width: ${pckg?.width} m`}</div>
                                        <div>{`Height: ${pckg?.height} m`}</div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssignTrip;
