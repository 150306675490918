import * as FleetPermissions from "../modules/FleetPermissions";

import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import axios from "axios";
import context from "../../../context";

const ViewFleet = ({ role }) => {
  const { id } = useParams();

  const [fleet, setFleet] = useState({
    name: "",
    locationAddress: "",
    status: "",
    registrationNumber: "",
    repContact: "",
    repEmail: "",
    repFirstName: "",
    repLastName: "",
  });

  const fetchFleet = () => {
    axios
      .get(`${context.SERVER_URL}/api/fleets/${id}`)
      .then((response) => {
        const {
          name,
          locationAddress,
          status,
          registrationNumber,
          repContact,
          repEmail,
          repFirstName,
          repLastName,
        } = response.data;
        setFleet({
          name,
          locationAddress,
          status,
          registrationNumber,
          repContact,
          repEmail,
          repFirstName,
          repLastName,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchFleet();
  }, []);

  return (
    <>
      <div className="p-3 w-full bg-white">
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            {/* Product etails */}
            <div className="lg:max-w-lg lg:self-end">
              <div className="mt-4">
                <h1 className="text-3xl font-bold tracking-tight text-primary-2 sm:text-4xl">
                  {fleet.name}
                </h1>
              </div>

              <section aria-labelledby="information-heading" className="mt-4">
                <h2 id="information-heading" className="sr-only">
                  fleet information
                </h2>

                <div className="flex items-center">
                  <p className="text-lg text-primary-2 sm:text-xl">
                    {fleet.registrationNumber}
                  </p>

                  <div className="ml-4 border-l border-gray-300 pl-4">
                    <div className="flex items-center">
                      <div></div>
                      <p className="ml-2 text-sm text-gray-500">Reg Number</p>
                    </div>
                  </div>
                </div>

                <div className="mb-4 space-y-6">
                  <p className="text-base text-gray-500">
                    {fleet.locationAddress}
                  </p>
                </div>

                <div className="flex flex-row">
                  <label className="text-primary-1 mr-2">
                    Representative Name:
                  </label>
                  <div className="text-gray-400 ">{`${fleet.repFirstName} ${fleet.repLastName}`}</div>
                </div>
                <div className="flex flex-row">
                  <label className="text-primary-1 mr-2">
                    Representative Contact:
                  </label>
                  <div className="text-gray-400 ">{fleet.repContact}</div>
                </div>
                <div className="flex flex-row">
                  <label className="text-primary-1 mr-2">
                    Representative Email:
                  </label>
                  <div className="text-gray-400 ">{fleet.repEmail}</div>
                </div>
                <div className="flex flex-row">
                  <label className="text-primary-1 mr-2">Status:</label>
                  <div className="text-gray-400 ">{fleet.status}</div>
                </div>
              </section>
            </div>

            {/* Product image */}
            <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
                <img
                  src="https://tailwindui.com/img/ecommerce-images/product-page-04-featured-product-shot.jpg"
                  alt="alt"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            {/* Product form */}
            <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
              <section aria-labelledby="options-heading">
                <div>
                  <div className="sm:flex sm:justify-between"></div>
                  <div className="mt-4"></div>
                  <div className="mt-10 flex space-x-4">
                    <Link
                      to={"/fleets"}
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
                    >
                      Fleets
                    </Link>{" "}
                    {FleetPermissions.isAuthorized("edit", role) && (
                      <Link
                        to={`/fleets/edit/${id}`}
                        className="flex w-full items-center justify-center rounded-md border border-primary-1 px-8 py-3 text-base font-medium text-primary-1 hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:text-white hover:no-underline"
                      >
                        edit
                      </Link>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFleet;
