import { socketBookingUpdateAction } from "../../routes/bookings/modules/bookings";
import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (bookingId) => {
    try{
        const socket = await getSocket();
        socket.on(`BookingUpdate_${bookingId}`, (data) => {
            ReduxStorage.store.dispatch(socketBookingUpdateAction(data));
        })
    }catch(error){
        console.log("Error registering booking update receiver", )
    }
}

export const unregister = async (bookingId) => {
    try{
        const socket = await getSocket();
        socket.off(`BookingUpdate_${bookingId}`);
    }catch(error){
        console.log("Error unregistering booking update receiver", )
    }
}