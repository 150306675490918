import React from "react";

const AddStationPointDialog = ({
    addStationPointToBookingPath,
    stationId,
    cancelAddStationPoint,
    pointBeforeStation,
}) => {
  
    return(
        <div className="flex flex-col gap-2 absolute right-3 top-16 bg-white rounded-lg p-5 items-center justify-center text-sm w-90">
            <button
                className="bg-gray-500 text-white rounded-lg px-4 py-2"
                onClick={()=>{
                    addStationPointToBookingPath(pointBeforeStation, stationId, "pickdrop")
                    cancelAddStationPoint();
                }}
            >Add Pick & Drop </button>
            <button
                className="bg-gray-500 text-white rounded-lg px-4 py-2"
                onClick={()=>{
                    addStationPointToBookingPath(pointBeforeStation, stationId, "pass");
                    cancelAddStationPoint();
                }}
            >Add Pass through </button>
            <button
                className="bg-gray-500 text-white rounded-lg px-4 py-2"
                onClick={()=>cancelAddStationPoint()}
            >Cancel </button>
        </div>
    )

}
export default AddStationPointDialog;
