import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialChatState = {
    chats: [],
};

const {
    GET_CHATS_DATA,
    MARK_AS_READ,
    CREATE_CHAT
} = actionConstants;

export const getChatsDataAction = (id) => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));

        axios.get(`${context.SERVER_URL}/api/chats/${id}`)
        .then((res) => {
            const {chats}  = res.data;
            dispatch({
                type: GET_CHATS_DATA,
                payload:{
                    chats,
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching chats:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const createChatAction = (message, id) =>{
    return (dispatch, store) => {
        // dispatch(showLoaderAction(true))
        axios.post(`${context.SERVER_URL}/api/chats`,{
            data:
            {
                booking: id,
                message,
            }
        })
        .then((res) => {
            const chats = res.data.chats;
            dispatch({
                type: CREATE_CHAT,
                payload:{
                    chats
                }
            })
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            // dispatch(showLoaderAction(false))
        })
    
    }    
}

export const markAsReadAction = (id) =>{
    return (dispatch, store) =>{

        axios.put(`${context.SERVER_URL}/api/chats/${id}`,
        {}).then((res)=>{   
            const chats = res.data.chats;
            dispatch({
                type: MARK_AS_READ,
                payload:{
                    chats
                }
            })
        }).catch((error)=>{
            console.log(error);
        })
    }
}

function handleGetChatsData (state, action){
    return update(state, {
        chats:{
            chats:{ $set: action.payload.chats },
        }
    })
}

function handleCreateChat (state, action){
    return state;
    // return update(state, {
    //     chats:{
    //         chats:{ $set: action.payload.chats },
    //     }
    // })
}

function handleMarkAsRead (state, action){
    return state;
    // return update(state, {
    //     chats:{
    //         chats:{ $set: action.payload.chats },
    //     }
    // })
}

export const CHATS_ACTION_HANDLERS = {
    GET_CHATS_DATA: handleGetChatsData,
    CREATE_CHAT: handleCreateChat,
    MARK_AS_READ: handleMarkAsRead,
}