import "react-confirm-alert/src/react-confirm-alert.css"; //

import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { createRef, useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditWeightBilling from "./EditWeightBilling";
import NewWeightBilling from "./NewWeightBilling";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert"; // Import

const EditBilling = ({
  showErrorAction,
  updateWeightBillingAction,
  deleteWeightBillingAction,
  updateWeightRangeAction,
  deleteWeightRangeAction,
  countries,
  getCountriesAction,
  getBillingAction,
  billing,
  addWeightBillingsAction,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [weightFrom, setWeightFrom] = useState("");
  const [weightTo, setWeightTo] = useState("");
  const [country, setCountry] = useState(null);

  const [weightBillings, setWeightBillings] = useState([]);
  const [newWeightBillings, setNewWeightBillings] = useState([
    {
      driverRateRef: createRef(),
      bookingRateRef: createRef(),
      distanceFromRef: createRef(),
      distanceTo: createRef(),
      driverRate: "",
      bookingRate: "",
      distanceFrom: "",
      distanceTo: "",
    },
  ]);
  useEffect(() => {
    getCountriesAction();
    getBillingAction(id);
  }, []);

  useEffect(() => {
    if (billing != null) {
      let _billing = JSON.stringify(billing);
      _billing = JSON.parse(_billing);
      setWeightFrom(_billing?.weightFrom);
      setWeightTo(_billing?.weightTo);
      setCountry({ label: _billing?.country, value: _billing?.country });
      setWeightBillings(_billing?.weightBillings);
      setNewWeightBillings([
        {
          driverRateRef: createRef(),
          bookingRateRef: createRef(),
          distanceFromRef: createRef(),
          distanceTo: createRef(),
          driverRate: "",
          bookingRate: "",
          distanceFrom: "",
          distanceTo: "",
        },
      ]);
    }
  }, [billing, billing?.weightBillings]);

  const onAddBilling = () => {
    setNewWeightBillings((billings) => {
      let _billings = [...billings];
      _billings.push({
        driverRateRef: createRef(),
        bookingRateRef: createRef(),
        distanceFromRef: createRef(),
        distanceTo: createRef(),
        driverRate: "",
        bookingRate: "",
        distanceFrom: "",
        distanceTo: "",
      });
      return _billings;
    });
  };

  const onNewBillingChanged = (value, key, index) => {
    console.log(value + " " + key + " " + index);
    setNewWeightBillings((billings) => {
      let newBillings = [...billings];
      if (value && value != "") {
        newBillings[index][key] = Number(value);
      } else {
        newBillings[index][key] = "";
      }
      return newBillings;
    });
  };

  const onBillingChanged = (value, key, index) => {
    setWeightBillings((billings) => {
      let newBillings = [...billings];
      if (value && value != "") {
        newBillings[index][key] = Number(value);
      } else {
        newBillings[index][key] = "";
      }
      return newBillings;
    });
  };

  const onCancelBilling = (index) => {
    setNewWeightBillings((billings) => {
      let _billings = [...billings];
      _billings.splice(index, 1);
      return _billings;
    });
  };

  const onUpdateWeightBilling = (index) => {
    let _weightBillings = [...weightBillings];
    updateWeightBillingAction({
      driverRate: _weightBillings[index].driverRate,
      bookingRate: _weightBillings[index].bookingRate,
      distanceFrom: _weightBillings[index].distanceFrom,
      distanceTo: _weightBillings[index].distanceTo,
      id: _weightBillings[index]._id,
      billingId: _weightBillings[index].billing,
    });
  };

  const deleteEntry = ({ type, id }) => {
    const options = {
      title:
        type == "weightRange"
          ? "Delete Entired Weight Billing Range"
          : "Delete Weight Billing",
      message: "Are you sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "weightRange"
              ? deleteWeightRangeAction({ id, navigate })
              : deleteWeightBillingAction({ id, billingId: billing?._id });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    };

    confirmAlert(options);
  };

  return (
    <div className="w-full h-full bg-white py-8 px-16 p-3  overflow-y-auto">
      <div className="flex justify-between items-center">
        <h1 className="text-primary-1 font-bold">Edit Billing</h1>
        <div className="text-gray-500 ">
          <Link to={"/billings"} className=" pr-2 text-primary-1 hover:no-underline">
            View Billings
          </Link>
        </div>
      </div>
      <div className="border border-gray-400 mt-1" />

      <div className="flex flex-row w-full h-fit mt-12 items-end justify-between gap-5">
        <div className="w-full">
          <label className="text-gray-500">{"Weight >="}</label>
          <input
            className="w-full p-2 mt-2 rounded-lg border"
            type="number"
            value={weightFrom}
            onChange={(e) => setWeightFrom(e.target.value)}
            placeholder={"Enter Weight"}
          />
        </div>
        <div className="w-full">
          <label className="text-gray-500">{"Weight <="}</label>
          <input
            className="w-full p-2 mt-2 rounded-lg border"
            type="number"
            value={weightTo}
            onChange={(e) => setWeightTo(e.target.value)}
            placeholder={"Enter weight"}
          />
        </div>
        <div className="w-full">
          <label className="text-gray-500">Country</label>
          <Select
            className="w-full mt-2 rounded-lg"
            placeholder="Select Country"
            options={countries}
            onChange={(value) => setCountry(value)}
            value={country}
          />
        </div>

        <div className="flex flex-row w-full justify-end space-x-4">
          <button
            className="px-2 py-2 text-gray-500 border-2 border-green-500 hover:bg-green-200 hover:text-green-800 bg-white rounded-lg"
            onClick={() =>
              updateWeightRangeAction({
                id: billing?._id,
                weightFrom,
                weightTo,
                country: country?.value,
              })
            }
          >
            Update
          </button>
          <button
            className="px-2 py-2 text-red-500 border-2 border-red-500 hover:bg-red-200 hover:text-red-800 rounded-lg"
            onClick={() =>
              deleteEntry({ type: "weightRange", id: billing?._id })
            }
          >
            {" "}
            Delete{" "}
          </button>
        </div>
      </div>
      <div className="w-full">
        <h1 className="text-2xl text-primary-2 pt-6">Weight Billings</h1>

        <div className="">
          <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pl-0"
                      >
                        {"Distance >="}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-semibold text-primary-2"
                      >
                        {"Distance <="}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-semibold text-primary-2"
                      >
                        Booking Rate / km
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pr-0"
                      >
                        Driver Rate / km
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pr-0"
                      >
                        Profite Rate /km
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pr-0"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {weightBillings.map(
                      (
                        {
                          driverRateRef,
                          driverRate,
                          bookingRateRef,
                          bookingRate,
                          distanceFromRef,
                          distanceFrom,
                          distanceToRef,
                          distanceTo,
                          _id,
                        },
                        index
                      ) => (
                        <EditWeightBilling
                          key={index}
                          index={index}
                          bookingRateRef={bookingRateRef}
                          driverRateRef={driverRateRef}
                          distanceFromRef={distanceFromRef}
                          distanceToRef={distanceToRef}
                          onBillingChanged={onBillingChanged}
                          driverRate={driverRate}
                          bookingRate={bookingRate}
                          distanceFrom={distanceFrom}
                          distanceTo={distanceTo}
                          deleteEntry={deleteEntry}
                          id={_id}
                          onUpdateWeightBilling={onUpdateWeightBilling}
                        />
                      )
                    )}
                  </tbody>
                </table>
                <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
                  <div className="flex flex-1 justify-between sm:hidden">
                    <Link
                      to="/"
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-primary-1 hover:bg-gray-50"
                    >
                      Previous
                    </Link>
                    <Link
                      to="/"
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-primary-1 hover:bg-gray-50"
                    >
                      Next
                    </Link>
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-primary-1">
                        Showing <span className="font-medium">1</span> to{" "}
                        <span className="font-medium">10</span> of{" "}
                        <span className="font-medium">97</span> results
                      </p>
                    </div>
                    <div>
                      <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                      >
                        <Link
                          to="/"
                          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Previous</span>
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Link>
                        {/* Current: "z-10 bg-primary-1 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-1", Default: "text-primary-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                        <Link
                          to="/"
                          aria-current="page"
                          className="relative z-10 inline-flex items-center bg-teal-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-1"
                        >
                          1
                        </Link>
                        <Link
                          to="/"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-primary-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          2
                        </Link>
                        <Link
                          to="/"
                          className="relative hidden items-center px-4 py-2 text-sm font-semibold text-primary-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                        >
                          3
                        </Link>
                        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-primary-1 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                          ...
                        </span>
                        <Link
                          to="/"
                          className="relative hidden items-center px-4 py-2 text-sm font-semibold text-primary-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                        >
                          8
                        </Link>
                        <Link
                          to="/"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-primary-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          9
                        </Link>
                        <Link
                          to="/"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-primary-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          10
                        </Link>
                        <Link
                          to="/"
                          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Next</span>
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Link>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-5">
        <h1 className="text-2xl text-primary-1 py-6"> Add Weight Billings</h1>

        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr className="divide-x divide-gray-200">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pl-0"
              >
                {"Distance >="}
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-left text-sm font-semibold text-primary-2"
              >
                {"Distance <="}
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-left text-sm font-semibold text-primary-2"
              >
                Booking Rate / km
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pr-0"
              >
                Driver Rate / km
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pr-0"
              >
                Profite Rate /km
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-primary-2 sm:pr-0"
              ></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {newWeightBillings.map(
              (
                {
                  driverRateRef,
                  driverRate,
                  bookingRateRef,
                  bookingRate,
                  distanceFromRef,
                  distanceFrom,
                  distanceToRef,
                  distanceTo,
                },
                index
              ) => (
                <NewWeightBilling
                  key={index}
                  index={index}
                  bookingRateRef={bookingRateRef}
                  driverRateRef={driverRateRef}
                  distanceFromRef={distanceFromRef}
                  distanceToRef={distanceToRef}
                  onCancelBilling={onCancelBilling}
                  onBillingChanged={onNewBillingChanged}
                  driverRate={driverRate}
                  bookingRate={bookingRate}
                  distanceFrom={distanceFrom}
                  distanceTo={distanceTo}
                />
              )
            )}
          </tbody>
        </table>
        <div className="flex flex-row items-center justify-between">
          <button
            type="button"
            onClick={() =>
              addWeightBillingsAction({
                id: billing?._id,
                newWeightBillings,
              })
            }
            className="inline-flex items-center gap-x-1.5 rounded-md bg-primary-1 px-4 py-2 text-sm text-white shadow-sm hover:bg-teal-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-1"
          >
            <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
          <div className=" p-4">
            <button
              className="flex item-center justify-center p-1 bg-primary-1 text-white rounded-full h-fit"
              onClick={onAddBilling}
            >
              <AddCircleIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBilling;
