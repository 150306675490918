import {connect} from 'react-redux'
import Trips from '../components/Trips';
import { getTripsDataAction } from '../modules/trips';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    trips: state.trips.trips,
    totalPages: state.trips.totalPages,
    currentPage: state.trips.currentPage
});

const mapActionCreators = {
    getTripsDataAction
};

export default connect (mapStateToProps,mapActionCreators)(Trips); 