import {connect} from 'react-redux'
import Billings from '../components/Billings';
import { 
    getBillingsAction,
    deleteWeightRangeAction
} from '../modules/billings';
const mapStateToProps = (state) =>({
    role:  state.profile.role,
    billings: state.billings.billings,
    billingCountries: state.billings.billingCountries
});

const mapActionCreators = {
    getBillingsAction,
    deleteWeightRangeAction
};

export default connect (mapStateToProps,mapActionCreators)(Billings); 