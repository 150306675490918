import React, { useEffect, useState, useRef } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    DirectionsRenderer,
    MarkerClusterer
  } from "@react-google-maps/api";
import { MapStyles, vehicleIcon } from "../../../util/map.util";
import { COLOR_CODES } from "../../../util/general.util";
import TrailerLocation from "./TrailerLocation"
import context from "../../../context";
const libraries = ["places"];

const Map = ({
    trailers
}) => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: context.GOOGLE_API_KEY,
    libraries
  });
  const [directions, setDirections] = useState(null);
  const [filteredTrailers, setFilteredTrailers] = useState([]);

  const mapRef = useRef();
  
  const centerMap = () =>{
    var bounds = new window.google.maps.LatLngBounds();
    filteredTrailers.forEach((filteredTrailer)=>{
      bounds.extend(
        {
          lat: filteredTrailer?.location[0]?.location.coordinates[1],
          lng: filteredTrailer?.location[0]?.location.coordinates[0]
        }
      );
    })
    mapRef.current?.setCenter(bounds.getCenter());
    mapRef.current?.fitBounds(bounds);
  }

  // const calculateRoute = (points) => {
  //   let pointsLatLng = points.map((point) => {
  //     return {
  //       lat: point.location.coordinates[1],
  //       lng: point.location.coordinates[0]
  //     }
  //   });

  //   const origin = pointsLatLng.shift();
  //   const destination = pointsLatLng.pop();
  //   let waypoints = [];
  //   pointsLatLng.forEach((point) => {
  //     if (point.lat != null) {
  //       waypoints.push({
  //         location: point,
  //         stopover: true
  //       })
  //     }
  //   });

  //   if (origin.lat != null && destination.lat != null) {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     //const origin = { lat: pointsLatLng[0].lat, lng: pointsLatLng[0].lng };
  //     //const destination = { lat: pointsLatLng[pointsLatLng.length-1].lat, lng: pointsLatLng[pointsLatLng.length-1].lng };

  //     directionsService.route(
  //       {
  //         origin: origin,
  //         destination: destination,
  //         waypoints,
  //         travelMode: window.google.maps.TravelMode.DRIVING
  //       },
  //       (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //           setDirections(result);
  //         } else {
  //           console.error(`error fetching directions ${result}`);
  //         }
  //       }
  //     );
  //   }
  // }

  useEffect(()=>{
    let _filteredTrailers = trailers.filter((trailer) => trailer.location[0]?.location != null );
    setFilteredTrailers(_filteredTrailers);
  },[trailers]);

  useEffect(()=>{
    if(filteredTrailers && isLoaded) centerMap();
  },[filteredTrailers, isLoaded])

  if (!isLoaded ) return (<></>)

  return(        
    <div className="mt-6 bg-white rounded-lg shadow-md p-6 w-full h-full">
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          borderRadius: '0.5rem',
        }}
        zoom={2}
        onLoad={(map) => {
          mapRef.current = map;
        }}
        options={{
          styles: MapStyles,
        }}
      >
        <MarkerClusterer
          averageCenter={true}
          gridSize={2}
          maxZoom={1}
        >
          {
            (clusterer) => {
              let trailerLocations =  filteredTrailers.map((trailer)=>(
                <TrailerLocation
                  trailer = {trailer}
                  clusterer = {clusterer}
                />
              ))
              return trailerLocations;

            }
          }
        </MarkerClusterer>
        {
          // directions &&
          // <DirectionsRenderer
          //   options={{
          //     markerOptions: {
          //       icon: {
          //         path: 'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
          //         fillColor: 'rgb(0,0,255)',
          //         fillOpacity: 1,
          //         scale: 1.2,
          //         strokeColor: 'rgba(255, 255, 255, 0.6)',
          //         strokeWeight: 15,
          //       },
          //       anchorPoint: {
          //         x: 0.5,
          //         y: 0.5
          //       }
          //     }
          //   }}
          //   directions={directions}
          // />
        }
      </GoogleMap>
    </div>
  )
}

export default Map;