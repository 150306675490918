import {connect} from 'react-redux'
import EditUser from '../components/EditUser';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(EditUser); 