import React from "react";
import { TIMESLOTS } from "../../../util/date.util";
const Timeslots = ({
    selectedTimeslot,
    setSelectedTimeslot
}) => {
    const renderTimeslot = (slot, index) =>{
        return (
            <button 
                key={index} 
                className={
                    (selectedTimeslot == slot.value) ?
                        "w-full bg-white p-2 my-1 text-gray-500 text-xs rounded-lg border-2 border-gray-500 justify-center items-center"
                    :
                        "w-full bg-white p-2 my-1 text-gray-500 text-xs rounded-lg justify-center items-center"
                } 
                onClick={()=>setSelectedTimeslot(slot.value)}
            >
                {slot.label}
            </button>
        )
    }

    return (
        <div className="flex flex-col w-fit border-2 border-gray-700 rounded-lg p-2 overflow-y-auto items-center mr-2 h-">
            {
                [{ label: "All", value: "All" }, ...TIMESLOTS].map((slot, index)=>{
                    return renderTimeslot(slot,index)
                })
            }
        </div>
    )
}
export default Timeslots;