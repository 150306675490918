import update from 'react-addons-update'
import axios from "axios";
import context from "../../../context";
import actionConstants from "./actionConstants";
import { showErrorAction, showLoaderAction } from "../../common/modules/modal";

export const initialBillingsState = {
    countries: [],
    billings: {},
    billingCountries:[],
    billing: null
};


const {
    GET_COUNTRIES,
    GET_BILLINGS,
    GET_BILLING
} = actionConstants;


export const submitBillingsAction = ({
    country,
    weightBillings,
    weightFrom,
    weightTo,
    navigate
}) => {

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        let _weightBillings = [];
        let _incompleteWeightBillings = [];

        weightBillings.forEach(({bookingRate, driverRate, distanceFrom, distanceTo }, index)=>{
            if (bookingRate && bookingRate != '' && driverRate && driverRate != '' 
                && distanceFrom && distanceFrom != '' && distanceTo && distanceTo != ''
            ){
                _weightBillings.push({
                    bookingRate,
                    driverRate,
                    distanceFrom,
                    distanceTo
                })
            } 
            else
            {
                _incompleteWeightBillings.push({
                    bookingRate,
                    driverRate,
                    distanceFrom,
                    distanceTo,
                    index
                })
            }
        })

        if (_incompleteWeightBillings.length != 0)
        {
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Billings on indices - ${ _incompleteWeightBillings.map((billing)=> billing.index).join(", ")} `
            }))
            dispatch(showLoaderAction(false))
        }
        else
        {
            axios.post(`${context.SERVER_URL}/api/billings`,
            {
                data:
                {
                    weightFrom,
                    weightTo,
                    country,
                    weightBillings: _weightBillings
                }

            }).then((response)=>{   

                console.log(response)
                navigate('/billings')

            }).catch((error)=>{
                console.log(error);
                dispatch(showErrorAction({
                    showError: true,
                    errorMessage: `Error creating billings. - ${error.message}`
                }))
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
        }
    }
}

export const addWeightBillingsAction = ({
    id,
    newWeightBillings,
}) => {

    console.log( "add billing called")

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        let _newWeightBillings = [];
        let _incompleteNewWeightBillings = [];

        newWeightBillings.forEach(({bookingRate, driverRate, distanceFrom, distanceTo }, index)=>{
            if (bookingRate && bookingRate != '' && driverRate && driverRate != '' 
                && distanceFrom && distanceFrom != '' && distanceTo && distanceTo != ''
            ){
                _newWeightBillings.push({
                    bookingRate,
                    driverRate,
                    distanceFrom,
                    distanceTo
                })
            } 
            else
            {
                _incompleteNewWeightBillings.push({
                    bookingRate,
                    driverRate,
                    distanceFrom,
                    distanceTo,
                    index
                })
            }
        })

        if (_incompleteNewWeightBillings.length != 0)
        {
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `New Billings on indices - ${ _incompleteNewWeightBillings.map((billing)=> billing.index).join(", ")} `
            }))
            dispatch(showLoaderAction(false))
        }
        else
        {
            axios.put(`${context.SERVER_URL}/api/billings/${id}/addWeightBillings`,
            {
                data:
                {
                    weightBillings: _newWeightBillings
                }

            })
            .then((response)=>{   
                dispatch(getBillingAction(id))
                console.log(response)

            })
            .catch((error)=>{
                console.log(error);
                dispatch(showErrorAction({
                    showError: true,
                    errorMessage: `Error creating billings. - ${error.message}`
                }))
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
        }
    }
}

export const getCountriesAction = () => {

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/countries`)
        .then(res=>{
            const countries = res.data.map((country)=>({ label: country, value: country }));
            dispatch({
                type: GET_COUNTRIES,
                payload:{
                    countries,
                }
            })
        })
        .catch(error=>{
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting countries - ${error.message}`
            }))        
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const getBillingsAction = () => {

    return (dispatch, store) => {
        axios.get(`${context.SERVER_URL}/api/billings`)
        .then(res=>{
            let billings = res.data.billings;
            let billingCountries = billings.map((billing)=>({ label: billing["_id"]["country"], value: billing["_id"]["country"]}))
            dispatch({
                type: GET_BILLINGS,
                payload:{
                    billings,
                    billingCountries,
                }
            })
        })
        .catch(error=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting billings - ${error.message}`
            }))        
        })
    }
}

export const getBillingAction = (id) => {

    return (dispatch, store) => {

        axios.get(`${context.SERVER_URL}/api/billings/${id}`)
        .then(res=>{
            let billing = res.data.billing;
            dispatch({
                type: GET_BILLING,
                payload:{
                    billing,
                }
            })
        })
        .catch(error=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting billing - ${error.message}`
            }))        
        })
    }
}

export const deleteWeightBillingAction = ({
    billingId,
    id
}) => {
    
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.delete(`${context.SERVER_URL}/api/weightBilling/${id}`)
        .then(res=>{
            dispatch(getBillingAction(billingId))
        })
        .catch(error=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error deleting weight billing - ${error.message}`
            }))        
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const deleteWeightRangeAction = ({
    id,
    navigate
}) => {
    
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.delete(`${context.SERVER_URL}/api/billings/${id}`)
        .then(res=>{
            navigate('/billings')
        })
        .catch(error=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error deleting billing range - ${error.message}`
            }))        
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const updateWeightRangeAction = ({
    id,
    weightFrom,
    weightTo,
    country
})=>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.put(`${context.SERVER_URL}/api/billings/${id}`,
        {
            data:
            {
                weightFrom,
                weightTo,
                country
            }

        })
        .then((response)=>{   
            dispatch(getBillingAction(id))
        })
        .catch((error)=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error updating billings range. - ${error.message}`
            }))
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const updateWeightBillingAction = ({
    billingId,
    id,
    bookingRate,
    driverRate,
    distanceFrom,
    distanceTo
})=>{

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.put(`${context.SERVER_URL}/api/weightBilling/${id}`,
        {
            data:
            {
                bookingRate,
                driverRate,
                distanceFrom,
                distanceTo
            }

        }).then((response)=>{   
            dispatch(getBillingAction(billingId));
        }).catch((error)=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error updating weight billing. - ${error.message}`
            }))
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

function handleGetCountries (state, action){
    return update(state, {
        billings:{
            countries:{ $set: action.payload.countries },
        }
    })
}

function handleGetBillings (state, action){
    return update(state, {
        billings:{
            billings:{ $set: action.payload.billings },
            billingCountries:{ $set: action.payload.billingCountries },
        }
    })
}

function handleGetBilling (state, action){
    return update(state, {
        billings:{
            billing:{ $set: action.payload.billing },
        }
    })
}

export const BILLINGS_ACTION_HANDLERS = {
    GET_COUNTRIES: handleGetCountries,
    GET_BILLINGS: handleGetBillings,
    GET_BILLING: handleGetBilling,
}