import {connect} from 'react-redux'
import ViewBooking from '../components/ViewBooking';
import {getBookingDataAction} from '../modules/bookings';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    booking: state.bookings.booking
});

const mapActionCreators = {
    getBookingDataAction,
};

export default connect (mapStateToProps,mapActionCreators)(ViewBooking); 