export const images = {
    Logo: 'https://i.ibb.co/ssvWZ9q/w.png',
    svgLogo: require('./logo.svg'),
    landingPicture01: 'https://i.ibb.co/q0GwvBF/landing.png',
    landingPicture02: 'https://i.ibb.co/K08B0Qy/landing2.png',
    landingPicture03: 'https://i.ibb.co/4sjZQSR/landing3.png',
    truckPicture: 'https://i.ibb.co/X4v3cNZ/truck.jpg',
    truckPicture2: 'https://i.ibb.co/qNvZML3/truck2.jpg',
    truckPicture3: 'https://i.ibb.co/yP0VhZ2/truck3.jpg',
    truckRegisterPicture: 'https://i.ibb.co/dW92qFF/truck-register.png',
}
