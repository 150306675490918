import { socketBookingPointUpdateAction } from "../../routes/bookings/modules/bookings";
import { socketTripPointUpdateAction } from "../../routes/trips/modules/trips";

import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (pointId) => {
    try {
        const socket = await getSocket();
        socket.on(`PointUpdate_${pointId}`, (data) => {
            ReduxStorage.store.dispatch(socketBookingPointUpdateAction(data));
            ReduxStorage.store.dispatch(socketTripPointUpdateAction(data));
        }) 
    } catch (error) {
        console.log("Error registering point update receiver: ", error);
    }
}

export const unregister = async (pointId) => { 
    try {
        const socket = await getSocket();
        socket.off(`PointUpdate_${pointId}`);
    } catch (error) {
        console.log("Error unregistering point update receiver: ", error);
    }
}