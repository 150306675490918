import React, {useState} from 'react'
import About from './about/About'
import Features from './features/Features'
import Footer from './footer/Footer'
import Header from './header/Header'
import Home from './home/Home'
import Services from './services/Services'

const Landing = () => {

    // const [mobile, setMobile] = useState(false);



    const [landingState, setLandingState] = useState({
        currentPage: "Home"
    })

    return(
        <div>

			<Header
                landingState = {landingState}
                setLandingState = {setLandingState}
                // mobile = {mobile}
            />
            {
                (landingState.currentPage = "Home") &&
                <Home />
            }
            {
                (landingState.currentPage = "Services") &&
                <Services />
            }
            {
                (landingState.currentPage = "Features") &&
                <Features />
            }
            {/* {
                (landingState.currentPage = "Prices") &&
                <Home />
            } */}
            {
                (landingState.currentPage = "About") &&
                <About />
            }
            <Footer />
	    </div>
    )


/*
    return(
        <div className='w-full h-full justify-center'>
            <div
                className='w-full h-1/2 px-5'
                style={{
                    backgroundImage: `url(${images.truck})`,
                    //backgroundSize: "coo",
                    //backgroundRepeat: "no-repeat"

                }}
            >
                <div className='flex flex-row w-full justify-between'>
                    <div>
                        <span className = "flex flex-row text-3xl font-bold underline py-2 text-primary-1">
                            Wheels <span className="text-orange-custom text-4xl">it</span> Now
                        </span>
                    </div>
                    <div className='flex flex-row justify-between gap-4'>
                        <div>About</div>
                        <div>Services</div>
                        <div>About</div>
                    </div>
                </div>

                <div
                    className='flex flex-row w-full justify-end'
                >
                    <button
                        className='bg-white font-bold text-primary-1 rounded-lg py-1 px-2 mr-2 shadow'
                    >
                        Register
                    </button>
                    <button
                        className='bg-white font-bold text-primary-1 rounded-lg py-1 px-2 shadow'
                    >
                        Login
                    </button>
                </div>
            </div>

            <div
                className='flex flex-row w-full h-1/4 absolute top-1/2 justify-center'
                style={{
                    marginTop: -40
                }}
            >
                <div
                    className='flex flex-row w-3/4 bg-red-300'
                >
                    <div
                        className='w-1/2 h-full'
                        style={{
                            backgroundImage: `url(${images.worldMap})`,
                            backgroundSize: "cover"
                        }}
                    >
                    </div>
                    <div
                        className='w-1/2 h-full'
                    ></div>

                </div>

            </div>

        </div>
    )*/
}

export default Landing;
