import * as UserPermissions from '../modules/UserPermissions'

import {Link, useParams} from 'react-router-dom'
import React,{useEffect, useRef, useState} from "react";

import axios from "axios";
import context from "../../../context/index"

const ViewUser = ({
    role
}) => {

    const {id} = useParams();
    
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        role:"",
        address: "",
        passport: "",
        company: {
            name: ""
        },
        fleet: {
            name: ""
        },
        dob: "",
        nationalId: "",
        contactNumber: "",
        status: "",
        email:""
    });

    const fetchUser = () =>{
        axios.get(`${context.SERVER_URL}/api/users/${id}`)
        .then((res)=>{
            const {
                firstName,
                lastName,
                role,
                passport,
                company,
                dob,
                nationalId,
                address,
                contactNumber,
                status,
                email,
                fleet
            } = res.data;

            setUser((prevState)=>{
                return{
                    ...prevState,
                    firstName,
                    lastName,
                    role,
                    passport,
                    ...( company && { company } ),
                    ...( fleet && { fleet }),
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email
                }
            });

        }).catch((err)=>{
            console.log(err)
        });    

    }
    useEffect(()=>{
        fetchUser();
    },[]);

    return(
        <div className="w-full h-full p-3 overflow-auto">
            <div className='flex justify-between items-center pt-2'>
            <h1 className="text-primary-1 font-bold">View User</h1>
            <div className='flex flex-row text-gray-500'>
                
                <Link 
                    to = {"/users"}
                    className = "hover:no-underline text-primary-1 pr-2"
                >users</Link> | 
               {
                    UserPermissions.isAuthorized("edit",role) &&
                    <Link 
                        to = {`/users/edit/${id}`}
                        className = "hover:no-underline text-primary-1 pl-2"
                    >edit</Link>
                }
            </div>
            </div>

            <div className='border border-gray-400 my-6'/>

           

            <div className="flex flex-wrap w-full">
                {
                    ["driver", "fleetAdmin", "fleetManager"].includes(user.role) &&
                    <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                        <label className = "font-bold text-primary-1 text-xs">Fleet:</label>
                        <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs" >{user.fleet.name}</div>
                    </div>
                }
                { 
                    ["logisticsSpecialist","admin", "companyManager", "user", "companyAdmin"].includes(user.role) &&
                    <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                        <label className = "font-bold text-primary-1 text-xs">Company:</label>
                        <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs" >{user.company.name}</div>
                    </div>
                }
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4 text-xs">
                    <label className = "font-bold text-primary-1">Name</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.firstName}</div>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Surname</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.lastName}</div>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Email</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.email}</div>
                </div>
                
                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Contact:</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.contactNumber}</div>
                </div>

                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Passport:</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.passport}</div>
                </div>

                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">National ID:</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.nationalId}</div>
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Address</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.address}</div>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Status</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.status}</div>
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Role</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.role}</div>
                </div>
            </div>
                                    
        </div>
    )
}

export default ViewUser;