import { initialProfileState } from "../routes/profile/modules/profile";
import { initialLoginState } from "../routes/login/modules/login";
import { initialDashboardState } from "../routes/dashboard/modules/dashboard";
import { initialModalState } from "../routes/common/modules/modal";
import { initialLandingState } from "../routes/landing/modules/landing";
import { initialRegisterState } from "../routes/register/modules/register";
import { initialBillingsState } from "../routes/billings/modules/billings";
import { initialBookingState } from "../routes/bookings/modules/bookings";
import { initialTripState } from "../routes/trips/modules/trips";
import { initialTrackingState } from "../routes/tracking/modules/tracking";
import { initialSupportsState } from "../routes/supports/modules/support";
import { initialCompanyState } from "../routes/companies/modules/companies";
import { initialChatState } from "../routes/chats/modules/chats";

import { PROFILE_ACTION_HANDLERS } from "../routes/profile/modules/profile";
import { LOGIN_ACTION_HANDLERS } from "../routes/login/modules/login";
import { DASHBOARD_ACTION_HANDLERS } from "../routes/dashboard/modules/dashboard";
import { MODAL_ACTION_HANDLERS } from "../routes/common/modules/modal";
import { LANDING_ACTION_HANDLERS } from "../routes/landing/modules/landing";
import { REGISTER_ACTION_HANDLERS } from "../routes/register/modules/register";
import { BILLINGS_ACTION_HANDLERS } from "../routes/billings/modules/billings";
import { BOOKINGS_ACTION_HANDLERS } from "../routes/bookings/modules/bookings";
import { TRIPS_ACTION_HANDLERS } from "../routes/trips/modules/trips";
import { TRACKING_ACTION_HANDLERS } from "../routes/tracking/modules/tracking";
import { SUPPORTS_ACTION_HANDLERS } from "../routes/supports/modules/support";
import { COMPANIES_ACTION_HANDLERS } from "../routes/companies/modules/companies";
import { CHATS_ACTION_HANDLERS } from "../routes/chats/modules/chats";

const ACTION_HANDLERS = {
    ...PROFILE_ACTION_HANDLERS,
    ...LOGIN_ACTION_HANDLERS,
    ...DASHBOARD_ACTION_HANDLERS,
    ...MODAL_ACTION_HANDLERS,
    ...LANDING_ACTION_HANDLERS,
    ...REGISTER_ACTION_HANDLERS,
    ...BILLINGS_ACTION_HANDLERS,
    ...BOOKINGS_ACTION_HANDLERS,
    ...TRIPS_ACTION_HANDLERS,
    ...TRACKING_ACTION_HANDLERS,
    ...SUPPORTS_ACTION_HANDLERS,
    ...COMPANIES_ACTION_HANDLERS,
    ...CHATS_ACTION_HANDLERS
}

const initialState = {
    profile: { ...initialProfileState },
    login: { ...initialLoginState },
    dashboard: { ...initialDashboardState},
    modal: { ...initialModalState },
    landing: { ...initialLandingState},
    register: { ...initialRegisterState},
    billings: {...initialBillingsState},
    bookings: {...initialBookingState},
    trips: {...initialTripState},
    tracking: {...initialTrackingState},
    supports: {...initialSupportsState},
    companies: {...initialCompanyState},
    chats: {...initialChatState}
}


export const AppReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    // if the handler does not exist, just return the state, no changes 
    return handler ? handler(state,action): state;
}