    import React, { useEffect, useState } from 'react';

    import axios from 'axios';
    import context from '../../../context';

    const BookingSummary = ({
        submitBooking,
        setBookingStage,
        points,
        fare,
        paidFare,
        directions,
        page,
        bookingChanged
    }) => {

        const [calculatedFare, setCalculatedFare] = useState(null);

        const getCalculatedFare = async () => {
            let path = [];
            let count = 0;
            points.forEach((point, index) => {
                if (point.latitude != null) {
                    let _pointCapacity = point.capacity.map((product) => ({
                        description: product.description,
                        leng: Number(product.leng),
                        width: Number(product.width),
                        height: Number(product.height),
                        weight: Number(product.weight),
                        category: product.category._id,
                        packaging: product.packaging,
                        quantity: Number(product.quantity)
                    }));
                    path.push({
                        pointType: point.type?.value,
                        order: count,
                        capacity: _pointCapacity,
                        address: point.address,
                        latitude: point.latitude,
                        longitude: point.longitude,
                        distance: (index == 0) ? 0 : directions.routes[0].legs[index - 1]?.distance.value / 1000,
                        country: point.country
                    })
                    count++;
                }
            });

            axios.post(`${context.SERVER_URL}/api/calculateBookingFare`,
                {
                    data:
                    {
                        path,
                    }

                }).then((response) => {

                    setCalculatedFare(response.data.fare);

                }).catch((error) => {
                    console.log(error);

                })
        }

        useEffect(() => {
            if (points.length > 0 && directions != null) {
                getCalculatedFare();
            }
        },[points, directions])


        const renderPoint = (point, index) => {
            return (
                <div className='flex flex-col w-full mb-5' key={index}>
                    <div className='flex flex-row w-full gap-4'>
                        <div className='flex flex-col w-1/2 items-center justify-center'>
                            <div className='w-full'>{point.address}</div>
                            <div className='w-full'>{point?.type?.label}</div>
                        </div>
                        <div className='flex flex-col w-1/2 items-center justify-center'>
                            {
                                point.capacity.map((product) => (
                                    <div className='flex flex-row w-full gap-4'>
                                        <div className=''>{product.description}</div>
                                        <div className=''>{product.quantity}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className=" bg-gray-50 rounded-xl shadow-lg p-6">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">Booking Summary</h1>

            <div className="space-y-4 mb-8">
                {points.map((point, index) => renderPoint(point, index))}
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Fare Details</h2>
                <div className="space-y-3">
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Paid Fare</span>
                        <span className="font-medium text-green-600">${paidFare.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Total Fare</span>
                        {calculatedFare === null ? (
                            <span className="text-gray-400">Calculating...</span>
                        ) : (
                            <span className="font-medium text-gray-800">${calculatedFare.toFixed(2)}</span>
                        )}
                    </div>
                    <div className="flex justify-between items-center pt-3 border-t">
                        <span className="text-lg font-semibold text-gray-800">Outstanding Balance</span>
                        {calculatedFare === null ? (
                            <span className="text-gray-400">Calculating...</span>
                        ) : (
                            <span className="text-lg font-bold text-blue-600">
                                ${(calculatedFare - paidFare).toFixed(2)}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center">
                <button
                    className="py-2 px-6 bg-gray-200 text-gray-800 font-medium rounded-lg hover:bg-gray-300 transition duration-200"
                    onClick={() => setBookingStage("directions")}
                >
                    Back
                </button>

                {page === "newBooking" && (
                    <button
                        className={`py-2 px-6 ${
                            calculatedFare === null
                                ? "bg-gray-400 cursor-not-allowed"
                                : "bg-green-600 hover:bg-green-700"
                        } text-white font-medium rounded-lg transition duration-200`}
                        disabled={calculatedFare === null}
                        onClick={() => submitBooking()}
                    >
                        Create Booking
                    </button>
                )}

                {page === "editBooking" && (
                    <div className="space-x-4">
                        <button
                            className={`py-2 px-6 ${
                                calculatedFare === null || !bookingChanged()
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 hover:bg-blue-700"
                            } text-white font-medium rounded-lg transition duration-200`}
                            disabled={calculatedFare === null || !bookingChanged()}
                            onClick={() => submitBooking(false)}
                        >
                            Save Changes
                        </button>
                        <button
                            className={`py-2 px-6 ${
                                calculatedFare === null || bookingChanged()
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-green-600 hover:bg-green-700"
                            } text-white font-medium rounded-lg transition duration-200`}
                            disabled={calculatedFare === null || bookingChanged()}
                            onClick={() => submitBooking(true)}
                        >
                            Pay Now
                        </button>
                    </div>
                )}
            </div>
        </div>

        );
    };

    export default BookingSummary;
