import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as SupportPermissions from '../modules/SupportPermissions';

import React, { useEffect, useState } from "react"

import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import { SUPPORT_CATEGORIES, SUPPORT_STATUSES } from '../../../util/general.util';
import { getSupportFilterDates } from '../../../util/date.util';

const Supports = ({
    role,
    supports,
    getSupportsDataAction,
}) => {

    const [dates, setDates] = useState([]);
    const [filters, setFilters] = useState({
        dateFilter: { value: '', label: 'Select Date' },
        statusFilter: { value: '', label: 'Select Status' },
        categoryFilter: { value: '', label: 'Select Category' },
        roleFilter: { value: '', label: 'Select Role' },
        searchFilter: ''
    });

    const navigate = useNavigate()

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    useEffect(()=>{
        let _dates = getSupportFilterDates(); 
        setDates(_dates)
        getSupportsDataAction(filters);
    },[])


    return(
        <div className="w-full bg-white p-5 overflow-y-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Support Tickets</h1>
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col md:flex-row w-full gap-4" >
                <div className="grid grid-cols-4 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold"> Search filter </label>
                        <input
                            className="w-full py-2 px-4 rounded-lg border"
                            placeholder="Search"
                            onChange={({ target: { value } }) =>
                                setFilters((prevState) => ({
                                ...prevState,
                                searchFilter: value,
                                }))
                            }
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Date filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select date"
                            options={dates}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                dateFilter: value
                            }))}
                            value={filters.dateFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Status filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Model"
                            options={SUPPORT_STATUSES}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                statusFilter: value
                            }))}
                            value={filters.statusFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Category filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Category"
                            options={SUPPORT_CATEGORIES}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                categoryFilter: value
                            }))}
                            value={filters.categoryFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>getSupportsDataAction(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = {
                                dateFilter: { value: '', label: 'Select Date' },
                                statusFilter: { value: '', label: 'Select Status' },
                                categoryFilter: { value: '', label: 'Select Category' },
                                searchFilter: ''
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            getSupportsDataAction(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="">
                <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                            >
                                Created At
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Role
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Category
                            </th>

                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Status
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Actions</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {supports.map((support, index) => {
                            return (
                                <tr key={support._id}>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-transparent",
                                            "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                        )}
                                    >
                                        <div className="font-medium text-primary-2">
                                            {support.createdAt}
                                        </div>

                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {`${support?.user?.firstName} ${support?.user?.lastName}`}
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{support?.user?.role}</div>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {support.category}
                                    </td>

                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {support.status}
                                    </td>

                                    <td className="border-t border-transparent flex relative py-3.5 text-right text-sm font-medium">
                                        {
                                            SupportPermissions.isAuthorized("view", role) &&
                                            <button 
                                                className="border p-2 m-2 rounded-md"
                                                onClick = {()=> navigate(`/supports/view/${support._id}`)} 
                                            >
                                                View
                                            </button>
                                        }
                                        {
                                            SupportPermissions.isAuthorized("edit", role) &&
                                            <button 
                                                className="border border-green-500 text-primary-1 p-2 m-2 rounded-md"
                                                onClick ={ () => navigate(`/supports/edit/${support._id}`)}
                                            >
                                                Edit
                                            </button>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Supports;