import React, { useEffect, useState } from  "react"

import {Link} from 'react-router-dom'
import axios from "axios"
import context from "../../../../context"

const Trips  = ({
    selectedDate
}) =>{

    const [trips, setTrips] = useState([]);
    const fetchTrips = () =>{
        axios.get(`${context.SERVER_URL}/api/trips`)
        .then((res)=>{
            let _trips = res.data.trips; 
            setTrips(_trips);
    
        }).catch((err)=>{
            console.log(err);
        });    
    }

    useEffect(()=>{
        fetchTrips()
    },[])

    const renderTrip = (trip, index) =>{
        const date = new Date(trip.date).toLocaleDateString()

        return (
            <div className="flex flex-col border-2 border-gray-300 text-xs p-3 rounded-lg bg-white shadow" key={index}>

                <div className="flex flex-wrap bg-green-200">
                    <div className="flex flex-col">
                        <label className="text-primary-1 font-bold">Date</label>
                        <div className=" rounded-lg py-3">{date}</div>
                    </div>
                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Time</label>
                        <div className=" rounded-lg py-3">{trip.timeslot}</div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Status</label>
                        <div className=" rounded-lg py-3">{trip.status}</div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Driver</label>
                        <div className=" rounded-lg py-3">{
                            (trip.driver) ?
                                `${trip?.driver?.firstName} ${trip?.driver?.lastName}`
                            :
                                "unassigned"
                            }
                        </div>
                    </div>

                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold">Vehicle</label>
                        <div className=" rounded-lg mt-1">
                            {
                                (trip.vehicle) ?
                                    `${trip?.vehicle?.registrationNumber}-${trip?.vehicle?.car?.make}-${trip?.vehicle?.car?.model}`
                                :
                                    "unassigned"
                            }
                        </div>
                    </div>
                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold py-2"></label>
                        <Link className="flex justify-center items-center py-1 h-10 px-3 mx-2 text-gray-500 border-2 border-gray-500 bg-gray-200 rounded-lg" to={`/trips/view/${trip._id}`}>View</Link>
                    </div>
                    <div className="flex flex-col pl-3">
                        <label className="text-primary-1 font-bold py-2"></label>
                        <Link className="flex justify-center items-center py-1 h-10 px-3 mx-2 text-gray-500 border-2 border-gray-500 bg-gray-200 rounded-lg" to={`/trips/assign/${trip._id}`}>Assign</Link>
                    </div>
                </div>
                <div>
                    <div className="flex flex-row w-full">
                        <div className="w-2/5 px-2 text-primary-1 font-bold">Address:</div>
                        <div className="px w-1/5 text-primary-1 font-bold">Point type:</div>
                        <div className="px w-1/5 text-primary-1 font-bold">Status:</div>
                        <div className="px w-2/5 text-primary-1 font-bold">Capacity:</div>
                    </div>
                    
                    {
                        trip.path.map((point, index)=>{

                            return(
                                <div className="flex flex-row w-full p-3" key={index}>
                                    <div className="w-2/5">{point.address}</div>
                                    <div className="w-1/5">{point.pointType}</div>
                                    <div className="w-1/5">{point.status}</div>
                                    <div className="flex flex-row w-2/5 gap-2">
                                        <div>{`weight: ${point.transitCapacity?.weight}  kg(s)`}</div>
                                        <div>{`length: ${point.transitCapacity?.leng}  m`}</div>
                                        <div>{`width: ${point.transitCapacity?.width}  m`}</div>
                                        <div>{`height: ${point.transitCapacity?.height}  m`}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                
                
            </div>
        )
    }

    return (
        <div className="w-full h-full overflow-auto p-3">
            <h1>Trips</h1>

            <div>
                {
                    trips.map((trip, index)=>renderTrip(trip, index))
                }
            </div>
        </div>
    )
}

export default Trips;