import React,{useEffect, useState} from "react";

import { Link } from "react-router-dom";
import Select from 'react-select'
import axios from "axios";
import context from "../../../context/index"

const NewVehicle = () => {

    const [fleets, setFleets] = useState([]);
    const [cars, setCars] = useState([]);

    const [selectedFleet, setSelectedFleet]= useState('');
    const [selectedCar, setSelectedCar]= useState('');

    const [registrationNumber, setRegistrationNumber] = useState('')
    const [color, setColor] = useState('')


    const fetchFleets = () => {
        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{   
            console.log(response.data)

            const _fleets = response.data.map((fleet)=>{
                return { label:  fleet.name, value: fleet._id }
            });
            setFleets(_fleets);

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchCars = () => {
        axios.get(`${context.SERVER_URL}/api/cars`)
        .then((response)=>{   
            console.log(response)

            const _cars = response.data.map((car)=>{
                return {label:`${car.make}-${car.model}-${car.year}`, value: car._id}
            })
            setCars(_cars);

        }).catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchCars();
        fetchFleets();
    },[])


    const submitVehicle = () => {

        axios.post(`${context.SERVER_URL}/api/vehicles`,
        {
            data:
            {
                registrationNumber,
                car: selectedCar.value,
                color,
                fleet: selectedFleet.value
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Vehicle</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/vehicles"}
                    className = "underline pr-2"
                >vehicles</Link>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4 mb-5">
                <label className="text-primary-1 font-bold">Fleet</label>
                <Select
                    className="w-full mt-2"
                    placeholder="Select Fleet"
                    options={fleets}
                    onChange={(value)=>setSelectedFleet(value)}
                    value={selectedFleet}
                />
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4">
                <label className="text-primary-1 font-bold">Car</label>
                <Select
                    className="w-full mt-2"
                    placeholder="Select Car"
                    options={cars}
                    onChange={(value)=>setSelectedCar(value)}
                    value={selectedCar}
                />
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Registration Number</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="text" 
                    value={registrationNumber} 
                    onChange={(e)=> setRegistrationNumber(e.target.value)}
                    placeholder={"Enter Registraion Number"}
                />
            </div>
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Color</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="text" 
                    value={color} 
                    onChange={(e)=> setColor(e.target.value)}
                    placeholder={"Enter Color"}
                />
            </div>

            <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> submitVehicle()}>Submit</button>
                        
        </div>
    )

}

export default NewVehicle;