import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialBookingState = {
    booking: null,
    bookings:[],
    currentPage: 1,
    totalPages: 10
};


const {
    GET_BOOKINGS_DATA,
    GET_BOOKING_DATA,
    DELETE_BOOKING,
    TRAILER_LOCATION_UPDATE,
    SOCKET_BOOKING_UPDATE,
    SOCKET_BOOKING_POINT_UPDATE
} = actionConstants;

export const getBookingsDataAction = (filters) => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        const {
            searchFilter,
            dateFilter,
            timeslotFilter,
            statusFilter,
            companyFilter,
            pageFilter,
            limitFilter
        } = filters;

        let _date = "";
        if (dateFilter?.value) {
            const date = new Date(dateFilter.value);
            _date = JSON.stringify({
                month: date.getMonth(),
                date: date.getDate(),
                year: date.getFullYear(),
            });
        }

        const params = new URLSearchParams({
            searchFilter: searchFilter || "",
            dateFilter: _date,
            timeslotFilter: timeslotFilter?.value || "",
            statusFilter: statusFilter?.value || "",
            companyFilter: companyFilter?.value || "",
            pageFilter: pageFilter || "",
            limitFilter: limitFilter || "",
        });

        axios.get(`${context.SERVER_URL}/api/bookings?${params.toString()}`)
        .then((res) => {
            const {bookings, currentPage, totalPages}  = res.data;
            dispatch({
                type: GET_BOOKINGS_DATA,
                payload:{
                    bookings,
                    currentPage,
                    totalPages
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching bookings:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const getBookingDataAction = (id) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/bookings/${id}`)
            .then((res) => {
                const trailerLocationCoordinates = res.data?.trip?.trailer?.location[0]?.location?.coordinates;
                dispatch({
                    type: GET_BOOKING_DATA,
                    payload:{
                        booking: {
                            ...res.data,
                            trailerLocationCoordinates
                        }
                    }
                })
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
    
    }    
}

export const deleteBookingAction = (id) =>{
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        axios.delete(`${context.SERVER_URL}/api/bookings/${id}`)
        .then((response) => {
            const bookings = store().bookings.bookings.filter((booking) => booking._id != id);
            dispatch({
                type: DELETE_BOOKING,
                payload:{
                    bookings
                }
            });
        })        
        .catch((error) => {
          console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const trailerLocationUpdateAction = (trailer) =>{
    return (dispatch, store) => {
        dispatch({
            type: TRAILER_LOCATION_UPDATE,
            payload:{
                coordinates:trailer?.location?.coordinates
            }
        })
    }    
}

// sockets
export const socketBookingPointUpdateAction = (point) =>{
    return (dispatch, store) => {
        let booking = store()?.bookings?.booking;
        if (booking?._id == point.booking){

            let path = booking?.path?.map((p)=>{
                if(p?._id == point._id) return point;
                return p;
            });

            dispatch({
                type: SOCKET_BOOKING_POINT_UPDATE,
                payload:{
                    path
                }
            })
        }
    }    
}

export const socketBookingUpdateAction = (booking) =>{
    return (dispatch, store) => {
        dispatch({
            type: SOCKET_BOOKING_UPDATE,
            payload:{
                booking
            }
        })
    }    
}

function handleGetBookingsData (state, action){
    return update(state, {
        bookings:{
            bookings:{ $set: action.payload.bookings },
            currentPage:{ $set: action.payload.currentPage },
            totalPages:{ $set: action.payload.totalPages },
        }
    })
}

function handleDeleteBooking (state, action){
    return update(state, {
        bookings:{
            bookings:{ $set: action.payload.bookings },
        }
    })
}

function handleGetBookingData (state, action){
    return update(state, {
        bookings:{
            booking:{ $set: action.payload.booking },
        }
    })
}

function handleTrailerLocationUpdate (state, action){
    return update(state, {
        bookings:{
            booking:{
                trailerLocationCoordinates: { $set: action.payload.coordinates }
            },
        }
    })
}

function handleSocketBookingUpdate (state, action){
    return update(state, {
        bookings:{
            booking:{
                status: { $set: action.payload.booking?.status },
                trip: { $set: action.payload.booking?.trip },
            },
        }
    })
}

function handleSocketBookingPointUpdate (state, action){
    return update(state, {
        bookings:{
            booking:{
                path: { $set: action.payload.path },
            },
        }
    })
}

export const BOOKINGS_ACTION_HANDLERS = {
    GET_BOOKINGS_DATA: handleGetBookingsData,
    GET_BOOKING_DATA: handleGetBookingData,
    TRAILER_LOCATION_UPDATE: handleTrailerLocationUpdate,
    SOCKET_BOOKING_UPDATE: handleSocketBookingUpdate,
    SOCKET_BOOKING_POINT_UPDATE: handleSocketBookingPointUpdate,
    DELETE_BOOKING: handleDeleteBooking
}