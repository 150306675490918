import {
    Autocomplete,
    useJsApiLoader,
} from "@react-google-maps/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import React,{useState} from 'react'

import context from '../../../context';

const libraries = ["places"];

const RegisterByInvite = ({
    registerByInviteAction
}) => {

    const {token} = useParams();
    const [password, setPassword] = useState(''); //1234
    const [confirmPassword, setConfirmPassword] = useState('');
    const [contact, setContact] = useState(''); 
    const [name, setName] = useState('');
    const [surname, setSurname] = useState(''); 

    const navigate = useNavigate();

    const [showErrorMessage, setShowErrorMessage] = useState({
        show: false,
        message: ""
    })

    const onPasswordChanged = (password) =>{
        setPassword(password.trim());
    }

    const onConfirmPasswordChanged = (password) =>{
        setConfirmPassword(password.trim());
    }

    const onContactChanged = (contact) =>{
        setContact(contact.trim())
    }

    const onNameChanged = (name) =>{
        setName(name.trim())
    }

    const onSurnameChanged = (surname) =>{
        setSurname(surname.trim())
    } 

    const handleSubmit = async () => {

        if (name == '' || surname == '' || password == '' || confirmPassword == '' || contact == '' || token == null) 
        {
            setShowErrorMessage({
                show: true,
                message: "Please complete all fields."
            })

            setTimeout(()=>{
                setShowErrorMessage({
                    show: false,
                    message: ""
                })
            }, 2000)
        }
        else if (password !== confirmPassword)
        {
            setShowErrorMessage({
                show: true,
                message: "Passwords do not match!"
            })

            setTimeout(()=>{
                setShowErrorMessage({
                    show: false,
                    message: ""
                })
            }, 2000)
        }
        else
        {
            const data = {
                token,
                password,
                firstName: name,
                lastName: surname,
                contactNumber: contact            
            }

            registerByInviteAction({data, navigate})
        }
        
    }


    return(
        <div className=" flex flex-col w-full h-full bg-green-50 items-center overflow-y-auto">
            <span className = "flex flex-row text-3xl font-bold underline py-2 text-primary-1"> Wheels 
                <span className="text-orange-custom text-4xl">it</span> 
                Now 
            </span>

            <div  className="flex flex-wrap w-full md:w-2/3 px-10">

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/2">
                    <label className = "font-bold text-primary-1">Name</label>
                    <input 
                        className = "border-2 border-primary-1 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="text" 
                        onChange = {e => onNameChanged(e.target.value) }
                        placeholder = {"Enter name"}
                        value = {name}
                    />
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/2">
                    <label className = "font-bold text-primary-1">Surname</label>
                    <input 
                        className = "border-2 border-primary-1 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="text" 
                        onChange = {e => onSurnameChanged(e.target.value) }
                        placeholder = {"Enter surname"}
                        value = {surname}
                    />
                </div>
                
                <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                    <label className = "font-bold text-primary-1">Contact:</label>
                    <input 
                        className = "border-2 border-neutral-7 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="number"
                        onChange = { e => onContactChanged(e.target.value) } 
                        placeholder = {"Enter contact"}
                        value = {contact}
                    />
                </div>

                <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                    <label className = "font-bold text-primary-1">Password:</label>
                    <input 
                        className = "border-2 border-neutral-7 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="password"
                        onChange = { e => onPasswordChanged(e.target.value) } 
                        placeholder = {"Enter password"}
                        value = {password}
                    />
                </div>

                <div className="w-full md:w-1/2 flex flex-col mt-2 pr-2">
                    <label className = "font-bold text-primary-1">Confirm Password:</label>
                    <input 
                        className = "border-2 border-neutral-7 rounded-lg p-4 text-gray-500 text-lg bg-white"
                        type="password"
                        onChange = { e => onConfirmPasswordChanged(e.target.value) } 
                        placeholder = {"Enter password confirmation"}
                        value = {confirmPassword}
                    />
                </div>

                <button 
                    className="bg-primary-1 rounded-lg p-5 text-white font-semibold w-full mt-10 md:w-1/2 md:mt-2"
                    onClick= {()=>handleSubmit()}
                    disabled = {false}
                >
                    Register
                </button>
                  
            </div>

            {
                (showErrorMessage.show) &&
                <div className="mt-3 underline font-bold text-red-500">{showErrorMessage.message}</div>
            }

            <Link to="/" className="underline font-bold text-orange-custom text-xl mt-10 mb-20 md:mb-0"> Go to login.</Link>

        </div>
    )
}

export default RegisterByInvite;