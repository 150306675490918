import { configureStore, applyMiddleware, compose, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { AppReducer  } from './appReducer';

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, AppReducer)


const log = createLogger ({diff: true, collapsed: true});
const  generateStore = (initalState={}) =>{
    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
              // Ignore these action types
              ignoredActions: ['persist/PERSIST'],
            },
        }).concat(log),
    })
    return store;
}

const store = generateStore({});
const persistor = persistStore(store);

export default{
    store,
    persistor
}
