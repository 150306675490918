import ReduxStorage  from '../../store/store';
import {getSocket} from "../SocketManager";

export const register = async (callback) => {
    try {
        const socket = await getSocket();
        const profile = ReduxStorage.store.getState()?.profile;
        socket.on(`TripNew_${profile.id}`, (data) => {
            callback();
        }) 
    } catch (error) {
        console.log("Error registering new trip receiver: ", error);
    }

}

export const unregister = async () => { 
    try {
        const socket = await getSocket();
        const profile = ReduxStorage.store.getState()?.profile;
        socket.off(`TripNew_${profile.id}`);
    } catch (error) {
        console.log("Error unregistering new trip receiver: ", error);
    }

}