import React,{useEffect, useState, useRef} from "react"
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    DirectionsRenderer,
    MarkerClusterer,
    InfoWindow
} from "@react-google-maps/api";
import * as TrailerLocationUpdateReceiver from "../../../sockets/receivers/TrailerLocationUpdateReceiver";
import { vehicleIcon } from "../../../util/map.util";

const TrailerLocation  = ({
  trailer,
  clusterer
}) =>{

  const [trailerState, setTrailerState] = useState(trailer); 
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const onTrailerLocationUpdate = (data) =>{
    setTrailerState((prevState) => ({
      ...prevState,
      location: data.location
    }))
  }


  useEffect(() => {
      TrailerLocationUpdateReceiver.register(trailer?._id, onTrailerLocationUpdate);
    return () => {
      if (trailer?._id)
        TrailerLocationUpdateReceiver.unregister(trailer?._id);
    }
  }, [])

  return(
    <Marker
      icon={vehicleIcon}
      clusterer={clusterer}
      position={{
        lng: trailerState?.location[0]?.location?.coordinates[0],
        lat: trailerState?.location[0]?.location?.coordinates[1]
      }}
      onClick={()=> setShowInfoWindow(true)}
    >
      {
        showInfoWindow &&
        <InfoWindow
          position={{
            lat: trailerState?.location[0]?.location?.coordinates[0],
            lng: trailerState?.location[0]?.location?.coordinates[1]
          }}
          onCloseClick={() => setShowInfoWindow(false)}
        >
          <div className = { "flex flex-col p-2" }>
            <div>{ trailerState?.registrationNumber}</div>
            <div>{ `${trailerState?.driver?.firstName} ${trailerState?.driver?.lastName}` }</div>
          </div>
        </InfoWindow>
      }
    </Marker>
  )
}

export default TrailerLocation;
