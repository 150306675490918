import Banner from './banner/Banner'
import Features from './features/Features'
import React from 'react'
const Home = () =>{
    
    return(
        <div>
            <Banner />
            <Features />
        </div>
    )
}

export default Home;