import {connect} from 'react-redux'
import NewTrailer from '../components/NewTrailer';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(NewTrailer); 