import {connect} from 'react-redux'
import NewSupport from '../components/NewSupport';
import { createSupportAction } from '../modules/support';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    support: state.supports.support
});

const mapActionCreators = {
    createSupportAction
};

export default connect (mapStateToProps,mapActionCreators)(NewSupport); 