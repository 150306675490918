import { Link } from 'react-router-dom';
import React from "react";

const About = () => {
  return (
    <section className="py-12 bg-white text-black sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-7 lg:gap-x-20">
          <div className="lg:col-span-3 lg:order-last">
            <div className="relative overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src="https://plus.unsplash.com/premium_photo-1664695368915-096285d5a08d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGF1bGFnZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
              />
              <div className="absolute bottom-0 left-0">
                <Link to={"/register"}
                  className="inline-flex items-center px-5 py-3 text-base font-normal bg-black"
                  role="button"
                >
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                    {" "}
                    Get Started{" "}
                  </span>
                  <svg
                    className="w-auto h-5 ml-2"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9"
                      stroke="url(#paint0_linear_523_1470)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_523_1470"
                        x1="2.75"
                        y1="3.75"
                        x2="17.6868"
                        y2="6.52979"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#6DDCFF" />
                        <stop offset="1" stop-color="#7F60F9" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="lg:col-span-4">
            <h2 className="text-3xl font-normal text-black sm:text-4xl lg:text-5xl xl:text-6xl">
              Your Premier Destination for Logistics and Transportation
              Solutions
            </h2>
            <p className="mt-8 text-xl font-normal text-black">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                Connecting Communities through Efficient and{" "}
              </span>
              Reliable Transportation.
            </p>
            <p className="mt-8 text-lg font-normal text-gray-800">
              Welcome to Wheelsit, the ultimate destination for comprehensive
              logistics and transportation solutions. Our unwavering mission is
              to cultivate a thriving ecosystem encompassing users,
              corporations, drivers, and fleets, all united within our
              innovative hub. Through this convergence, we aim to provide
              unparalleled, transparent, secure, and proficient transportation
              services.{" "}
            </p>

            <hr className="mt-8 border-gray-800 sm:mt-12" />

            <ul className="grid grid-cols-1 mt-8 sm:grid-cols-2 sm:mt-12 gap-x-12 gap-y-8 sm:gap-y-12 ">
              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6DDCFF" />
                      <stop offset="1" stop-color="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="ml-4 text-lg font-normal text-gray-800">
                  {" "}
                  In the modern economy, transportation forms the backbone. Our
                  team delivers exceptional customer experiences, meeting
                  diverse transportation needs.
                </span>
              </li>

              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6DDCFF" />
                      <stop offset="1" stop-color="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="ml-4 text-lg font-normal text-gray-800">
                  {" "}
                  Establish alliance of dependable transportation providers.
                  Meticulously engineered platform for secure, efficient
                  services.
                </span>
              </li>

              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6DDCFF" />
                      <stop offset="1" stop-color="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="ml-4 text-lg font-normal text-gray-800">
                  {" "}
                  Access drivers, fleets for users, corporations. Manage
                  shipments, booking options, real-time tracking.{" "}
                </span>
              </li>

              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6DDCFF" />
                      <stop offset="1" stop-color="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="ml-4 text-lg font-normal text-gray-800">
                  {" "}
                  Gateway for drivers, fleets to access trips. Optimize routes,
                  increase earnings.{" "}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
