import * as CarPermissions from '../modules/CarPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../../context'

const EditCar = ({
    role
}) =>{

    const {id} = useParams();
    const [make, setMake]= useState('');
    const [model, setModel]= useState('');
    const [year,setYear] = useState('');


    const fetchCar = () => {
        axios.get(`${context.SERVER_URL}/api/cars/${id}`)
        .then((response)=>{

            const {make, model, year} = response.data;
            
            setMake(make);
            setModel(model);
            setYear(year);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchCar()
    },[])

    const updateCar = () =>{
        axios.put(`${context.SERVER_URL}/api/cars/${id}`,
        {
            data:
            {
                make,
                model,
                year
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    return(
        <div className="w-full p-3 bg-white py-8 px-16  overflow-y-auto">
            <div className='flex justify-between'>
            <h1 className="text-primary-1 font-bold">Edit Car</h1>
            <Link 
                    to = {"/cars"}
                    className = "text-gray-400 hover:text-gray-800 pr-2"
                >Back</Link> 
            </div>
            <div className='border border-gray-400 mt-1'/>

            

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Make</label>
                <input 
                    className="w-full p-5 rounded-lg mt-2 border" 
                    type="text" 
                    value={make} 
                    onChange={(e)=> setMake(e.target.value)}
                    placeholder={"Enter Company Make"}
                />
            </div>
           
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Model</label>
                <input 
                    className="w-full p-5 rounded-lg mt-2 border" 
                    type="text" 
                    value={model} 
                    onChange={(e)=> setModel(e.target.value)}
                    placeholder={"Enter Company Model"}
                />
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Year</label>
                <input 
                    className="w-full p-5 rounded-lg mt-2 border " 
                    type="number" 
                    value={year} 
                    onChange={(e)=> setYear(e.target.value)}
                    placeholder={"Enter Company Make"}
                />
            </div>
        
            <div className=''>
            <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> updateCar()}>Update</button>
            {
                    CarPermissions.isAuthorized("view",role) &&
                    <Link 
                        to = {`/cars/view/${id}`}
                        className = "border px-4 py-2 rounded-md ml-2 text-center hover:text-neutral-7 "
                    >Cancel</Link>
                }
            </div> 
        </div>
    )
}

export default EditCar;