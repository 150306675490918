import {connect} from 'react-redux'
import ViewSupport from '../components/ViewSupport';
import { getSupportDataAction } from '../modules/support';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    support: state.supports.support
});

const mapActionCreators = {
    getSupportDataAction
};

export default connect (mapStateToProps,mapActionCreators)(ViewSupport); 