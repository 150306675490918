import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as CarPermissions from '../modules/CarPermissions';
import * as FleetPermissions from "../../fleets/modules/FleetPermissions";

import React, { useEffect, useState } from "react"

import {Link} from 'react-router-dom'
import Select from 'react-select'
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from "../../../context";
import ReactPaginate from 'react-paginate';

const Cars = ({
    role,
    showLoaderAction,
    showErrorAction
}) => {

    const [cars, setCars] = useState([]);

    const [filters, setFilters] = useState({
        makeFilter: { value: '', label: 'Select Make' },
        modelFilter: { value: '', label: 'Select Model' },
        yearFilter: { value: '', label: 'Select Year' },
        pageFilter: 1,
        limitFilter: 10
    });

    const [filterOptions, setFilterOptions] = useState({
        makes:[],
        models:[],
        years:[]
    });

    const [totalPages, setTotalPages] = useState(100);

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    useEffect(()=>{
        fetchCars(filters);
        fetchCarOptions()
    },[])

    const fetchCarOptions = () =>{
        axios.get(`${context.SERVER_URL}/api/carattributes`)
        .then((response)=>{
            const {makes, models, years} = response.data;
            setFilterOptions((prevState)=>({
                ...prevState,
                makes: makes.map((make)=>({ label: make, value: make })),
                models: models.map((model)=>({ label: model, value: model })),
                years: years.map((year)=>({ label: year, value: year }))
            }));
        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchCars = (filters) => {
        showLoaderAction(true);
        const { makeFilter, modelFilter, yearFilter, pageFilter, limitFilter } = filters;
        axios.get(`${context.SERVER_URL}/api/cars?makeFilter=${makeFilter?.value}&modelFilter=${modelFilter?.value}&yearFilter=${yearFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{
            const { cars, totalPages, currentPape} = response.data;
            setCars(cars);
            setTotalPages(totalPages);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }

    const deleteCar = (id) =>{

        const options = {
            title: 'Delete Car',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/cars/${id}`)
                    .then((response)=>{
                        const _cars = cars.filter((car)=> car._id != id)
                        setCars(_cars);
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);
    }

    return(
        <div className="w-full bg-white p-5 overflow-y-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Cars</h1>
                <Link
                    to = {"/cars/new"}
                >
                    <div className="bg-primary-1 rounded-md text-white px-3 py-2">New Car</div>
                </Link>
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col md:flex-row w-full gap-4" >
                <div className="grid grid-cols-4 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Make filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Make"
                            options={filterOptions.makes}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                makeFilter: value
                            }))}
                            value={filters.makeFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Model filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Model"
                            options={filterOptions.models}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                modelFilter: value
                            }))}
                            value={filters.modelFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Year filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Year"
                            options={filterOptions.years}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                yearFilter: value
                            }))}
                            value={filters.yearFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>fetchCars(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = {
                                makeFilter: { value: '', label: '' },
                                modelFilter: { value: '', label: '' },
                                yearFilter: { value: '', label: '' }
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            fetchCars(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                        >
                            Make
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Model
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Updated At
                        </th>

                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Year
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {cars.map((car, index) => {
                        return (
                            <tr key={car._id}>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-transparent",
                                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    )}
                                >
                                    <div className="font-medium text-primary-2">
                                        {car.make}
                                    </div>

                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    {car.model}
                                </td>

                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    {car.updatedAt}
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{car.year}</div>
                                </td>
                                <td
                                    className="border-t border-transparent flex
                                        relative py-3.5 text-right text-sm font-medium"

                                >


                                    {
                                        CarPermissions.isAuthorized("view", role) &&
                                        <Link to={`/cars/view/${car._id}`}>
                                            <div className="border p-2 m-2 rounded-md">View</div>
                                        </Link>
                                    }
                                    {
                                        CarPermissions.isAuthorized("edit", role) &&
                                        <Link to ={`/cars/edit/${car._id}`}>
                                            <div  className="border border-green-500 text-primary-1 p-2 m-2 rounded-md"> Edit </div>
                                        </Link>
                                    }
                                    {
                                        CarPermissions.isAuthorized("delete", role) &&
                                        <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>deleteCar(car._id)}> Delete </button>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        <div className="flex flex-row w-full items-center justify-end mt-5">
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={({selected}) => {
                    let _filters = {
                    ...filters,
                    pageFilter: selected + 1
                    } 
                    (_filters);
                    setFilters(_filters);
                    fetchCars(_filters);
                }}
                containerClassName={'pagination'}
                pageClassName={'page'}
                pageLinkClassName={'page-link'}
                previousClassName={'previous'}
                nextClassName={'next'}
                activeClassName={'active'}
                disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}
export default Cars;