import React from "react"
import { useNavigate } from "react-router-dom"

const Settings = ({
    setToken
}) => {

    const navigate = useNavigate();

    const logout = () => {
        setToken({token: null})
        navigate("/");
    }

    return(
        <div className="w-full h-full p-3">
            <h1>Settings</h1>

            <button
                onClick={()=> logout()}
                className ="bg-primary-1 rounded-lg py-1 px-3 text-white font-semibold"
            >
                logout
            </button>
            
        </div>
    )
}

export default Settings;