import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialCompanyState = {
    company: null,
    companies:[],
    currentPage: 1,
    totalPages: 10
};

const {
    GET_COMPANIES_DATA,
    GET_COMPANY_DATA,
    DELETE_COMPANY,
} = actionConstants;

export const getCompaniesDataAction = (filters) => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        const {
            searchFilter,
            pageFilter,
            limitFilter
        } = filters;

        const params = new URLSearchParams({
            searchFilter: searchFilter || "",
            pageFilter: pageFilter || "",
            limitFilter: limitFilter || "",
        });

        axios.get(`${context.SERVER_URL}/api/companies?${params.toString()}`)
        .then((res) => {
            const {companies, currentPage, totalPages}  = res.data;
            dispatch({
                type: GET_COMPANIES_DATA,
                payload:{
                    companies,
                    currentPage,
                    totalPages
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching companies:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const getCompanyDataAction = (id) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/companies/${id}`)
            .then((res) => {
                const company = res.data;
                dispatch({
                    type: GET_COMPANY_DATA,
                    payload:{
                        company
                    }
                })
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
    
    }    
}

export const deleteCompanyAction = (id) =>{
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        axios.delete(`${context.SERVER_URL}/api/companies/${id}`)
        .then((response) => {
            const companies = store().companies.companies.filter((company) => company._id != id);
            dispatch({
                type: DELETE_COMPANY,
                payload:{
                    companies
                }
            });
        })        
        .catch((error) => {
          console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

function handleGetCompaniesData (state, action){
    return update(state, {
        companies:{
            companies:{ $set: action.payload.companies },
            currentPage:{ $set: action.payload.currentPage },
            totalPages:{ $set: action.payload.totalPages },
        }
    })
}

function handleDeleteCompany (state, action){
    return update(state, {
        companies:{
            companies:{ $set: action.payload.companies },
        }
    })
}

function handleGetCompanyData (state, action){
    return update(state, {
        companies:{
            company:{ $set: action.payload.company },
        }
    })
}

export const COMPANIES_ACTION_HANDLERS = {
    GET_COMPANIES_DATA: handleGetCompaniesData,
    GET_COMPANY_DATA: handleGetCompanyData,
    DELETE_COMPANY: handleDeleteCompany
}