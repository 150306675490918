import {connect} from 'react-redux'
import EditTrailer from '../components/EditTrailer';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(EditTrailer); 