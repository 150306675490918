import React from "react";

const Services = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <p className="text-base font-semibold text-blue-600">Our Services</p>
          <h2 className="mt-6 text-3xl font-semibold tracking-tight text-primary-2 lg:mt-8 sm:text-4xl lg:text-5xl">
            Elevate Your Logistics with Wheelsit: Seamless Transport Management
          </h2>
        </div>

        <div className="grid max-w-3xl grid-cols-1 gap-5 mx-auto mt-12 sm:mt-16 md:grid-cols-2 sm:gap-6">
          <div className="transition-all duration-200 bg-white border border-gray-200 rounded-2xl hover:bg-gray-50">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-start">
                <svg
                  className="w-6 h-6 text-blue-600 shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="ml-3 text-base font-normal text-primary-2">
                  Wheelsit revolutionizes logistics management for private
                  users, companies, and drivers. Seamlessly book trips, connect
                  with drivers, and track deliveries, all in one platform.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="transition-all duration-200 bg-white border border-gray-200 rounded-2xl hover:bg-gray-50">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-start">
                <svg
                  className="w-6 h-6 text-blue-600 shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="ml-3 text-base font-normal text-primary-2">
                  For private users, cost-efficiently share truck space and
                  track deliveries. Register as a private driver to access
                  various trips.
                </p>
              </div>
            </div>
          </div>

          <div className="transition-all duration-200 bg-white border border-gray-200 rounded-2xl hover:bg-gray-50">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-start">
                <svg
                  className="w-6 h-6 text-blue-600 shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="ml-3 text-base font-normal text-primary-2">
                  Companies benefit from centralized logistics management,
                  booking, fleet access, and order tracking for enhanced supply
                  chain visibility.
                </p>
              </div>
            </div>
          </div>

          <div className="transition-all duration-200 bg-white border border-gray-200 rounded-2xl hover:bg-gray-50">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-start">
                <svg
                  className="w-6 h-6 text-blue-600 shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="ml-3 text-base font-normal text-primary-2">
                  Drivers easily connect with users and companies, becoming part
                  of a thriving transportation community. Access trips and earn
                  by transporting cargo.
                </p>
              </div>
            </div>
          </div>

          <div className="transition-all duration-200 bg-white border border-gray-200 rounded-2xl hover:bg-gray-50">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-start">
                <svg
                  className="w-6 h-6 text-blue-600 shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="ml-3 text-base font-normal text-primary-2">
                  Our commitment to top-notch customer service ensures your
                  satisfaction. Our dedicated team is available 24/7 to address
                  any concerns.
                </p>
              </div>
            </div>
          </div>

          <div className="transition-all duration-200 bg-white border border-gray-200 rounded-2xl hover:bg-gray-50">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-start">
                <svg
                  className="w-6 h-6 text-blue-600 shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="ml-3 text-base font-normal text-primary-2">
                  Discover the ultimate transport management system. Join
                  Wheelsit today for convenient, efficient logistics solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
