import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';

export const initialProfileState = {
    firstName: null,
    lastName: null,
    role: null,
    passport: null,
    company: null,
    dob: null,
    nationalId: null,
    address: null,
    contactNumber: null,
    status: null,
    email: null,
    fleet: null,
    id: null
};


const {
    GET_PROFILE_DATA
} = actionConstants;

export const getProfileDataAction = () =>{
    return (dispatch, store) => {

        const {id} = store().profile;

        axios.get(`${context.SERVER_URL}/api/users/${id}`)
        .then((res)=>{
            const {
                firstName,
                lastName,
                role,
                passport,
                company,
                dob,
                nationalId,
                address,
                contactNumber,
                status,
                email,
                fleet,
                _id
            } = res.data

            dispatch({
                type: GET_PROFILE_DATA,
                payload:{
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    id: _id
                }
            })

        }).catch((err)=>{
            console.log(err)
        });
    }    
}

function handleGetProfileData (state, action){
    return update(state, {
        profile:{
            firstName: { $set: action.payload.firstName },
            lastName: { $set: action.payload.lastName},
            role: { $set: action.payload.role },
            passport: { $set: action.payload.passport },
            company: { $set: action.payload.company },
            dob: { $set: action.payload.dob },
            nationalId: { $set: action.payload.nationalId },
            address: { $set: action.payload.address },
            contactNumber: { $set: action.payload.contactNumber },
            status: { $set: action.payload.status },
            email: { $set: action.payload.email },
            fleet: { $set: action.payload.fleet },
            id: { $set: action.payload.id }
        }
    });
}


export const PROFILE_ACTION_HANDLERS = {
    GET_PROFILE_DATA: handleGetProfileData
}



