import {connect} from 'react-redux'
import Bookings from '../components/Bookings';
import { 
    getBookingsDataAction,
    deleteBookingAction 
} from '../modules/bookings';

const mapStateToProps = (state) =>({
    role:  state.profile.role,
    bookings: state.bookings.bookings,
    currentPage: state.bookings.currentPage,
    totalPages: state.bookings.totalPages
});

const mapActionCreators = {
    getBookingsDataAction,
    deleteBookingAction
};

export default connect (mapStateToProps,mapActionCreators)(Bookings); 