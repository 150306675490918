import React, {useState} from 'react';


const CreatePaymentMethod = ({
    itemName,
    setShowNewPaymentMethodDialog,
    type
}) => {

    const [cardNickname, setCardNickname] = useState('');

    return (
        <div className="flex flex-col items-center absolute w-1/2 h-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-gray-400 self-center top-1/2 left-1/2 rounded-md p-10">
            <div
                className='flex flex-row w-full items-center justify-end w-full'
            >
                <button
                    className='bg-primary-1 text-white rounded-md py-2 px-3'
                    onClick={()=> setShowNewPaymentMethodDialog(false)}
                >
                    Cancel
                </button>
            </div>
            <div
                className='flex flex-col items-center mb-2'
            >
                <label className='text-primary-1 font-bold text-md'>Card Nickname</label>
                <input
                    className='border border-gray-200 mt-2 p-2 rounded-md w-full'
                    placeholder='Enter card nickname'
                    onChange={({target: {value}})=> setCardNickname(value)}
                    value={cardNickname} 
                />
            </div>
            <form action={process.env.REACT_APP_PAYFAST_URL} method="post">
                <input type="hidden" name="merchant_id" value={process.env.REACT_APP_PAYFAST_MERCHANT_ID}/>
                <input type="hidden" name="merchant_key" value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY}/>
                <input type="hidden" name="return_url" value={`${window.location.origin}/payments/paymentMethods`}/>
                <input type="hidden" name="cancel_url" value={`${window.location.origin}/payments/paymentMethods`}/>
                <input type="hidden" name="notify_url" value={`${process.env.REACT_APP_SERVER_URL}/api/payments/subscribe`}/>
                <input type="hidden" name="item_name" value={itemName.toString()}/>
                <input type="hidden" name="amount" value={"0.00"}/>
                <input type="hidden" name="test" value={true}/>
                <input type="hidden" name="subscription_type" value="2"/>

                <input type="hidden" name="custom_str1" value={type}/>
                <input type="hidden" name="custom_str2" value={itemName.toString()}/>
                <input type="hidden" name="custom_str3" value={cardNickname}/>
                <input 
                    className={`${ cardNickname == '' ? "bg-gray-200" : "bg-primary-1" } text-white rounded-md py-2 px-3`}
                    disabled={cardNickname == ''}
                    value='create'
                    type="submit" 
                    target="_blank"
                />
            </form>
        </div>
    )
}
export default CreatePaymentMethod;