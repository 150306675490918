import React,{useState} from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import context from "../../../context/index"

const NewCar = () => {

    const [make, setMake]= useState('');
    const [model, setModel]= useState('');
    const [year,setYear] = useState('');

    const submitCar = () => {

        axios.post(`${context.SERVER_URL}/api/cars`,
        {
            data:
            {
                make,
                model,
                year
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Car</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/cars"}
                    className = "underline pr-2"
                >cars</Link>
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Make</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="text" 
                    value={make} 
                    onChange={(e)=> setMake(e.target.value)}
                    placeholder={"Enter Make"}
                />
            </div>
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Model</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="text" 
                    value={model} 
                    onChange={(e)=> setModel(e.target.value)}
                    placeholder={"Enter Make"}
                />
            </div>
            
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Year</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="number" 
                    value={year} 
                    onChange={(e)=> setYear(e.target.value)}
                    placeholder={"Enter Make"}
                />
            </div>

            <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> submitCar()}>Submit</button>
                        
        </div>
    )

}

export default NewCar;