import React, { createRef, useEffect, useState } from 'react'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from 'react-modal'
import { PACKAGING_TYPE } from '../../../util/general.util';
import Select from 'react-select'

const style = {
    content: {
        top: 50 + "%",
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        justifyContent: "center",
        alignItems: "center",
        width: 50 + "%",
        height: 50 + "%",
        borderRadius: 10,
    },

}

const BookingProductsDistribution = ({
    products,
    setExpanded,
    point,
    onSetPointCapacity,
    pointIndex,
    isDestination,
    productCategories,
    points
}) => {

    const [productOptions, setProductOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [newProducts, setNewProducts] = useState([]);

    useEffect(() => {
        let _productOptions = products?.map((product) => ({
            label: product.description,
            value: product._id,
            product,
            quantity: ''
        }));

        // check if droff off point
        if (point.type.value == "destination") {
            // get all product descriptions pickuped at before this point
            let pastPoints = points.slice(0, pointIndex);
            let addedProducts = {};
            let _filteredProductOptions = [];

            pastPoints.forEach((point) => {
                if (point.type.value == "location") {
                    point.capacity.forEach((product) => {
                        if (!addedProducts[product.description]) {
                            addedProducts[product.description] = true;
                            let _foundProduct = _productOptions.find((option) => option.product.description == product.description);
                            if (_foundProduct) {
                                _filteredProductOptions.push(_foundProduct);
                            }
                        }
                    })
                }
            })

            setProductOptions(_filteredProductOptions);

        } else {
            setProductOptions(_productOptions);
        }

        let _selectedProducts = point.capacity.map((product) => ({
            label: product.description,
            value: product._id,
            product,
            quantity: product.quantity
        }));

        setSelectedProducts(_selectedProducts)

    }, [])

    const onQuantityChanged = (value, index) => {
        let _selectedProducts = [...selectedProducts];
        _selectedProducts[index]["quantity"] = value;
        setSelectedProducts(_selectedProducts)
    }


    const renderSelectedProduct = (item, index) => {
        const { product, quantity } = item;
        return (
            <div className='flex flex-row w-full bg-gray-100 mb-2 items-center justify-between px-2 rounded-lg' key={index}>
                <div className='flex flex-row w-1/2'>
                    <div
                        className='w-1/2'
                    >{product?.description}</div>
                    <input
                        className='w-1/2 border border-black'
                        placeholder='Enter quantity'
                        type='number'
                        value={quantity}
                        onChange={({ target: { value } }) => onQuantityChanged(value, index)}
                        disabled={isDestination}
                    />
                </div>

                <button
                    onClick={() => onRemoveProduct(item)}
                    disabled={isDestination}
                >
                    <CancelIcon />
                </button>
            </div>
        )
    }

    const onRemoveProduct = (removedProduct) => {

        let _filteredProducts = selectedProducts.filter((product) => product.value != removedProduct.value);
        setSelectedProducts(_filteredProducts);
    }

    const onNewProductChanged = (index, key, value) => {
        let _newProducts = [...newProducts];
        _newProducts[index]["product"][key] = value;
        setNewProducts(_newProducts);
    }
    const onNewProductQuantityChanged = (index, value) => {
        let _newProducts = [...newProducts];
        _newProducts[index]["quantity"] = value;
        setNewProducts(_newProducts);
    }

    const addNewProduct = () => {

        setNewProducts((prev) => [
            ...prev,
            {
                product: {
                    _id: Math.random().toString(36).substring(7),
                    new: true,
                    description: "",
                    leng: "",
                    width: "",
                    height: "",
                    weight: "",
                    category: { label: "", value: "" },
                    packaging: { label: "Unit", value: "unit" }
                },
                quantity: "",
            }
        ])
    }

    const removeNewProduct = (index) => {
        let _newProducts = [...newProducts];
        _newProducts.splice(index, 1);
        setNewProducts(_newProducts);
    }

    const renderNewProduct = (item, index) => {
        const { product, quantity } = item;
        return (
            <div className='grid grid-cols-9 w-full gap-4' key={index}>
                <div className='col-span-1 h-full'>
                    <input
                        className='w-full p-1.5 border border-gray-300'
                        type='text'
                        placeholder='Description'
                        value={product.description}
                        onChange={({ target: { value } }) => onNewProductChanged(index, "description", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border border-gray-300'
                        type='number'
                        placeholder='length'
                        value={product.leng}
                        onChange={({ target: { value } }) => onNewProductChanged(index, "leng", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border border-gray-300'
                        type='number'
                        placeholder='Width'
                        value={product.width}
                        onChange={({ target: { value } }) => onNewProductChanged(index, "width", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border border-gray-300'
                        type='number'
                        placeholder='Height'
                        value={product.height}
                        onChange={({ target: { value } }) => onNewProductChanged(index, "height", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border border-gray-300'
                        type='number'
                        placeholder='Weight'
                        value={product.weight}
                        onChange={({ target: { value } }) => onNewProductChanged(index, "weight", value)}
                    />
                </div>
                <div className='col-span-1 z-150'>
                    <Select
                        className="w-full"
                        placeholder="Category"
                        options={productCategories}
                        onChange={(value) => onNewProductChanged(index, "category", value)}
                        value={product.category}
                    />
                </div>
                <div className="col-span-1">
                    <Select
                        className="w-full"
                        placeholder="Packaging"
                        options={PACKAGING_TYPE}
                        onChange={(value) => onNewProductChanged(index, "packaging", value)}
                        value={product.packaging}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border border-gray-300'
                        placeholder='Enter quantity'
                        type='number'
                        value={quantity}
                        onChange={({ target: { value } }) => onNewProductQuantityChanged(index, value)}
                        disabled={isDestination}
                    />
                </div>

                <div className='col-span-1'>
                    <button
                        className='w-full h-full'
                        onClick={() => removeNewProduct(index)}
                    >
                        <CancelIcon fontSize='large' />
                    </button>
                </div>
            </div>
        )
    }

    const getFormatNewProducts = () => newProducts.map((item) => {
        item.product.category = {
            _id: item.product.category.value,
            name: item.product.category.label
        }
        item.product.packaging = item.product.packaging.value;
        return item;
    });

    return (
        <div className='flex flex-col w-full h-full items-center justify-between'>
            <div className='w-full mt-8'>
                <Select
                    isSearchable={true}
                    options={productOptions}
                    isMulti={true}
                    className='w-full mb-5'
                    placeholder={"Select product for this stop"}
                    onChange={(value) => setSelectedProducts(value)}
                    value={selectedProducts}
                    isDisabled={isDestination}
                />
                <div className='w-full overflow-y-auto'>
                    {
                        selectedProducts.map((product, index) => renderSelectedProduct(product, index))
                    }
                    {
                        newProducts.map((product, index) => renderNewProduct(product, index))
                    }

                    {
                        <div className='flex flex-row w-full items-center justify-end'>
                            <button
                                className='w-fit'
                                onClick={() => addNewProduct()}
                            >
                                <AddCircleIcon fontSize='large' />
                            </button>
                        </div>
                    }
                </div>


            </div>
            <div className='flex flex-row w-full items-center justify-between py-4'>
                <button
                    className="bg-danger-2 rounded-md text-white px-3 py-2"
                    onClick={() => setExpanded(false)}
                >Cancel</button>



                <button
                    className={`px-2 py-1 border rounded-lg text-gray-500 ${[...selectedProducts, ...newProducts].length == 0 || isDestination
                            ? "bg-gray-200 border border-gray-500"
                            : "  px-3 py-2 animate-pulseBorder border-3"
                        }`} onClick={() => {
                            onSetPointCapacity(pointIndex, [...selectedProducts, ...getFormatNewProducts()]);
                            setExpanded(false);
                        }}
                    disabled={([...selectedProducts, ...newProducts].length == 0 || isDestination) ? true : false}
                >Update</button>
            </div>
        </div>
    )

}
export default BookingProductsDistribution;
