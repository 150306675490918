import {connect} from 'react-redux'
import Vehicles from '../components/Vehicles';
import { showLoaderAction } from '../../common/modules/modal';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
    showLoaderAction
};

export default connect (mapStateToProps,mapActionCreators)(Vehicles); 