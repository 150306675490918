import {connect} from 'react-redux'
import Login from '../components/Login';
import { 
    loginAction,
    logoutAction
} from '../modules/login'

const mapStateToProps = (state) =>({

});

const mapActionCreators = {
    loginAction,
    logoutAction
};

export default connect (mapStateToProps,mapActionCreators)(Login); 