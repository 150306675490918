import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useParams, useNavigate } from 'react-router-dom';
import context from '../../../context';

const NewPayments = () => {
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    const { id } = useParams();
    const [cvv, setCvv] = useState('123');
    const [signature, setSignature] = useState(null);
    const [booking, setBooking] = useState(null);
    const navigate = useNavigate();

    const getBooking = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/bookings/${id}`);
            setBooking(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const fetchPaymentMethods = () => {
        axios.get(`${context.SERVER_URL}/api/paymentMethods`)
            .then((response) => {
                let paymentOptions = response.data.map((paymentMethod) => ({
                    label: paymentMethod.cardNickname,
                    value: paymentMethod.token
                }));
                setPaymentMethodOptions(paymentOptions);
            }).catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getBooking();
        fetchPaymentMethods();
    }, []);

    const getSignature = async () => {
        let _signature = await generateAPISignature({
            'merchant-id': process.env.REACT_APP_PAYFAST_MERCHANT_ID,
            'version': process.env.REACT_APP_PAYFAST_VERSION,
            'timestamp': new Date().toISOString(),
        }, process.env.REACT_APP_PAYFAST_PASSPHRASE);

        setSignature(_signature);
    };

    useEffect(() => {
        //getSignature();
    }, []);

    const getMD5Hash = (getString) => {
        return CryptoJS.MD5(getString).toString(CryptoJS.enc.Hex);
    };

    const generateAPISignature = (data, passPhrase = null) => {
        let ordered_data = {};
        Object.keys(data).sort().forEach(key => {
            ordered_data[key] = data[key];
        });
        data = ordered_data;

        let getString = '';
        for (let key in data) {
            getString += key + '=' + encodeURIComponent(data[key]).replace(/%20/g, '+') + '&';
        }

        getString = getString.substring(0, getString.length - 1);
        if (passPhrase !== null) { getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`; }

        return getMD5Hash(getString);
    };

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    if (booking == null || paymentMethodOptions.length == 0) {
        return (
            <div className="w-full h-full p-5 overflow-y-auto bg-white text-black">
                <div className="w-full flex flex-row justify-between items-center">
                    <div className="text-2xl font-bold">Payment</div>
                </div>
                <div className="w-full flex flex-col justify-center items-center mt-5 text-lg font-bold">
                    <div>You have no set payment methods.</div>
                    <div className="mt-2">
                        If you are a Company Admin or Logistics Specialist,
                        please go to your company's edit page to manage billing methods.
                    </div>
                    <div className="mt-2">
                        If you are an Admin or Regular User,
                        please go to your profile and manage your billing methods.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-full p-5 overflow-y-auto bg-white text-black">
            <div className="w-full flex flex-row justify-between items-center">
                <div className="text-2xl font-bold">Payment</div>
            </div>
            <div className="w-full flex flex-row justify-between items-center mt-5">
                <Select
                    className="w-full mt-2 text-black"
                    placeholder="Select Payment Method"
                    options={paymentMethodOptions}
                    onChange={(value) => setSelectedPaymentMethod(value)}
                    value={selectedPaymentMethod}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: 'white',
                            borderColor: 'black',
                            color: 'black',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: 'white',
                            color: 'black',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? 'black' : 'white',
                            color: state.isSelected ? 'white' : 'black',
                        }),
                    }}
                />
            </div>
            <div className='flex flex-row w-full gap-4 items-center mt-5'>
                <button
                    className="flex items-center justify-center px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
                    onClick={() => navigate(`/bookings/edit/${id}`, {
                        state: {
                            stage: 2,
                        }
                    })}
                >
                    Back
                </button>
                <form action={process.env.REACT_APP_PAYFAST_URL} method="post" className="flex-1">
                    <input type="hidden" name="merchant_id" value={process.env.REACT_APP_PAYFAST_MERCHANT_ID} />
                    <input type="hidden" name="merchant_key" value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY} />
                    <input type="hidden" name="m_payment_id" value={booking?._id} />
                    <input type="hidden" name="return_url" value={`${window.location.origin}/payments/view/${booking?._id}`} />
                    <input type="hidden" name="cancel_url" value={`${window.location.origin}/payments/new/${booking?._id}`} />
                    <input type="hidden" name="notify_url" value={`${process.env.REACT_APP_SERVER_URL}/api/payments/confirm`} />
                    <input type="hidden" name="amount" value={booking?.fare} />
                    <input type="hidden" name="item_name" value={booking?._id} />
                    <input type="hidden" name="test" value={true} />
                    <input
                        className={`${selectedPaymentMethod == null ? "bg-gray-300" : "bg-black"} text-white rounded-md py-2 px-3 cursor-pointer hover:bg-gray-800`}
                        disabled={selectedPaymentMethod == null}
                        type="submit"
                    />
                </form>
            </div>
        </div>
    );
};

export default NewPayments;
