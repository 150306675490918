import {connect} from 'react-redux'
import Register from '../components/Register';
import { 
    registerAction
} from '../modules/register'
import { showErrorAction } from '../../common/modules/modal';

const mapStateToProps = (state) =>({

});

const mapActionCreators = {
    registerAction,
    showErrorAction 
};

export default connect (mapStateToProps,mapActionCreators)(Register); 