import React, { useEffect, useState } from  "react"
import Select from 'react-select'
import axios from "axios"
import context from "../../../context";
import { AVAILABILITY_OPTIONS } from "../../../util/general.util";
import Map from  "./Map"

const Tracking  = ({
    role,
    trailers,
    getTrackingTrailersDataAction
}) =>{

    const [filters, setFilters] = useState({
        locationFilter: null, 
        radiusFilter: 10000,
        limitFilter: null,
        searchFilter: '',
        fleetFilter: { value: '', label: 'Select Fleet' },
        cargoTypeFilter: { value: '', label: 'Select Cargo' },
        trailerTypeFilter: { value: '', label: 'Select Trailer' },
        availabilityFilter: { value: '', label: 'Select Availability' }
    });

    const [filterOptions, setFilterOptions] = useState({
        fleets:[],
        cargoTypes: [],
        trailerTypes: [],
        availability: AVAILABILITY_OPTIONS,
    })

    const fetchFleets = () => {
        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{   
            const fleets = response.data.fleets.map((fleet) => ({ label: fleet.name, value: fleet._id }));
            setFilterOptions((prevState)=>({
                ...prevState,
                fleets
            }));

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchCargoTypes = () => {
        axios.get(`${context.SERVER_URL}/api/cargoTypes`)
        .then((response)=>{   
            const cargoTypes = response.data.map((cargoType)=>{
                return { label:  cargoType, value: cargoType }
            });
            setFilterOptions((prevState)=>({
                ...prevState,
                cargoTypes
            }));

        }).catch((error)=>{
            console.log(error);
        })
    }


    const fetchTrailerTypes = () => {
        axios.get(`${context.SERVER_URL}/api/trailerTypes`)
        .then((response)=>{   
            const trailerTypes = response.data.map((trailerType)=>{
                return { label:  trailerType, value: trailerType }
            });
            setFilterOptions((prevState)=>({
                ...prevState,
                trailerTypes
            }));
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            // showLoaderAction(false)
        })
    }

    const resetFilters = () =>{
        let resetState = {
            searchFilter: '',
            fleetFilter: { value: '', label: 'Select Fleet' },
            cargoTypeFilter: { value: '', label: 'Select Cargo' },
            trailerTypeFilter: { value: '', label: 'Select Trailer' },
            availabilityFilter: { value: '', label: 'Select Availability' },
            locationFilter: null, 
            radiusFilter: 10000,
            limitFilter: null,
        }
        setFilterOptions(resetState);
        getTrackingTrailersDataAction(resetState);
    }

    useEffect(()=>{
        getTrackingTrailersDataAction(filters);
        fetchFleets();
        fetchCargoTypes();
        fetchTrailerTypes();
    },[])

    return (
        <div className="w-full bg-white overflow-auto p-3 h-full">
            <h1 className="text-primary-1 font-bold mb-2">Tracking</h1>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col w-full md:flex-row gap-4 mb-5" >
                <div className="grid grid-cols-5 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Search filter</label>
                        <input 
                            className="w-full py-2 px-4 rounded-lg border"
                            placeholder="Search"
                            onChange={({target:{value}}) => setFilters((prevState)=>({
                                ...prevState,
                                searchFilter: value
                            }))}
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Trailer type filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Trailer Type"
                            options={filterOptions.trailerTypes}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                trailerTypeFilter: value
                            }))}
                            value={filters.trailerTypeFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Cargo type filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Year"
                            options={filterOptions.cargoTypes}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                cargoTypeFilter: value
                            }))}
                            value={filters.cargoTypeFilter}
                        />
                    </div>

                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Availability filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Availability"
                            options={filterOptions.availability}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                availabilityFilter: value
                            }))}
                            value={filters.availabilityFilter}
                        />
                    </div>
                    
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Fleet filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Fleet"
                            options={filterOptions.fleets}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                fleetFilter: value
                            }))}
                            value={filters.fleetFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>getTrackingTrailersDataAction(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            resetFilters();
                        }}
                    > Reset</button>
                </div>
            </div>
            <Map
                trailers={trailers}
            />
        </div>
    )
}

export default Tracking;