import "react-confirm-alert/src/react-confirm-alert.css"; //
import "./rangeSlider.css";

import * as BillingsPermissions from "../modules/BillingPermissions";

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { RangeSlider } from "rsuite";
import Select from "react-select";
import WeightBillingRange from "./WeightBillingRange";

const Billings = ({
  role,
  billings,
  billingCountries,
  getBillingsAction,
  deleteWeightRangeAction,
}) => {
  const [selectedCountry, setSelectedCountry] = useState({
    label: "",
    value: "",
  });
  const [rangeFilterd, setRangeFilterd] = useState([]);
  const [activateRange, setActivateRange] = useState(false);

  useEffect(() => {
    getBillingsAction();
  }, []);

  const renderSelectedCountryBilling = () => {
    let [_filteredCountry] = billings.filter(
      ({ _id: { country } }) => country == selectedCountry.value
    );

    function findMinMaxWeights(data) {
      if (!data || data.length === 0) {
        return null;
      }
    
      let minWeightFrom = Infinity;
      let maxWeightTo = -Infinity;
    
      for (const item of data) {
        if (item.weightFrom < minWeightFrom) {
          minWeightFrom = item.weightFrom;
        }
        if (item.weightTo > maxWeightTo) {
          maxWeightTo = item.weightTo;
        }
      }
    
      return {
        minWeightFrom,
        maxWeightTo,
      };
    }

    const { minWeightFrom, maxWeightTo } = findMinMaxWeights(
      _filteredCountry.billings
    );

    

    function filterByWeightRange(min, max, data) {
      const result = [];

      // Check if the 'data' object has a 'billings' array
      if (data.billings && Array.isArray(data.billings)) {
        const billings = data.billings;

        console.log(billings.length);

        // Filter the 'billings' array to include items within the weight range
        const filteredBillings = billings.filter((billing) => {
          return (
            (min >= billing.weightFrom && min < billing.weightTo) || // min is within the range
            (max >= billing.weightFrom && max < billing.weightTo) || // max is within the range
            (billing.weightFrom >= min && billing.weightTo <= max) || // range is within the min and max
            (min <= billing.weightFrom && max >= billing.weightTo) // range is within the min and max
          );
        });

        // If there are filtered billings, add them to the result array
        if (filteredBillings.length > 0) {
          result.push(...filteredBillings);
        }
      }

      return result;
    }

    return (
      <div className="my-6">
        <RangeSlider
          onChangeCommitted={(value, event) => {
            const rangeData = filterByWeightRange(
              value[0],
              value[1],
              _filteredCountry
            );
            setActivateRange(true);
            console.log(_filteredCountry?.length);
            console.log(rangeData.length);
            setRangeFilterd(rangeData);
          }}
          min={minWeightFrom}
          max={maxWeightTo}
          // getAriaValueText={(value) => console.log(value)}
          // defaultValue={[minWeightFrom, 500]}
        />
        <p className="pt-3">Move the range to filter billings</p>

        <div className=" grid grid-cols-4 gap-4 pt-6">
          {activateRange && rangeFilterd.map((weightRange, index) => {
            const { weightFrom, weightTo, weightBillings, _id } = weightRange;

            return (
              <WeightBillingRange
                weightFrom={weightFrom}
                weightTo={weightTo}
                weightBillings={weightBillings}
                country="{_filteredCountry.country}"
                weightRangeId={_id}
                deleteWeightRangeAction={deleteWeightRangeAction}
              />
            );
          })}

          {!activateRange && _filteredCountry.billings.map((weightRange, index) => {
            const { weightFrom, weightTo, weightBillings, _id } = weightRange;

            return (
              <WeightBillingRange
                weightFrom={weightFrom}
                weightTo={weightTo}
                weightBillings={weightBillings}
                country="{_filteredCountry.country}"
                weightRangeId={_id}
                deleteWeightRangeAction={deleteWeightRangeAction}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full bg-white p-5 overflow-y-auto">
      <div className="flex w-full justify-between mb-2 items-center">
        <h1 className="text-primary-1 cf-headings ">Billings</h1>
        <Link to={"/billings/new"}>
          <div className="bg-primary-1 rounded-md text-white px-3 py-2">
            New Billing
          </div>
        </Link>
      </div>
      <div className="border border-gray-400 mt-1 mb-10" />
      <div className="App"></div>

      <div className="flex justify-between">
        <h1 className="pb-4 w-full text-2xl text-gray-600">
          Please select Billing Country
        </h1>
        <div className="w-full flex justify-end">
          <Select
            className="w-2/3 text-black"
            placeholder="Select Country"
            options={billingCountries}
            onChange={(value) => setSelectedCountry(value)}
            value={selectedCountry}
          />
        </div>
      </div>

      {selectedCountry.value &&
        selectedCountry.value != "" &&
        renderSelectedCountryBilling()}
    </div>
  );
};
export default Billings;
