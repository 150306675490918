import moment from "moment"

export const getDates = () => {
    const today = moment();
    const endOfTwoWeeks = moment().add(2, 'weeks').clone().endOf('isoWeek');
    const days = [];

    for (let i = 0; i <= endOfTwoWeeks.diff(today, 'days'); i++) {
        const value = moment(today).add(i, 'days').format('YYYY-MM-DD');
        days.push({ value, label: moment(value, 'YYYY-MM-DD').format('D MMM YYYY (ddd)') });
    }

    return days;
};

export const getSupportFilterDates = () => {
    const endOfTwoWeeks = moment().add(2, 'weeks').clone().endOf('isoWeek');
    const startOfLastTwoWeeks = moment().subtract(2, 'weeks').clone().startOf('isoWeek')
    const days = [];

    for (let i = 0; i <= endOfTwoWeeks.diff(startOfLastTwoWeeks, 'days'); i++) {
        const value = moment(startOfLastTwoWeeks).add(i, 'days').format('YYYY-MM-DD');
        days.push({ value, label: moment(value, 'YYYY-MM-DD').format('D MMM YYYY (ddd)') });
    }

    return days;
};


export const TIMESLOTS = [

    { value: "12:00AM", label: "12:00AM"},
    { value: "12:30AM", label: "12:30AM"},
    { value: "01:00AM", label: "01:00AM"},
    { value: "01:30AM", label: "01:30AM"},
    { value: "02:00AM", label: "02:00AM"},
    { value: "02:30AM", label: "02:30AM"},
    { value: "03:00AM", label: "03:00AM"},
    { value: "03:30AM", label: "03:30AM"},
    { value: "04:00AM", label: "04:00AM"},
    { value: "04:30AM", label: "04:30AM"},
    { value: "05:00AM", label: "05:00AM"},
    { value: "05:30AM", label: "05:30AM"},
    { value: "06:00AM", label: "06:00AM"},
    { value: "06:30AM", label: "06:30AM"},
    { value: "07:00AM", label: "07:00AM"},
    { value: "07:30AM", label: "07:30AM"},
    { value: "08:00AM", label: "08:00AM"},
    { value: "08:30AM", label: "08:30AM"},
    { value: "09:00AM", label: "09:00AM"},
    { value: "09:30AM", label: "09:30AM"},
    { value: "10:00AM", label: "10:00AM"},
    { value: "10:30AM", label: "10:30AM"},
    { value: "11:00AM", label: "11:00AM"},
    { value: "11:30AM", label: "11:30AM"},
    { value: "12:00PM", label: "12:00PM"},
    { value: "12:30PM", label: "12:30PM"},
    { value: "13:00PM", label: "13:00PM"},
    { value: "13:30PM", label: "13:30PM"},
    { value: "14:00PM", label: "14:00PM"},
    { value: "14:30PM", label: "14:30PM"},
    { value: "15:00PM", label: "15:00PM"},
    { value: "15:30PM", label: "15:30PM"},
    { value: "16:00PM", label: "16:00PM"},
    { value: "16:30PM", label: "16:30PM"},
    { value: "17:00PM", label: "17:00PM"},
    { value: "17:30PM", label: "17:30PM"},
    { value: "18:00PM", label: "18:00PM"},
    { value: "18:30PM", label: "18:30PM"},
    { value: "19:00PM", label: "19:00PM"},
    { value: "19:30PM", label: "19:30PM"},
    { value: "20:00PM", label: "20:00PM"},
    { value: "20:30PM", label: "20:30PM"},
    { value: "21:00PM", label: "21:00PM"},
    { value: "21:30PM", label: "21:30PM"},
    { value: "22:00PM", label: "22:00PM"},
    { value: "22:30PM", label: "22:30PM"},
    { value: "23:00PM", label: "23:00PM"},
    { value: "23:30PM", label: "23:30PM"}

]

export const MILES = [
    { value: "first", label: "First"},
    { value: "middle", label: "Middle"},
    { value: "last", label: "Last"}
]

export const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const getDaysOfMonth = (year, month) => {
    const numDays = new Date(year, month + 1 , 0).getDate();
    return Array.from({length: (numDays)}, (_, i) => i + 1)
}

export const SHARE_TYPES = [
    { label: "Dedicated", value: "dedicated" },
    { label: "Shared", value: "shared" }
];

export const BOOKING_TYPES = [
    { label: "On demand", value: "ondemand" },
    { label: "Scheduled", value: "scheduled" }
];

export const PATH_TYPES = [
    { label: "Direct", value: "direct" },
    { label: "Connecting", value: "connecting" }
];