import {connect} from 'react-redux'
import Cars from '../components/Cars';
import { showLoaderAction, showErrorAction } from '../../common/modules/modal';

const mapStateToProps = (state) =>({
    role:  state.profile.role
});

const mapActionCreators = {
    showLoaderAction,
    showErrorAction
};

export default connect (mapStateToProps,mapActionCreators)(Cars); 