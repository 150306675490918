import React, { useEffect } from 'react'
import { getDaysOfMonth, MONTHS } from '../../../util/date.util'


const Dates = ({
    selectedDate,
    setSelectedDate
}) => {

    const renderDate = (date, index) => {

        return(

            <button 
                key={index} 
                className={
                    (selectedDate.date == date) ?
                        "flex w-full bg-white p-2 my-1 text-xs text-gray-500 rounded-lg border-2 border-gray-500 justify-center items-center"
                    :   
                        "flex w-full bg-white p-2 my-1 text-xs text-gray-500 rounded-lg justify-center items-center"
                }
                onClick={()=>setSelectedDate({...selectedDate,date})}
            >{date}</button>
        )

    }

    return(
        <div className="flex flex-col w-fit border-2 border-gray-700 rounded-lg p-2 overflow-y-scroll">
            {
                ['All',...getDaysOfMonth(selectedDate.year, selectedDate.month)].map((date, index)=>{
                    return renderDate(date, index)
                })
            }
        </div>
    )
}

export default Dates;