// import BookingModal from "./BookingModal";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { images } from "../../../../assets/index";
import ScrollLink from "../../../../util/ScrollLink"
import BookingModal from "../../../../util/BookingModal";

const MenuItems = [
  {
    label: "Home",
    value: "Home",
  },
  {
    label: "About",
    value: "About",
  },
  {
    label: "Services",
    value: "Services",
  },
  {
    label: "Features",
    value: "Features",
  },
  /*{
        label: "Prices", value: "Prices"
    },*/
];
const Header = ({ landingState, setLandingState }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onPageChanged = (selectedPage) => {
    setLandingState((prevState) => ({
      ...prevState,
      currentPage: selectedPage,
    }));
  };

  const handleJoinNowClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    const element = document.querySelector("#contact-form");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-neutral-1 ">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <div className="flex justify-center items-center">
              <img className="w-14 my-4" src={images.Logo} alt="Workflow" />
              <h1 className="text-2xl font-semibold leading-none text-primary-1 lg:text-xl pl-1">Wheelsitnow</h1>
            </div>
          </div>

          <button
            type="button"
            className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
          >
            <svg
              className="block w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 8h16M4 16h16"
              ></path>
            </svg>

            <svg
              className="hidden w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>

          <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
            {MenuItems.map((item, index) => (
              <button
                key={index}
                className={`${landingState.currentPage = item.value
                  ? " text-shade-2"
                  : "text-base text-black transition-all duration-200 hover:text-opacity-80"
                  } `}
                onClick={() => onPageChanged(item.value)}
              >
                {item.label}
              </button>
            ))}
          </div>

          {/* <div className="space-x-2"> */}
          {/* <ScrollLink
              to="#contact-form"
              className="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-yellow-300 hover:text-black no-underline hover:no-underline focus:text-black focus:bg-yellow-300 font-semibold text-white bg-black rounded-full"
            >
              Join Now
            </ScrollLink> */}
          {/* <Link
              to={"/register"}
              className="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-black hover:no-underline hover:text-white focus:text-black  font-semibold text-black border-2 border-black rounded-full"
              role="button"
            >
              {" "}
              Sign Up{" "}
            </Link> */}
          {/* </div> */}

          <div className="space-x-2">

          <p className=" text-gray-600">
                <Link
                className="border border-gray-600 rounded-md px-4 py-2 hover:no-underline text-gray-600 hover:bg-gray-600 hover:text-white"
              to={'/login'}
                >
                  Log in
                </Link>
              </p>
            {/* ... (keep the sign-up link if needed) */}
          </div>

          <BookingModal isOpen={isModalOpen} onClose={handleModalClose} />
        </div>
      </div>
    </header>
  );
};

export default Header;
