import React from 'react'

const Bookings = ({
    groupingState
}) =>{

    const renderBooking = (booking, index) =>{
        var options = { weekday: 'long', day: 'numeric' };
        const date = new Date(booking.date).toLocaleString("en-US",options)
        return(
            <div className="flex flex-col w-full border-b-2 border-gray-300 text-xs rounded-lg bg-white shadow p-2 mt-2" key={index}>
                <div className="flex flex-wrap w-full bg-green-200 ">
                    <div className="flex flex-col p-2">
                        <label>Company</label>
                        <div>{booking.company.name}</div>
                    </div>
                    <div className="flex flex-col p-2">
                        <label> Date</label>
                        <div className="">{date}</div>
                    </div>
                    <div className="flex flex-col p-2">
                        <label>Timeslot</label>
                        <div className="">{booking.timeslot}</div>
                    </div>
                    <div className="flex flex-col p-2">
                        <label>Cargo Type</label>
                        <div className="">{booking.cargoType}</div>
                    </div>
                    <div className="flex flex-col p-2">
                        <label>Distance</label>
                        <div className="">{`${booking.distance} km`}</div>
                    </div>

                    <div className="flex flex-col p-2">
                        <label>Fare</label>
                        <div className="">{booking.fare}</div>
                    </div>
                    
                    <div className="flex flex-col p-2">
                        <label>Status</label>
                        <div className="">{booking.status}</div>
                    </div>
                </div>
                <div className="flex flex-col w-full p-3">
                    <div className="flex flex-row w-full">
                        <div className="w-2/5 px-2 text-primary-1 font-bold">Address:</div>
                        <div className="px w-1/5 text-primary-1 font-bold">Point type:</div>
                        <div className="px w-1/5 text-primary-1 font-bold">Status:</div>
                        <div className="px w-2/5 text-primary-1 font-bold">Capacity:</div>
                    </div>
                    {
                        booking.path.map((point)=>(
                            <div className="flex flex-row w-full">
                                <div className="w-2/5 px-2">{point.address} </div>
                                <div className="w-1/5">{(point.pointType == "location") ? "pickup" : "dropoff" }</div>
                                <div className="w-1/5">{point.status} </div>
                                <div className="flex flex-row w-2/5 gap-2">
                                    <div>{`weight: ${point.capacity?.weight}  kg(s)`}</div>
                                    <div>{`length: ${point.capacity?.leng}  m`}</div>
                                    <div>{`width: ${point.capacity?.width}  m`}</div>
                                    <div>{`height: ${point.capacity?.height}  m`}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )

    };


    return(
        <div className='w-full h-full p-3'>
            <h1>Bookings</h1>

            {
                groupingState.bookings.map((booking, index)=>{
                    return renderBooking(booking, index)
                })
            }
        </div>
    )
}

export default Bookings;