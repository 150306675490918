import {
    GoogleMap,
    InfoWindow,
    Marker,
    MarkerClusterer,
    useJsApiLoader
} from "@react-google-maps/api";
import { Labels, MapStyles } from "../../../../util/map.util";
/* eslint-disable no-undef */
import React,{ createRef, useEffect, useRef, useState} from "react"

import GroupingDialogBox from "./GroupingDialogBox";
import context from "../../../../context";
import AddStationPointDialog from "./AddStationPointDialog";

const libraries = ["places"];


const Map = ({
    groupingState,
    onBookingMarkerClicked,
    onTripMarkerClicked,
    onGroupBookings,
    onAddBookingsToGroup,
    onReorderGroupBookings,
    onRemoveBookingFromGroup,
    onOrderSelectedBookingsPath,
    openInGoogleMaps,
    addStationPointToBookingPath,
    setShowMile
}) => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    });
    const mapRef = useRef();
    const [markers, setMarkers] = useState([]);
    const [tripMarkers, setTripMarkers] = useState([]);
    const [bookingPolylinesState, setBookingPolylinesState] = useState([]);
    const [tripPolylinesState, setTripPolylinesState] = useState([]);
    const [stationMarkers, setStationMarkers] = useState([]);

    const [selectedBookingsPolylineState, setSelectedBookingsPolylineState] = useState(null);

    const [mapCentered, setMapCentered] = useState(false);
    const [showAddStationPointDialog, setShowAddStationPointDialog] = useState({
        show: false,
        stationId: null,
        pointBeforeStation: null
    });

    const getBookingMarkerColor = (pointType) => {

        switch (pointType) {
            case "location":
                return 'rgb(0,255,0)';
            case "destination":
                return 'rgb(255,0,0)';
            case "pass":
                return 'rgb(0,0,255)';
            default:
                return 'rgb(0,0,0)';
        }

    }
    const centerMap = () =>{
        var bounds = new window.google.maps.LatLngBounds();
        var allMarkers = [...markers, ...tripMarkers, ...stationMarkers];
        allMarkers.forEach((marker)=>{
            const {booking, type, latitude, longitude} = marker;
            bounds.extend(
                {
                    lat: latitude,
                    lng: longitude
                }
            );
        })
        mapRef.current?.setCenter(bounds.getCenter());
        mapRef.current?.fitBounds(bounds);
    }

    const renderBookingMarker = (marker, clusterer, existingMarkers) =>{
        const {booking, type, markerRef, label, pathInfo, address, latitude, longitude, order, capacity } = marker;

        let _position = `${latitude.toFixed(4)}|${longitude.toFixed(4)}`
        let _newLatLng = null

        if (!existingMarkers.hasOwnProperty(_position))
        {
            existingMarkers[_position] = true;
        }
        else
        {
            _newLatLng = {
                latitude: latitude + (Math.random() / 25000),
                longitude: longitude + (Math.random() / 25000)
            }
            _position = `${_newLatLng.latitude.toFixed(4)}|${_newLatLng.latitude.toFixed(4)}`
            existingMarkers[_position] = true
        }

        return(
            <Marker
                key={`${booking._id}-${type}-${order}`}
                ref={markerRef}
                position={
                    //check if there is maker at the same position
                    (_newLatLng != null ) ?
                        {
                            lat: _newLatLng.latitude, // randomize
                            lng: _newLatLng.longitude
                        }
                    :
                        {
                            lat: latitude,
                            lng: longitude
                        }
                }
                label={
                    (label != null) ?
                        {
                            text: label,
                            className: 'bg-gray-200 rounded-lg p-1 font-bold border-2 border-gray-500'
                        }
                    :
                        null
                }
                clusterer={clusterer}
                icon={{
                    path:'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                    fillColor: getBookingMarkerColor(type),
                    fillOpacity: 1,
                    //scale: 2,
                    strokeColor: 'rgba(255, 255, 255, 0.5)',
                    strokeWeight: 15,
                }}
                onClick={()=>onBookingMarkerClicked(marker.booking)}
            >
                {

                    // (pathInfo != null) ?
                    //     <InfoWindow
                    //         position={
                    //             (_newLatLng != null) ?
                    //             {
                    //                 lat: _newLatLng.latitude, // randomize point
                    //                 lng: _newLatLng.longitude
                    //             }
                    //             :
                    //             {
                    //                 lat: latitude,
                    //                 lng: longitude                                }

                    //         }
                    //     >
                    //         <div 
                    //             className = {
                    //                 ( type == "location") ? 
                    //                     "flex flex-col p-2 bg-green-200" 
                    //                 :
                    //                     "flex flex-col p-2 bg-red-200"
                    //             }
                    //         >
                    //             {
                    //                 pathInfo.capacity?.leng && 
                    //                 <div className="flex flex-col">
                    //                     <label className="">Capacity:</label>
                    //                     <div className="">{`l: ${pathInfo.capacity?.leng}`}</div>
                    //                     <div className="">{`w: ${pathInfo.capacity?.width}`}</div>
                    //                     <div className="">{`h: ${pathInfo.capacity?.height}`}</div>
                    //                     <div className="">{`wt: ${pathInfo.capacity?.weight}`}</div>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </InfoWindow>
                    // :
                    //     <InfoWindow
                    //         position={
                    //             (_newLatLng != null) ?
                    //             {
                    //                 lat: _newLatLng.latitude, // randomize point
                    //                 lng: _newLatLng.longitude
                    //             }
                    //             :
                    //             {
                    //                 lat: latitude,
                    //                 lng: longitude       
                    //             }
                    //         }
                    //     >
                    //         <div className="flex flex-col p-2">
                    //             <label className="mr-2">Capacity:</label>
                    //             <div className="">{`l: ${capacity?.leng}`}</div>
                    //             <div className="">{`w: ${ capacity?.width}`}</div>
                    //             <div className="">{`h: ${capacity?.height}`}</div>
                    //             <div className="">{`wt: ${capacity?.weight}`}</div>
                    //         </div>
                    //     </InfoWindow>
                }

            </Marker>
           
        )
    }

    const getBookingMarkers = () => {
        let markers = [];

        groupingState.bookings.forEach((booking) => {

            booking.path.filter((point) => point.trip == null)
            .forEach((point)=>{
                markers.push({
                    type: point.pointType,
                    booking,
                    markerRef: createRef(),
                    label: null,
                    pathInfo: null,
                    trip: null,
                    order: point.order,
                    address: point.address,
                    capacity: point.capacity,
                    transitCapacity: point.transitCapacity,
                    latitude: point.location.coordinates[1],
                    longitude: point.location.coordinates[0]
                })
            })
        });
        setMarkers(markers);
    }

    const clearBookingPolylines = () => {
        bookingPolylinesState.forEach((polyline)=>{
            polyline.polylineRef.setMap(null)
        })
    }

    const setBookingPolylines = () =>{

        clearBookingPolylines();

        let polylines  = [];

        groupingState.bookings.forEach((booking)=>{
            // create polyline here and store ref

            const path = booking.path.filter((point) => point.trip == null)
            .map((point) => ({ lat: point.location.coordinates[1], lng: point.location.coordinates[0] }))

            const polyline = new window.google.maps.Polyline({
                path,
                //strokeColor: '#00ffff',
                strokeOpacity: 0,
                //strokeWeight: 6,
                editable: false,
                draggable: false,
                geodesic: true,
                icons: [{
                    icon:{
                        //path: "M 0,-1 0,1",
                        path: window.google && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        strokeOpacity: 1,
                        scale: 1.5,
                        strokeColor: '#000000'
                    },
                    offset: '0',
                    repeat: '20px'
                }],
            })
            polyline.setMap(mapRef.current);
            addPolylineListeners(polyline, mapRef, booking);
            polylines.push({
                bookingId: booking._id,
                path,
                polylineRef: polyline
            })
        });

        setBookingPolylinesState(polylines)
    }

    var activePolyline = useRef();
    var draggingPointIndex =  useRef();
    var originalPolylinePath = useRef();
    var pulling = useRef();
    var activeBooking = useRef();

    function addPolylineListeners(polyline, mapRef, booking) {
        pulling.current = false;
        // Add click listener to the polyline
        window.google.maps.event.addListener(polyline, 'click', async function(event) {
            const path = polyline.getPath();
            let minDistance = Infinity;
            let closestSegmentIndex = -1;
            const clickLocation = event.latLng;

            pulling.current = !pulling.current;

            if (pulling.current == true) {
                // Find the closest point on the polyline to the click event
                for (let i = 0; i < path.getLength() - 1; i++) {
                    const segmentStart = path.getAt(i);
                    const segmentEnd = path.getAt(i + 1);
                    const distance = await window.google.maps.geometry.spherical.computeDistanceBetween(
                      clickLocation,
                      window.google.maps.geometry.spherical.interpolate(segmentStart, segmentEnd, 0.5)
                    );
                    if (distance < minDistance) {
                      minDistance = distance;
                      closestSegmentIndex = i;
                    }
                  }

                if (closestSegmentIndex !== -1) {

                    const originalPath = path.getArray();
                    draggingPointIndex.current = closestSegmentIndex + 1;
                    const newPath = [...originalPath.slice(0, draggingPointIndex.current), clickLocation, ...originalPath.slice(draggingPointIndex.current)];
                    polyline.setPath(newPath);

                    activePolyline.current = polyline;
                    originalPolylinePath.current = originalPath;
                    activeBooking.current = booking;
                    pulling.current = true;
                    mapRef.current.setOptions({ draggable: false });
                }
            } 
            else
            {
                pulling.current = false;
                if (activePolyline.current) {
                    cancelStationSelection();
                }
            }
        })
    }

    const cancelAddStationPoint = () => {
        setShowAddStationPointDialog({
            show: false,
            stationId: null,
            pointBeforeStation: null
        });
        cancelStationSelection();
    }

    const cancelStationSelection = () => {
        activePolyline.current.setPath(originalPolylinePath.current);
        activePolyline.current = null;
        draggingPointIndex.current =  null;
        originalPolylinePath.current = null;
        activeBooking.current = null;
        mapRef.current.setOptions({ draggable: true });
    }

    const getClosestStationAndDistance = async (point) => {

        let closestStationId = null;
        let minDistance = Infinity;

        for (let i = 0; i < stationMarkers.length - 1; i++) {
            let station = stationMarkers[i];
            let distance = await window.google.maps.geometry.spherical.computeDistanceBetween(
                point,
                { lat: station.latitude, lng: station.longitude }
            );

            if (distance < minDistance) {
                minDistance = distance;
                closestStationId = station._id;
            }
        }

        return {
            stationId: closestStationId,
            stationDistance: minDistance
        }
    }

    useEffect(() => {
        // Add drag listener to the map
        if ( isLoaded && window.google != null && mapRef.current)
        {
            window.google.maps.event.addListener(mapRef.current, 'mousemove', function(event) {
                if (pulling.current && draggingPointIndex.current !== null) {
                    const path = activePolyline.current.getPath();
                    path.setAt(draggingPointIndex.current, event.latLng);
                }
            });
        }
    },[window.google, isLoaded, mapRef.current])

    const clearSelectedBookingsPolyline = () => {

        if(selectedBookingsPolylineState)
        {
            selectedBookingsPolylineState.polylineRef.setMap(null)
        }
    }

    const setSelectedBookingsPolyline = () =>{

        clearSelectedBookingsPolyline();

        const path = groupingState.selectedPath.map((point)=>{
            // create polyline here and store ref
            const { latitude, longitude } = point; 
            return({
                lat: latitude,
                lng: longitude,
            })
        })

        if (path.length != 0)
        {
            const polyline = new window.google.maps.Polyline({
                path,
                strokeColor: '#00ffff',
                strokeOpacity: 1,
                strokeWeight: 6,
            })
            polyline.setMap(mapRef.current)
            setSelectedBookingsPolylineState({
                path,
                polylineRef: polyline
            })
        }

    }
    useEffect(()=>{
        if ( isLoaded && window.google != null)
        {
            setBookingPolylines();
            getBookingMarkers();
        }
       
    },[JSON.stringify(groupingState.bookings), window.google, isLoaded])

    useEffect(()=>{
        // set marker labels here

        let _pathHash = {};
        groupingState.selectedPath.forEach((point)=>{
            const { booking, type, label, capacity, transitCapacity, order } = point;
            _pathHash[`${booking._id}-${type}-${order}`] = {
                label,
                capacity: transitCapacity,
                order
            };
        });

        const allMarkers = [...markers, ...tripMarkers]

        let _markers = [];
        let _tripMarkers = [];

        allMarkers.forEach((marker)=>{

            const { booking, type, markerRef, trip, order } = marker;

            if(_pathHash.hasOwnProperty(`${booking._id}-${type}-${order}`))
            {
                const { label, capacity } = _pathHash[`${booking._id}-${type}-${order}`]
                // add label
                marker.label = label;
                marker.pathInfo = {
                    capacity
                }
            }
            else
            {
                marker.label = null;
                marker.pathInfo = null;
            }

            if (trip)
            {
                _tripMarkers.push(marker);
            }
            else
            {
                _markers.push(marker)
            }
        });

        setMarkers(_markers);
        setTripMarkers(_tripMarkers);
        setSelectedBookingsPolyline();
       
    }, [groupingState.selectedPath])

    useEffect(()=>{

        if( isLoaded && window.google != null && (markers.length != 0 || tripMarkers.length != 0) && mapCentered == false)
        {
            centerMap();
            setMapCentered(true);
        }
    },[markers,window.google, isLoaded])

    // trips
    const getTripMarkers = () => {
        let markers = [];
        groupingState.trips.forEach((trip) => {
            trip.path.forEach((point)=>{
                const {booking, pointType, transitOrder, order, address, capacity, transitCapacity} = point
                markers.push({
                    _id: point._id,
                    type: pointType,
                    booking,
                    markerRef: createRef(),
                    label: null,
                    pathInfo: null,
                    trip,
                    transitOrder,
                    order,
                    address,
                    capacity,
                    transitCapacity,
                    latitude: point.location.coordinates[1],
                    longitude: point.location.coordinates[0]
                })
    
            })
        });

        setTripMarkers(markers);
    }

    const renderTripMarker = (marker, clusterer, existingMarkers) =>{
        const {booking, type, markerRef, label, pathInfo , trip, latitude, longitude, _id, capacity, transitCapacity} = marker;

        let _position = `${latitude.toFixed(4)}|${longitude.toFixed(4)}`
        let _newLatLng = null

        if (!existingMarkers.hasOwnProperty(_position))
        {
            existingMarkers[_position] = true;
        }
        else
        {
            _newLatLng = {
                lat: latitude + (Math.random() / 25000),
                lng: longitude + (Math.random() / 25000)
            }
            _position = `${_newLatLng.lat.toFixed(4)}|${_newLatLng.lng.toFixed(4)}`
            existingMarkers[_position] = true
        }

        return(
            <Marker
                key={_id}
                ref={markerRef}
                position={
                    //check if there is maker at the same position
                    (_newLatLng != null ) ?
                        {
                            lat: _newLatLng.lat, // randomize
                            lng: _newLatLng.lng
                        }
                    :
                        {
                            lat: latitude,
                            lng: longitude
                        }
                }
                label={
                    (label != null) ?
                        {
                            text: label,
                            className: 'bg-gray-200 rounded-lg p-1 font-bold border-2 border-gray-500'
                        }
                    :
                        null
                }
                clusterer={clusterer}
                icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    //path:'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                    fillColor: (type == "location") ? 'rgb(16,105,38)':'rgb(255,0,0)',
                    fillOpacity: 1,
                    scale: 15,
                    strokeColor: 'rgba(255, 255, 255, 0.5)',
                    strokeWeight: 15,
                }}
                onClick={()=>onTripMarkerClicked(trip)}
            >
                {

                    // (pathInfo != null) ?
                    //     <InfoWindow
                    //         position={
                    //             //check if there is maker at the same position
                    //             (_newLatLng != null ) ?
                    //                 {
                    //                     lat: _newLatLng.lat, // randomize
                    //                     lng: _newLatLng.lng
                    //                 }
                    //             :
                    //                 {
                    //                     lat: latitude,
                    //                     lng: longitude
                    //                 }
                    //         }
                    //     >
                    //         <div 
                    //             className = {
                    //                 ( type == "location") ? 
                    //                     "flex flex-col p-2 bg-green-200" 
                    //                 :
                    //                     "flex flex-col p-2 bg-red-200"
                    //             }
                    //         >
                    //             {
                    //                 pathInfo.capacity && 
                    //                 <div className="flex flex-row">
                    //                     <label className="mr-2">Capacity:</label>
                    //                     <div className="">{pathInfo.capacity?.leng}</div>
                    //                     <div className="">{pathInfo.capacity?.width}</div>
                    //                     <div className="">{pathInfo.capacity?.height}</div>
                    //                     <div className="">{pathInfo.capacity?.weight}</div>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </InfoWindow>
                    // :
                    //     <InfoWindow
                    //     position={
                    //         //check if there is maker at the same position
                    //         (_newLatLng != null ) ?
                    //             {
                    //                 lat: _newLatLng.lat, // randomize
                    //                 lng: _newLatLng.lng
                    //             }
                    //         :
                    //             {
                    //                 lat: latitude,
                    //                 lng: longitude
                    //             }
                    //     }
                    //     >
                    //         <div className="flex flex-col p-2">
                    //             {
                    //                 <div className="flex flex-col p-2">
                    //                     <label className="mr-2">Capacity:</label>
                    //                     <div className="">{`l: ${transitCapacity?.leng}`}</div>
                    //                     <div className="">{`w: ${ transitCapacity?.width}`}</div>
                    //                     <div className="">{`h: ${transitCapacity?.height}`}</div>
                    //                     <div className="">{`wt: ${transitCapacity?.weight}`}</div>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </InfoWindow>
                }

            </Marker>
           
        )
    }

    // stations

    const onStationMarkerClicked = (station) => {

        if (pulling.current == true && activePolyline.current) {
            pulling.current = false;
            let pointBeforeStation = activeBooking.current.path.filter((point) => point.trip == null)[draggingPointIndex.current - 1];
            setShowAddStationPointDialog({
                show: true,
                stationId: station._id,
                pointBeforeStation,
            });
        }
    }

    const getStationMarkers = () => {
        let markers = [];
        groupingState.stations.forEach((station) => {
            markers.push({
                _id: station._id,
                station,
                markerRef: createRef(),
                label: station.name,
                address: station.address,
                latitude: station.location.coordinates[1],
                longitude: station.location.coordinates[0]
            })
        });
        setStationMarkers(markers);
    }

    const renderStationMarker = (marker, clusterer, existingMarkers) =>{
        const {markerRef, label, latitude, longitude, _id} = marker;
        let _position = `${latitude.toFixed(4)}|${longitude.toFixed(4)}`
        let _newLatLng = null;

        if (!existingMarkers.hasOwnProperty(_position))
        {
            existingMarkers[_position] = true;
        }
        else
        {
            _newLatLng = {
                lat: latitude + (Math.random() / 25000),
                lng: longitude + (Math.random() / 25000)
            }
            _position = `${_newLatLng.lat.toFixed(4)}|${_newLatLng.lng.toFixed(4)}`
            existingMarkers[_position] = true
        }

        return(
            <Marker
                key={_id}
                ref={markerRef}
                position={
                    (_newLatLng != null ) ?
                        {
                            lat: _newLatLng.lat, // randomize
                            lng: _newLatLng.lng
                        }
                    :
                        {
                            lat: latitude,
                            lng: longitude
                        }
                }
                label={{
                    text: label,
                    className: 'rounded-lg p-1 font-bold border-1 border-gray-500 text-sm',
                }}
                onClick={()=>onStationMarkerClicked(marker.station)}
                // clusterer={clusterer}
                icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: 'rgba(0, 0, 0, 1)',
                    fillOpacity: 1,
                    scale: 5,
                    strokeColor: 'rgba(0, 0, 0, 1)',
                    strokeWeight: 2,
                    labelOrigin: { x: 0, y: -5 },
                    anchor: { x: 0, y: 0 },
                }}
            />
        )
    }

    useEffect(()=>{
        if ( isLoaded && window.google != null)
        {
            getStationMarkers();
        }
       
    },[groupingState.stations, window.google, isLoaded])

    const renderMarkers = () => {

        return(
            <MarkerClusterer
                averageCenter={true}
                gridSize={2} 
                maxZoom={12}
            >
                {
                    (clusterer)=>{
                        let existingMarkers = {};
                        let _markers = [];
                        let _tripMarkers = [];
                        let _stationMarkers = [];
                        
                        tripMarkers.forEach((marker)=>{
                            _tripMarkers.push(renderTripMarker(marker, clusterer, existingMarkers));
                        })
                        
                        markers.map((marker)=>{
                            _markers.push(renderBookingMarker(marker,clusterer, existingMarkers))
                        })

                        stationMarkers.map((marker)=>{
                            _stationMarkers.push(renderStationMarker(marker, clusterer, existingMarkers))
                        })

                        return [..._stationMarkers, ..._markers, ..._tripMarkers]

                    }
                }
            </MarkerClusterer>
        )
    }

    const clearTripPolylines = () => {
        tripPolylinesState.forEach((polyline)=>{
            polyline.polylineRef.setMap(null)
        })
    }

    const setTripPolylines = () =>{

        clearTripPolylines();

        let polylines  = [];

        groupingState.trips.forEach((trip)=>{
            // create a polyline here using trip path
            const path = trip.path.map((point)=>{

                return({
                    lat: point.location.coordinates[1],
                    lng: point.location.coordinates[0],
                })

            })
           
            const polyline = new window.google.maps.Polyline({
                path,
                //strokeColor: '#00ffff',
                strokeOpacity: 0,
                //strokeWeight: 6,
                icons: [{
                    icon:{
                        //path: "M 0,-1 0,1",
                        path: window.google && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        strokeOpacity: 1,
                        scale: 1.5,
                        strokeColor: 'rgb(16,105,38)'
                    },
                    offset: '0',
                    repeat: '20px'
                }],
            })
            polyline.setMap(mapRef.current)
            polylines.push({
                tripId: trip._id,
                path,
                polylineRef: polyline
            })
        })
        setTripPolylinesState(polylines)
    }    

    useEffect(()=>{
        if ( isLoaded && window.google != null)
        {
            setTripPolylines();
            getTripMarkers();
        }
       
    },[groupingState.trips, window.google, isLoaded])


    if ( !isLoaded)
    {
        return(
            <div className="w-full h-full p-5">
                <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
            </div>
        )
    }

    return (
        <div className="w-full h-full">
            <GoogleMap
                mapContainerStyle={{
                    width: 100 + "%",
                    height: 100 + "%"
                }}
                zoom={15}
                onLoad={(map)=>{
                    mapRef.current = map;
                    setBookingPolylines();
                    setTripPolylines();
                }}
                options={{
                    styles: MapStyles,
                }}
            >
                {
                    <div className="flex flex-row w-fit h-fit gap-3 p-2 bg-white absolute left-3 top-16">
                        <button
                            className={`text-gray-500 ${groupingState.showMile.first == true ? "underline" : ''}`}
                            onClick={()=>setShowMile("first", !groupingState.showMile.first)}
                        >First</button>
                        <button
                            className={`text-gray-500 ${groupingState.showMile.middle == true ? "underline" : ''}`}
                            onClick={()=>setShowMile("middle", !groupingState.showMile.middle)}
                        >Middle</button>
                        <button
                            className={`text-gray-500 ${groupingState.showMile.last == true ? "underline" : ''}`}
                            onClick={()=>setShowMile("last", !groupingState.showMile.last)}
                        >Last</button>
                    </div>
                }
                {
                    (groupingState.selectedPath.length != 0) &&
                    <GroupingDialogBox 
                        groupingState={groupingState}
                        onGroupBookings = {onGroupBookings}
                        onOrderSelectedBookingsPath={onOrderSelectedBookingsPath}
                        onAddBookingsToGroup={onAddBookingsToGroup}
                        onRemoveBookingFromGroup = {onRemoveBookingFromGroup}
                        onReorderGroupBookings={onReorderGroupBookings}
                        openInGoogleMaps={openInGoogleMaps}
                    />
                }
                {
                    (showAddStationPointDialog.show) &&
                    <AddStationPointDialog
                        addStationPointToBookingPath={addStationPointToBookingPath}
                        stationId={showAddStationPointDialog.stationId}
                        cancelAddStationPoint={cancelAddStationPoint}
                        pointBeforeStation={showAddStationPointDialog.pointBeforeStation}
                    />
                }
                {
                    renderMarkers()
                }
            </GoogleMap>
        </div>
    )
}

export default Map;