import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialTrackingState = {
    trailers:[],
};


const {
    GET_TRACKING_TRAILERS_DATA,
} = actionConstants;


export const getTrackingTrailersDataAction  = (filters) => {

    return (dispatch, store) => {
        dispatch(showLoaderAction(true));

        const {searchFilter, fleetFilter, trailerTypeFilter, cargoTypeFilter, availabilityFilter} = filters;

        axios.get(`${context.SERVER_URL}/api/trackingTrailers?searchFilter=${searchFilter}&fleetFilter=${fleetFilter?.value}&trailerTypeFilter=${trailerTypeFilter?.value}&cargoTypeFilter=${cargoTypeFilter?.value}&availabilityFilter=${availabilityFilter?.value}`)
        .then((response)=>{   
            let trailers = response.data;
            dispatch({
                type: GET_TRACKING_TRAILERS_DATA,
                payload:{
                    trailers
                }
            })

        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
}

function handleGetTrackingTrailersData (state, action){
    return update(state, {
        tracking:{
            trailers:{ $set: action.payload.trailers },
        }
    })
}

export const TRACKING_ACTION_HANDLERS = {
    GET_TRACKING_TRAILERS_DATA: handleGetTrackingTrailersData
}