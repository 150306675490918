import React, { useState } from "react";

import ApartmentIcon from "@mui/icons-material/Apartment";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter5Icon from "@mui/icons-material/Filter5";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Groups2Icon from "@mui/icons-material/Groups2";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaymentIcon from "@mui/icons-material/Payment";
import Person3Icon from "@mui/icons-material/Person3";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";

const Features = () => {
  const [features, setFeatures] = useState([
    {
      title: "Booking your trip today!",
      content:
        "Our transport management system is specialized for moving goods, making it easier than ever for private users and companies to transport their cargo. Whether you're a small business owner or a large corporation, our system can optimize your route and streamline your logistics.",
      style: "bg-primary-1 text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <ShoppingCartCheckoutIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <ShoppingCartCheckoutIcon fontSize="large" />
            <ShoppingCartCheckoutIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Trip sharing",
      content:
        "Our transport management system allows users to share space in a truck, combining less than truck load bookings and saving money on transportation costs. Whether you're a small business owner or an individual with goods to transport, our system can help you save money while still getting your goods to their destination safely and efficiently",
      style: "bg-orange-custom text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <Filter2Icon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <Filter5Icon fontSize="large" />
            <Filter9PlusIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Real-Time Tracking",
      content:
        "With Wheelsit's, you can stay informed with real-time tracking of your bookings. Whether you're a small business owner or an individual with goods to transport, Wheelsit can provide you with up-to-date information on your booking's status, driver's location, and more.",
      style: "bg-orange-custom text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <LocationOnIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <LocationOnIcon fontSize="large" />
            <LocationOnIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Cost and earning",
      content:
        "We strive to make the transport process affordable for our users while ensuring that our drivers can earn a living from their work.  Our shared space option allows users to share space in a truck, combining less than truck load bookings and reducing transportation costs for all parties involved.",
      style: "bg-primary-1 text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <PriceCheckIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <PriceCheckIcon fontSize="large" />
            <PriceCheckIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Our support team",
      content:
        "We understand that transportation can sometimes be stressful, and that's why we're committed to providing our customers and drivers with the best possible customer service. Our team is available 24/7 to attend to any customer support issues that may arise, ensuring that our customers and drivers can always get the help they need.",
      style: "bg-primary-1 text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <InterpreterModeIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <Groups2Icon fontSize="large" />
            <Groups2Icon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Community",
      content:
        "At Wheelsit, we're not just a transport management system - we're a community of users and drivers who are passionate about making transportation of goods seamless and affordable.",
      style: "bg-orange-custom text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <TapAndPlayIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <TapAndPlayIcon fontSize="large" />
            <TapAndPlayIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Independent users",
      content:
        "Wheelsit understands that many businesses and individuals require transportation services but may not have their own fleets or drivers. Therefore, we offer a transportation management system that allows independent users to book trips and access independent drivers and fleets, making transportation easy and efficient.",
      style: "bg-orange-custom text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <GroupAddIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <GroupAddIcon fontSize="large" />
            <GroupAddIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Companies",
      content:
        "If you're a company in need of efficient logistics management, Wheelsit has got you covered. Our system caters to companies by offering them the ability to make bookings for their company and access fleets and private drivers.",
      style: "bg-primary-1 text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <ApartmentIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <ApartmentIcon fontSize="large" />
            <ApartmentIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Independent drivers",
      content:
        "At Wheelsit, we believe in creating a community of independent drivers who want to earn extra income by transporting cargo with their personal trucks. Our system allows drivers to use it as private drivers and get access to trips from individual users and companies. Whether you're looking for part-time or full-time work, Wheelsit can help you connect with customers who need their goods transported. Join our community of independent drivers today and start earning money on your own terms. Sign up for Wheelsit now!",
      style: "bg-primary-1 text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <Person3Icon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <EmojiTransportationIcon fontSize="large" />
            <PaymentIcon fontSize="large" />
          </div>
        </div>
      ),
    },
    {
      title: "Fleets",
      content:
        "Manage your fleet with ease and get access to new business opportunities. With Wheelsit, fleets can accept trips from private users and companies, and track the delivery of orders. Join the Wheelsit community and grow your business today.",
      style: "bg-orange-custom text-white",
      icon: (
        <div className="flex flex-col items-center justify-center">
          <LocalShippingIcon fontSize="large" />
          <div className="flex flex-row items-center justify-center">
            <LocalShippingIcon fontSize="large" />
            <LocalShippingIcon fontSize="large" />
          </div>
        </div>
      ),
    },
  ]);

  const renderFeature = (feature, index) => {
    return (
      <div
        className={`flex  flex-col p-3 col-span-1 ${feature.style} } items-center justify-center shadow`}
        key={index}
      >
        <h2 className="font-bold text-white text-xl mb-2">{feature.title}</h2>
        <div className="flex flex-row">
          <p className="text-white">{feature.content}</p>
          <div className="flex w-fit items-center justify-center p-2 text-white">
            {feature.icon}
          </div>
        </div>
      </div>
    );
  };

  return (
    // <div className="w-full grid grid-cols-1 px-5 gap-4 md:px-20 md:grid-cols-2">

    //     {
    //         features.map((feature, index)=>renderFeature(feature, index))
    //     }

    // </div>

    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold leading-tight text-primary-2 sm:text-4xl xl:text-5xl font-pj">
            WE CAN HELP YOU
          </h2>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8 font-pj">
            Make the best of our transport platform
          </p>
        </div>

        <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
          <div className="md:p-8 lg:p-14">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12 mx-auto">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
</svg>

            <h3 className="mt-12 text-xl font-bold text-primary-2 font-pj">
              Booking Your Trip Today!
            </h3>
            <p className="mt-5 text-base text-gray-600 font-pj">
              Streamline your cargo transportation with our specialized
              transport management system. Whether you're an individual or a
              business, our system optimizes routes, making goods movement
              easier than ever before.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
            <svg
              className="mx-auto"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 27H19V45H27V27Z"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 37H1V45H9V37Z"
                fill="#D4D4D8"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M45 17H37V45H45V17Z"
                fill="#D4D4D8"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 17L15 7L23 15L37 1"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 1H37V10"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="mt-12 text-xl font-bold text-primary-2 font-pj">Trip Sharing</h3>
            <p className="mt-5 text-base text-gray-600 font-pj">
            Save on transportation costs by sharing truck space through our management system. Individuals and small businesses can now access affordable goods transport while ensuring safe and efficient delivery.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
            <svg
              className="mx-auto"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41 1H1V41H41V1Z"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 7H7V20H18V7Z"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 26H7V35H18V26Z"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M35 7H24V35H35V7Z"
                fill="#D4D4D8"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="mt-12 text-xl font-bold text-primary-2 font-pj">
            Real-Time Tracking
            </h3>
            <p className="mt-5 text-base text-gray-600 font-pj">
            Stay updated with real-time booking tracking. Monitor your shipment's progress, driver location, and more. This service is available to both individual customers and businesses.

            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200">
            <svg
              className="mx-auto"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.66667 25H6C3.23858 25 1 27.2386 1 30V37C1 39.7614 3.23858 42 6 42H36C38.7614 42 41 39.7614 41 37V30C41 27.2386 38.7614 25 36 25H31.8333C30.2685 25 29 26.2685 29 27.8333C29 29.3981 27.7315 30.6667 26.1667 30.6667H15.3333C13.7685 30.6667 12.5 29.3981 12.5 27.8333C12.5 26.2685 11.2315 25 9.66667 25Z"
                fill="#D4D4D8"
              />
              <path
                d="M9 9H33"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 17H33"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 25H13V31H29V25H41"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M37 1H5C2.79086 1 1 2.79086 1 5V37C1 39.2091 2.79086 41 5 41H37C39.2091 41 41 39.2091 41 37V5C41 2.79086 39.2091 1 37 1Z"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="mt-12 text-xl font-bold text-primary-2 font-pj">
            Affordable Solutions
            </h3>
            <p className="mt-5 text-base text-gray-600 font-pj">
            We're dedicated to affordability and sustainability. Our shared space option lowers costs for users and fosters driver earnings. Efficient logistics benefit all parties involved.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
            <svg
              className="mx-auto"
              width="46"
              height="42"
              viewBox="0 0 46 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.562 18.4609C30.0511 17.9392 29.4292 17.5392 28.7426 17.2907C28.0559 17.0422 27.3221 16.9516 26.5956 17.0256C25.8692 17.0996 25.1687 17.3362 24.5462 17.718C23.9237 18.0998 23.3952 18.6169 23 19.2309C22.6049 18.6167 22.0764 18.0995 21.4539 17.7176C20.8315 17.3357 20.1309 17.099 19.4044 17.025C18.6779 16.951 17.944 17.0417 17.2573 17.2903C16.5706 17.5389 15.9488 17.939 15.438 18.4609C14.5163 19.4035 14.0002 20.6695 14.0002 21.9879C14.0002 23.3063 14.5163 24.5722 15.438 25.5149L23 33.1999L30.564 25.5159C31.485 24.5726 32.0004 23.3064 32 21.988C31.9997 20.6696 31.4835 19.4037 30.562 18.4609Z"
                fill="#D4D4D8"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M41 41H5C3.93913 41 2.92172 40.5786 2.17157 39.8284C1.42143 39.0783 1 38.0609 1 37V1H17L22 9H45V37C45 38.0609 44.5786 39.0783 43.8284 39.8284C43.0783 40.5786 42.0609 41 41 41Z"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="mt-12 text-xl font-bold text-primary-2 font-pj">
            24/7 Support
            </h3>
            <p className="mt-5 text-base text-gray-600 font-pj">
            Transportation made stress-free. Our customer service operates round the clock to address any concerns promptly, ensuring a smooth experience for both users and drivers.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
            <svg
              className="mx-auto"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 7C34.941 7 43 15.059 43 25C43 34.941 34.941 43 25 43C15.059 43 7 34.941 7 25"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 1C9.059 1 1 9.059 1 19H19V1Z"
                fill="#D4D4D8"
                stroke="#161616"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="mt-12 text-xl font-bold text-primary-2 font-pj">
            Community Connection
            </h3>
            <p className="mt-5 text-base text-gray-600 font-pj">
            Beyond a management system, Wheelsit is a passionate community of users and drivers. Together, we're committed to seamless, affordable goods transportation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
