import React, {useState} from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { PACKAGING_TYPE } from '../../../util/general.util';
import Select from 'react-select';

const BookingProducts = ({
    productsControl,
    onProductChanged,
    updateProduct,
    deleteProduct,
    newProducts,
    onNewProductChanged,
    removeNewProduct,
    addNewProduct,
    productCategories
}) => {

    const renderProductControl = (product, index)  =>{
        return(
            <div className='grid grid-cols-8 w-full gap-4 mb-4' key={index}>
                <div className='col-span-1 h-full '>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='text'
                        placeholder='Description'
                        value={product.description}
                        onChange={({target: {value}}) => onProductChanged(index,"description", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='length'
                        value={product.leng}
                        onChange={({target: {value}}) => onProductChanged(index, "leng",value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='Width'
                        value={product.width}
                        onChange={({target: {value}}) => onProductChanged(index, "width", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='Height'
                        value={product.height}
                        onChange={({target: {value}})  => onProductChanged(index, "height", value)}
                    />  
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='Weight'
                        value={product.weight}
                        onChange={({target: {value}})  => onProductChanged(index, "weight", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <Select
                        className="w-full"
                        placeholder="Category"
                        options={productCategories}
                        onChange={(value)=>onProductChanged(index, "category",value)}
                        value={product.category}
                    />
                </div>
                <div className="col-span-1">
                    <Select
                        className="w-full"
                        placeholder="Packaging"
                        options={PACKAGING_TYPE}
                        onChange={(value)=>onProductChanged(index, "packaging",value)}
                        value={product.packaging}
                    />
                </div>

                <div className=' col-span-1 flex flex-row justify-between items-center'>
                    <button
                        className='w-full border border-shade-2 rounded-sm mr-2 px-4 py-2 hover:bg-shade-2 hover:text-shade-1'
                        onClick={() => updateProduct(product)}
                    >
                        update
                    </button>
                    <button
                        className='w-full rounded-sm  px-4 py-2'
                        onClick={() => deleteProduct(product)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 hover:text-red-400">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>

                    </button>
                </div>
            </div>
        )
    }

    const renderNewProduct = (product, index) =>{
        return (
            <div className='grid grid-cols-8 w-full gap-4' key={index}>
                <div className='col-span-1 h-full'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='text'
                        placeholder='Description'
                        value={product.description}
                        onChange={({target: {value}}) => onNewProductChanged(index,"description", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='length'
                        value={product.leng}
                        onChange={({target: {value}}) => onNewProductChanged(index, "leng",value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='Width'
                        value={product.width}
                        onChange={({target: {value}}) => onNewProductChanged(index, "width", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='Height'
                        value={product.height}
                        onChange={({target: {value}})  => onNewProductChanged(index, "height", value)}
                    />  
                </div>
                <div className='col-span-1'>
                    <input
                        className='w-full p-1.5 border rounded-sm'
                        type='number'
                        placeholder='Weight'
                        value={product.weight}
                        onChange={({target: {value}})  => onNewProductChanged(index, "weight", value)}
                    />
                </div>
                <div className='col-span-1'>
                    <Select
                        className="w-full"
                        placeholder="Category"
                        options={productCategories}
                        onChange={(value)=>onNewProductChanged(index, "category",value)}
                        value={product.category}
                    />
                </div>
                <div className="col-span-1">
                    <Select
                        className="w-full"
                        placeholder="Packaging"
                        options={PACKAGING_TYPE}
                        onChange={(value)=>onNewProductChanged(index, "packaging",value)}
                        value={product.packaging}
                    />
                </div>

                <div className='col-span-1'>
                    <button
                        className='w-full'
                        onClick={() => removeNewProduct(index)}
                    >
                        <CancelIcon  fontSize='large'/>
                    </button>
                </div>
            </div>
        )
    }
   
    return (
        <div className='w-full'>
            <h1 className='text-xl mb-6'>My products</h1>

            {
                productsControl.map((product, index) => renderProductControl(product, index))
            }
            {
                newProducts.map((product, index) => renderNewProduct(product, index))
            }
            <div className='flex flex-row w-full items-center justify-end'>
                <button
                    className='w-fit'
                    onClick={() => addNewProduct()}
                >
                    <AddCircleIcon  fontSize='large'/>
                </button>
            </div>
        </div>
    )
}

export default BookingProducts;