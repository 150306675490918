import React, { useEffect, useState } from "react";
import axios from "axios";
import context from "../../../context";
import { useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as PaymentPermissions from "../modules/PaymentPermissions";
import CreatePaymentMethod from "./CreatePaymentMethod";

const PaymentMethods = ({ role }) => {
  const location = useLocation();
  const { itemName, type } = location.state || {};
  const [showNewPaymentMethodDialog, setShowNewPaymentMethodDialog] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = () => {
    axios
      .get(`${context.SERVER_URL}/api/paymentMethods`)
      .then((response) => {
        setPaymentMethods(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderPaymentMethod = (paymentMethod, index) => {
    return (
      <tr className="border-b border-gray-200" key={index}>
        <td className="p-3 text-gray-800">{paymentMethod.cardNickname}</td>
        <td className="p-3 text-gray-800">{paymentMethod.createdAt}</td>
        <td className="p-3 text-gray-800">{paymentMethod.lastCardDigits}</td>
        {PaymentPermissions.isAuthorized("delete", role) && (
          <td className="p-3 text-right">
            <button
              className="px-4 py-2 text-sm text-red-600 bg-red-100 border border-red-300 rounded hover:bg-red-200"
              onClick={() => deletePaymentMethod(paymentMethod._id)}
            >
              Delete
            </button>
          </td>
        )}
      </tr>
    );
  };

  const deletePaymentMethod = (id) => {
    const options = {
      title: "Delete Payment Method",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios
              .delete(`${context.SERVER_URL}/api/paymentMethods/${id}`)
              .then((response) => {
                fetchPaymentMethods(); // Refresh the list after deletion
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "No",
        },
      ],
      overlayClassName: "overlay-custom-class-name",
    };

    confirmAlert(options);
  };

  return (
    <div className="w-full h-full p-6 bg-white shadow rounded-lg overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-primary-1">Payment Methods</h1>
        <button
          className="bg-primary-1 text-white py-2 px-4 rounded-md hover:bg-primary-2"
          onClick={() => setShowNewPaymentMethodDialog(true)}
        >
          New Payment Method
        </button>
      </div>
      <div className="border-b border-gray-300 mb-6"></div>

      <table className="w-full">
        <thead className="border-b-2 border-gray-300">
          <tr>
            <th className="p-3 text-green-custom text-left">Card Nickname</th>
            <th className="p-3 text-green-custom text-left">Created At</th>
            <th className="p-3 text-green-custom text-left">Last Digits</th>
            <th className="p-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {paymentMethods.map((paymentMethod, index) => {
            return renderPaymentMethod(paymentMethod, index);
          })}
        </tbody>
      </table>
      {showNewPaymentMethodDialog && (
        <CreatePaymentMethod
          itemName={itemName}
          type={type}
          setShowNewPaymentMethodDialog={setShowNewPaymentMethodDialog}
        />
      )}
    </div>
  );
};

export default PaymentMethods;
