import {connect} from 'react-redux'
import Companies from '../components/Companies';
import { 
    getCompaniesDataAction,
    deleteCompanyAction 
} from '../modules/companies';
const mapStateToProps = (state) =>({
    role: state.profile.role,
    companies: state.companies.companies,
    company: state.companies.company,
    totalPages: state.companies.totalPages,
    currentPage: state.companies.currentPage,
});

const mapActionCreators = {
    getCompaniesDataAction,
    deleteCompanyAction 
};

export default connect (mapStateToProps,mapActionCreators)(Companies); 