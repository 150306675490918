import { getChatsDataAction } from "../../routes/chats/modules/chats";

import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (bookingId) => {
    try {
        const socket = await getSocket();
        socket.on(`ChatUpdate_${bookingId}`, (data) => {
            ReduxStorage.store.dispatch(getChatsDataAction(bookingId));
        })
    } catch (error) {
        console.log("Error registering chat update receiver: ", error);
    }
}

export const unregister = async (bookingId) => { 
    try {
        const socket = await getSocket();
        socket.off(`ChatUpdate_${bookingId}`);
    } catch (error) {
        console.log("Error unregistering chat update receiver: ", error);
    }
}