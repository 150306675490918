import {connect} from 'react-redux'
import LoaderModal from '../components/LoaderModal';

const mapStateToProps = (state) =>({
    showLoader: state.modal.showLoader,
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(LoaderModal); 