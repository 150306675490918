import { Link } from "react-router-dom";
import React from "react";

const WeightBillingRange = ({
  weightTo,
  weightFrom,
  weightBillings,
  weightRangeId,
}) => {
  return (
    <div className="">
      {
        <div className="flex flex-row w-full items-center">
          <div className="rounded-lg border border-gray-100 bg-white p-4 shadow-sm transition hover:shadow-lg hover:border-teal-50 sm:p-6 w-full">
            <span className="inline-block rounded bg-shade-2 p-2 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </span>

            <h3 className="mt-0.5 text-lg font-medium text-primary-2">
              Weight Range
            </h3>

            <div className="flex gap-4 pb-2">
              <div>
                <h1 className="text-sm text-shade-2">Min</h1>
                <p className="text-gray-600">{weightFrom}</p>
              </div>
              <div>
                <h1 className="text-sm text-shade-2">Max</h1>
                <p className="text-gray-600">{weightTo}</p>
              </div>
            </div>

            <div className="flex items-center justify-end gap-4 border-t-2 border-shade-2 pt-2">
              <Link
                to="/"
                className="group inline-flex items-center gap-1 text-sm font-medium text-neutral-6 hover:text-gray-600"
              >
                View
                <span
                  aria-hidden="true"
                  className="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                >
                  &rarr;
                </span>
              </Link> 

              <Link to={`/billings/edit/${weightRangeId}`} className="hover:no-underline">
                <div className="py-1 px-3 text-gray-500 border-2 rounded hover:border-neutral-6 hover:text-neutral-6 text-sm hover:no-underline">
                  Edit
                </div>
              </Link>
            </div>
          </div>
        </div>
      }

      {/* {
                (collapsed == false) &&
                <div className="flex flex-row w-full gap-5 items-center px-10">
                    <label className="w-full text-primary-1 font-bold">{"Distance >="}</label>
                    <label className="w-full text-primary-1 font-bold">{"Distance <="}</label>
                    <label className="w-full text-primary-1 font-bold">Booking Rate / km</label>
                    <label className="w-full text-primary-1 font-bold">Driver Rate / km</label>
                    <label className="w-full text-primary-1 font-bold">Profite Rate /km</label>
                </div>
            } */}

      {/* Data for the View Page */}
      {/* {
                (collapsed == false) &&
                weightBillings.map((billing)=>{

                    const { driverRate, bookingRate, distanceFrom, distanceTo } = billing;
                    return(
                        <WeightBilling
                            driverRate={driverRate}
                            bookingRate={bookingRate}
                            distanceFrom = {distanceFrom}
                            distanceTo = {distanceTo}
                        />
                    )
                })
            } */}
    </div>
  );
};

export default WeightBillingRange;
