import {connect} from 'react-redux'
import RegisterByInvite from '../components/RegisterByInvite';
import { 
    registerByInviteAction
} from '../modules/register'

import { showErrorAction } from '../../common/modules/modal';

const mapStateToProps = (state) =>({
    
});

const mapActionCreators = {
    showErrorAction,
    registerByInviteAction
};

export default connect (mapStateToProps,mapActionCreators)(RegisterByInvite); 