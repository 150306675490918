import io from 'socket.io-client';
import * as Receivers from './Receivers';
import ReduxStorage from '../store/store'

let socket;

export const createSocket = async () => {
    socket = io(process.env.REACT_APP_SERVER_URL, {
        auth: {
            token: await ReduxStorage.store.getState()?.login.userToken
        }
    });
    socket.on('connect', () => {
        console.log('Connected to server');
        Receivers.register(socket);

    });

    socket.on('disconnect', () => {
        console.log('Disconnected from server');
        Receivers.unregister(socket);
    });
}

export const getSocket = () => {
    return socket;
}

export const disconnectSocket = async () => {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
}    