import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as VehiclePermissions from "../modules/VehiclePermissions"

import React, { useEffect, useState } from "react"

import {Link} from 'react-router-dom'
import Select from "react-select";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from "../../../context";
import { COLOR_CODES } from '../../../util/general.util';
import ReactPaginate from "react-paginate";

const Vehicles = ({
    role,
    showLoaderAction
}) => {

    const [vehicles, setVehicles] = useState([]);
    const [totalPages, setTotalPages] = useState(100);
    const [filters, setFilters] = useState({
        searchFilter: '',
        fleetFilter: { value: '', label: 'Select Fleet' },
        statusFilter: { value: '', label: 'Select Status' },
        pageFilter: 1,
        limitFilter: 10
    });

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }

    const [filterOptions, setFilterOptions] = useState({
        fleets:[],
        statuses:[
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
            { label: 'Incomplete', value: 'incomplete' }
        ]
    })

    useEffect(()=>{
        fetchVehicles(filters);
        fetchFleets()
    },[])

    const fetchFleets = () => {
        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((response)=>{   
            const fleets = response.data.fleets.map((vehicle) => ({ label: vehicle.name, value: vehicle._id }));
            setFilterOptions((prevState)=>({
                ...prevState,
                fleets
            }));

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchVehicles = (filters) => {
        showLoaderAction(true);
        const { searchFilter, fleetFilter, statusFilter, pageFilter, limitFilter } = filters;
        axios.get(`${context.SERVER_URL}/api/vehicles?searchFilter=${searchFilter}&fleetFilter=${fleetFilter?.value}&statusFilter=${statusFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{   
            const {vehicles, totalPages, currentPage } = response.data;
            setVehicles(vehicles);
            setTotalPages(totalPages);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }



    const deletevehicle = (id) =>{

        const options = {
            title: 'Delete Vehicle',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/vehicles/${id}`)
                    .then((response)=>{
                        const _vehicles = vehicles.filter((vehicle)=> vehicle._id != id)
                        setVehicles(_vehicles); 
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };
        
        confirmAlert(options);
    }

    return(
        <div className="w-full bg-white p-5 overflow-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Vehicles</h1>
                {
                    VehiclePermissions.isAuthorized("new", role) &&
                    <a href="/vehicles/new">
                        <div className="bg-primary-1 rounded-md text-white px-3 py-2">New Vehicle</div>
                    </a>
                }
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>
            <div className="flex flex-col w-full md:flex-row md:w-3/4 gap-4" >
                <div className="grid grid-cols-4 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Search filter</label>
                        <input 
                            className="w-full py-2 px-4 border rounded-lg"
                            placeholder="Search"
                            onChange={({target:{value}}) => setFilters((prevState)=>({
                                ...prevState,
                                searchFilter: value
                            }))}
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Fleet filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Year"
                            options={filterOptions.fleets}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                fleetFilter: value
                            }))}
                            value={filters.fleetFilter}
                        />
                    </div>

                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Status filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Year"
                            options={filterOptions.statuses}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                statusFilter: value
                            }))}
                            value={filters.statusFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>fetchVehicles(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = { 
                                fleetFilter: { value: '', label: 'Select Fleet' },
                                statusFilter: { value: '', label: 'Select Status' },
                                searchFilter: '',
                                pageFilter: 1,
                                limitFilter: 10
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            fetchVehicles(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>
            
            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                        >
                            Make
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Color
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Model
                        </th>

                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Year
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Reg Number
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Status
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map((vehicle, index) => {
                        return (
                            <tr key={vehicle._id}>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-transparent",
                                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    )}
                                >
                                    <div className="font-medium text-primary-2">
                                        {vehicle.car.make}
                                    </div>

                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    {vehicle.car.make}
                                </td>

                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    {vehicle.car.model}
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{vehicle.car.year}</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{vehicle.registrationNumber}</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.vehicle?.status[vehicle.status.toLowerCase()]}`}>
                                        {vehicle?.status}
                                    </span>
                                </td>

                                <td
                                    className="border-t border-transparent flex
                                        relative py-3.5 text-right text-sm font-medium"

                                >


                                    {
                                        VehiclePermissions.isAuthorized("view", role) &&
                                        <Link to={`/vehicles/view/${vehicle._id}`}>
                                            <div className="border p-2 m-2 rounded-md">View</div>
                                        </Link>
                                    }
                                    {
                                        VehiclePermissions.isAuthorized("edit", role) &&
                                        <Link to ={`/vehicles/edit/${vehicle._id}`}>
                                            <div  className="border border-green-500 text-primary-1 p-2 m-2 rounded-md"> Edit </div>
                                        </Link>
                                    }
                                    {
                                        VehiclePermissions.isAuthorized("delete", role) &&
                                        <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>deletevehicle(vehicle._id)}> Delete </button>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            <div className="flex flex-row w-full items-center justify-end mt-5">
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={({selected}) => {
                        let _filters = {
                            ...filters,
                            pageFilter: selected + 1
                        } 
                        fetchVehicles(_filters);
                        setFilters(_filters);
                    }}
                    containerClassName={'pagination'}
                    pageClassName={'page'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'previous'}
                    nextClassName={'next'}
                    activeClassName={'active'}
                    disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}
export default Vehicles;