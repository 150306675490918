import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';

export const initialModalState = {
    showError: false,
    errorMessage: "" ,
    showLoader: false  
};


const {
    SHOW_ERROR,
    SHOW_LOADER
} = actionConstants;

export const showErrorAction = ({showError, errorMessage}) =>{

    return (dispatch, store) => {
        dispatch({
            type: SHOW_ERROR,
            payload:{
                showError,
                errorMessage,
            }
        })
    }    
}

export const showLoaderAction = (showLoader) =>{

    return (dispatch, store) => {
        dispatch({
            type: SHOW_LOADER,
            payload:{
                showLoader
            }
        })
    }    
}


function handleShowErrorAction (state, action){
    return update(state, {
        modal:{
            showError: { $set: action.payload.showError },
            errorMessage: { $set: action.payload.errorMessage },
        },
    })
}

function handleShowLoaderAction (state, action){
    return update(state, {
        modal:{
            showLoader: { $set: action.payload.showLoader },
        },
    })
}

export const MODAL_ACTION_HANDLERS = {
    SHOW_ERROR: handleShowErrorAction,
    SHOW_LOADER: handleShowLoaderAction
}



