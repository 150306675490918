import {connect} from 'react-redux'
import ViewTrip from '../components/ViewTrip';
import { 
    acceptTripAction, 
    getTripDataAction,
    onrouteTripAction,
    declineTripAction,
    completeTripAction,
    pointArrivedAction,
    pointLoadingAction,
    pointOffloadingAction,
    pointCompleteAction,
} from '../modules/trips';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    trip: state.trips.trip,
});

const mapActionCreators = {
    getTripDataAction,
    acceptTripAction,
    onrouteTripAction,
    declineTripAction,
    completeTripAction,
    pointArrivedAction,
    pointLoadingAction,
    pointOffloadingAction,
    pointCompleteAction,
};

export default connect (mapStateToProps,mapActionCreators)(ViewTrip); 