import * as TrailerPermissions from "../modules/TrailerPermissions";

import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Select from "react-select";
import axios from "axios";
import context from "../../../context";
import { AVAILABILITY_OPTIONS } from "../../../util/general.util"

const EditTrailer = ({ role }) => {
  const { id } = useParams();
  const [fleets, setFleets] = useState([]);

  const [selectedFleet, setSelectedFleet] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [cargoTypes, setCargoTypes] = useState([]);
  const [selectedCargoTypes, setSelectedCargoTypes] = useState([]);
  const [colors, setColors] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [trailerTypes, setTrailerTypes] = useState([]);
  const [selectedTrailerType, setSelectedTrailerType] = useState("");
	const [drivers, setDrivers] = useState([]);
	const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedAvailability, setSelectedAvailability] = useState({label: "offline", value: "offline"});

  const [capacity, setCapacity] = useState({
    leng: 0,
    weight: 0,
    width: 0,
    height: 0,
  });

  const fetchTrailer = () => {
    axios
      .get(`${context.SERVER_URL}/api/trailers/${id}`)
      .then((response) => {
        const {
          fleet,
          capacity,
          registrationNumber,
          cargoTypes,
          color,
          trailerType,
					driver,
          availability
        } = response.data.trailers;
        setSelectedFleet({ label: fleet.name, value: fleet._id });
        setRegistrationNumber(registrationNumber);
        setCapacity(capacity);
        setSelectedCargoTypes(
          cargoTypes.map((cargo) => ({ label: cargo, value: cargo }))
        );
        setSelectedColor({ label: color, value: color });
        setSelectedTrailerType({ label: trailerType, value: trailerType });
        setSelectedAvailability({  label: availability, value: availability })
				if(driver != null)
				{
					setSelectedDriver({ label: `fleet: ${driver.fleet?.name} - name: ${driver.firstName} - surname: ${driver.lastName}`, value: driver._id });
				}
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchColors = () => {
    axios
      .get(`${context.SERVER_URL}/api/colorTypes`)
      .then((response) => {
        const _colors = response.data.map((color) => {
          return { label: color, value: color };
        });
        setColors(_colors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCargoTypes = () => {
    axios
      .get(`${context.SERVER_URL}/api/cargoTypes`)
      .then((response) => {
        const _cargoTypes = response.data.map((cargoType) => {
          return { label: cargoType, value: cargoType };
        });
        setCargoTypes(_cargoTypes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTrailerTypes = () => {
    axios
      .get(`${context.SERVER_URL}/api/trailerTypes`)
      .then((response) => {
        const _trailerTypes = response.data.map((trailerType) => {
          return { label: trailerType, value: trailerType };
        });
        setTrailerTypes(_trailerTypes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCapacityChanged = (key, value) =>{
    setCapacity((prevState)=>({
        ...prevState,
        [key]: value == '' ? '' : Number(value)
    }))
  }

  const fetchFleets = () => {
    axios
      .get(`${context.SERVER_URL}/api/fleets`)
      .then((response) => {
        const _fleets = response.data.fleets.map((fleet) => {
          return { label: fleet.name, value: fleet._id };
        });
        setFleets(_fleets);
      })
      .catch((error) => {
        console.log(error);
      });
  };

	const fetchDrivers = () =>{
		//const fleet = selectedTrailer.value.split("|")[0];
		axios.get(`${context.SERVER_URL}/api/users?roleFilter=driver&fleetFilter=${selectedFleet.value}`)
		.then((response)=>{
				const _drivers = response.data.users.map((driver)=>({ label: `fleet: ${driver.fleet?.name} - name: ${driver.firstName} - surname: ${driver.lastName}`, value: driver._id }))
				setDrivers(_drivers);

		}).catch((err)=>{
				console.log(err)
		});    
	}


  useEffect(() => {
    fetchTrailer();
    fetchCargoTypes();
    fetchColors();
    fetchTrailerTypes();
    fetchFleets();
  }, []);

	useEffect(()=>{
		if(selectedFleet.value != null)
		{
			fetchDrivers();
		}
	},[selectedFleet.value])

  const updateTrailer = () => {
    axios
      .put(`${context.SERVER_URL}/api/trailers/${id}`, {
        data: {
          capacity,
          registrationNumber,
          fleet: selectedFleet.value,
          cargoTypes: selectedCargoTypes.map((cargo) => cargo.value),
          color: selectedColor.value,
          trailerType: selectedTrailerType.value,
					driver: selectedDriver.value,
          availability: selectedAvailability.value
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <div className="w-full bg-white p-3 py-8 px-16 overflow-y-auto">
      <div className="flex justify-between">
        <h1 className="text-primary-1 font-bold">Edit Trailers</h1>
        <Link to={"/fleets"} className="text-gray-400 hover:text-gray-800 pr-2">
          Back
        </Link>
      </div>
      <div className="border border-gray-400 mt-1" />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-5 gap-4">
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Fleet</label>
          <Select
            className="w-full"
            placeholder="Select fleet"
            options={fleets}
            onChange={(value) => setSelectedFleet(value)}
            value={selectedFleet}
          />
        </div>
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Trailer Type:</label>
          <Select
            className="w-full"
            placeholder="Select Trailer Type"
            options={trailerTypes}
            onChange={(value) => setSelectedTrailerType(value)}
            value={selectedTrailerType}
          />
        </div>

        <div className="col-span-1">
          <label className="text-primary-1 font-bold">
            Registration Number
          </label>
          <input
            className="w-full p-2"
            type="text"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
            placeholder={"Enter Registration Number"}
          />
        </div>
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Color</label>
          <Select
            className="w-full"
            placeholder="Select Color"
            options={colors}
            onChange={(value) => setSelectedColor(value)}
            value={selectedColor}
          />
        </div>
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Weight</label>
          <input
            className="w-full p-2"
            type="number"
            value={capacity.weight}
            onChange={(e) => onCapacityChanged("weight", e.target.value)}
            placeholder={"Enter weight"}
          />
        </div>
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Length</label>
          <input
            className="w-full p-2"
            type="number"
            value={capacity.leng}
            onChange={(e) => onCapacityChanged("leng", e.target.value)}
            placeholder={"Enter length"}
          />
        </div>
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Width</label>
          <input
            className="w-full p-2"
            type="number"
            value={capacity.width}
            onChange={(e) => onCapacityChanged("width", e.target.value)}
            placeholder={"Enter width"}
          />
        </div>
        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Height</label>
          <input
            className="w-full p-2"
            type="number"
            value={capacity.height}
            onChange={(e) => onCapacityChanged("height", e.target.value)}
            placeholder={"Enter height"}
          />
        </div>

        <div className="col-span-1 md:col-span-2 lg:col-span-3">
          <label className="text-primary-1 font-bold">Cargo Types</label>
          <Select
            className="w-full"
            placeholder="Select Color"
            options={cargoTypes}
            onChange={(value) => setSelectedCargoTypes(value)}
            value={selectedCargoTypes}
            isMulti={true}
          />
        </div>

				<div className="col-span-1">
          <label className="text-primary-1 font-bold">Driver</label>
          <Select
            className="w-full"
            placeholder="Select Driver"
            options={drivers}
            onChange={(value) => setSelectedDriver(value)}
            value={selectedDriver}
          />
        </div>

        <div className="col-span-1">
          <label className="text-primary-1 font-bold">Availability</label>
          <Select
            className="w-full"
            placeholder="Select Availability"
            options={AVAILABILITY_OPTIONS}
            onChange={(value) => setSelectedAvailability(value)}
            value={selectedAvailability}
          />
        </div> 
      </div>

      <div className="">
        <button
          className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5"
          onClick={() => updateTrailer()}
        >
          Update
        </button>
        {TrailerPermissions.isAuthorized("view", role) && (
          <Link
            to={`/trailers/view/${id}`}
            className="border px-4 py-2 rounded-md ml-2 text-center hover:text-neutral-7 "
          >
            Cancel
          </Link>
        )}
      </div>
    </div>
  );
};

export default EditTrailer;
