import {connect} from 'react-redux'
import EditSupport from '../components/EditSupport';
import { getSupportDataAction, updateSupportAction } from '../modules/support';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    support: state.supports.support
});

const mapActionCreators = {
    getSupportDataAction,
    updateSupportAction
};

export default connect (mapStateToProps,mapActionCreators)(EditSupport); 