import 'react-confirm-alert/src/react-confirm-alert.css';

import * as VehiclePermissions from '../modules/VehiclePermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from '../../../context'

const ViewVehicle = ({
    role
}) =>{

    const {id} = useParams();

    const [vehicle, setVehicle] = useState({
        registrationNumber: "",
        color: "",
        vehicle: {
            name: ""
        },
        car:{
            make: "",
            model: "",
            year: ""
        },
        trailers:[]
    });

    const [trailers, setTrailers] = useState([])
    const [selectedTrailer, setSelectedTrailer] = useState('')

    const fetchVehicle = () => {
        axios.get(`${context.SERVER_URL}/api/vehicles/${id}`)
        .then((response)=>{
            const vehicle = response.data;
            setVehicle(vehicle)
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const fetchTrailers = () => {
        axios.get(`${context.SERVER_URL}/api/trailers`)
        .then((response)=>{
            const trailers = response.data.trailers.map((trailer)=>{
                return  {
                    label: `${trailer.registrationNumber}-${trailer.capacity} tns`,
                    value: trailer._id
                }
            })
            setTrailers(trailers);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
      fetchVehicle();
      fetchTrailers();
    },[])

    const removeTrailer = (trailerId)=>{

        const options = {
            title: 'Remove Trailer',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.put(`${context.SERVER_URL}/api/vehicles/${id}/removetrailer`,{
                        data:{
                            trailerId
                        }
                    })
                    .then((response)=>{

                        const vehicle = response.data;
                        setVehicle(vehicle);
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };
        confirmAlert(options);
    }

    const addTrailer = ()=> {
        axios.put(`${context.SERVER_URL}/api/vehicles/${id}/addtrailer`,{
            data:{
                trailerId: selectedTrailer.value
            }
        })
        .then((response)=>{

            const vehicle = response.data;
            setVehicle(vehicle);
            setSelectedTrailer('')
        })
        .catch((error)=>{
            console.log(error);
        })
    }



    return(

        <>

        <div className="p-3 w-full bg-white">
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            {/* Product etails */}
            <div className="lg:max-w-lg lg:self-end">
              <div className="mt-4">
                <h1 className="text-3xl font-bold tracking-tight text-primary-2 sm:text-4xl">
                {vehicle?.car.make}
                </h1>
              </div>

              <section aria-labelledby="information-heading" className="mt-4">
                <h2 id="information-heading" className="sr-only">
                  vehicle information
                </h2>

                <div className="flex items-center">
                  <p className="text-lg text-primary-2 sm:text-xl">
                    {vehicle.registrationNumber}
                  </p>

                  <div className="ml-4 border-l border-gray-300 pl-4">
                    <div className="flex items-center">
                      <div></div>
                      <p className="ml-2 text-sm text-gray-500">Reg Number</p>
                    </div>
                  </div>
                </div>

                <div className="mb-4 space-y-6">
                  <p className="text-base text-gray-500">
                  {vehicle.car.model}
                  </p>
                </div>

                <div className="flex flex-row">
                  <label className="text-primary-1 mr-2">Status:</label>
                  <div className="text-gray-400 ">{vehicle?.car.year}</div>
                </div>
              </section>
            </div>

            {/* Product image */}
            <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
                <img
                  src="https://tailwindui.com/img/ecommerce-images/product-page-04-featured-product-shot.jpg"
                  alt="alt"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            {/* Product form */}
            <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
              <section aria-labelledby="options-heading">
                <div>
                  <div className="sm:flex sm:justify-between"></div>
                  <div className="mt-4"></div>
                  <div className="mt-10 flex space-x-4">
                    <Link
                      to={"/vehicles"}
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
                    >
                      Vehicles
                    </Link>{" "}
                    {VehiclePermissions.isAuthorized("edit", role) && (
                      <Link
                        to={`/vehicles/edit/${id}`}
                        className="flex w-full items-center justify-center rounded-md border border-primary-1 px-8 py-3 text-base font-medium text-primary-1 hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:text-white hover:no-underline"
                      >
                        edit
                      </Link>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default ViewVehicle;
