import { socketTripUpdateAction } from "../../routes/trips/modules/trips";
import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (tripId) => {
    try {
        const socket = await getSocket();
        socket.on(`TripUpdate_${tripId}`, (data) => {
            ReduxStorage.store.dispatch(socketTripUpdateAction(data));
        })
    } catch (error) {
        console.log("Error registering trip update receiver: ", error);
    }
}


export const unregister = async (tripId) => { 
    try {
        const socket = await getSocket();
        socket.off(`TripUpdate_${tripId}`);
    } catch (error) {
        console.log("Error unregistering trip update receiver: ", error);
    }
}